import { useState } from 'react';
import { TranslationService } from '../../../services/translation.service';
import theme from "../../../constants/theme";
import { BlueButtonComponent } from '../../Shared/BlueButtonComponent';
import { ModalSwither } from "../../../utils/modal-switcher";
import { Style } from '../style';
import { InputComponent } from '../../Shared/InputComponent';
import { useAuth } from '../../../context/Auth/authContext';

export const LoginStackRegisterComponent = (props: any) => {
    const t = TranslationService.translate;
    const preview = require('../../../assets/images/ic_preview.png');
    const { signUp } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passType, setPassType] = useState('password')
    const [isAccept, setIsAccept] = useState(false);

    function callSignUp() {
        email === '' || password === ''
            ? ModalSwither.ShowErrorModal(t('SystemMessages', 'error5'))
            : isAccept
                ? signUp(email, password).then(res => {
                    if (res) {
                        ModalSwither.HideLoginStackModal()
                    }
                })
                : ModalSwither.ShowErrorModal(t('SystemMessages', 'error6'));
    }

    return (
        <div style={{ backgroundColor: theme.COLORS.screenBgColor }}>

            <div style={Style.container} className='text-center'>
                <img src={theme.ICONS.logo} style={Style.logo} alt='bildir' />
            </div>
            <button
                onClick={() => {
                    ModalSwither.HideLoginStackModal()
                }}
                className='transparentButton' style={{ position: 'absolute', top: 20, right: 20 }}>
                <img src={theme.ICONS.ic_delete_white} style={{ width: 24, height: 24 }} />
            </button>
            <div style={{ paddingInline: 24 }}>
                <p className='fontMedium' style={Style.title}>
                    {t('AutenticationScreen', 'signUp')}
                </p>
            </div>
            <div style={Style.form}>
                <p className='fontMediumItalic' style={Style.label}>
                    {t('AutenticationScreen', 'email')}
                </p>
                <InputComponent
                    listener={setEmail}
                    type='text'
                />
                <p className='fontMediumItalic mt-3' style={Style.label}>
                    {t('AutenticationScreen', 'password')}
                </p>
                <InputComponent
                    listener={setPassword}
                    type={passType}
                    hasIcon={true}
                    icon={preview}
                    onClickIcon={() => {
                        passType === 'password' ?
                            setPassType('text') : setPassType('password');
                    }}
                />
                <div className='fontMediumItalic d-flex align-items-center'>
                    <input type={'checkbox'} id='privacy' onChange={(value) => {
                        setIsAccept(!isAccept)
                    }} />
                    <button
                        style={Style.privacy}
                        onClick={() => {
                            // todo
                        }}>
                        {<span style={{ color: theme.COLORS.blue }}>{t('AutenticationScreen', 'privacyPolicy')}</span>} {t('AutenticationScreen', 'accept')}
                    </button>
                </div>
                <div style={{ marginTop: 27 }}>
                    <BlueButtonComponent
                        onClick={callSignUp}
                        text={t('AutenticationScreen', 'signUp')}
                    />
                </div>
                <div style={{ marginTop: 25 }} className='d-flex'>
                    <p className='fontMediumItalic' style={Style.haventAccount}>
                        {t('AutenticationScreen', 'haveAnAccount')}
                    </p>
                    <button className='transparentButton fontMediumItalic' onClick={() => {
                        props.setStep(0);
                    }}>
                        <p className='fontMediumItalic' style={Style.singUp}>
                            {t('AutenticationScreen', 'signIn')}
                        </p>
                    </button>

                </div>
            </div>
        </div>
    )
}
