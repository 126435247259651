import theme from "../../../../constants/theme";
export const Style = ({
    container: {
        backgroundColor: theme.COLORS.componentBgColor,
        borderRadius: 12,
        paddingBlock: theme.SPACES.paddingHorizontal,
        marginTop: 10,
        zIndex: -1,
        paddingInline:15,
        justifyContent: 'space-between', 
        alignItems: 'center',
        width:'100%'
    },
    country : {
        color:'#fff',
        fontSize:15,
        marginLeft:12
    }
});
