import React, { useEffect, useState } from 'react';
import { Props } from './types';
import { Style } from './style';
import theme from '../../../../constants/theme';
import { Lang, langs } from '../../../../constants/langs';
import { TranslationService } from '../../../../services/translation.service';

export function SettingScreenComponent({ lang }: Props) {
  const t = TranslationService.translate;
  const img = require('../../../../assets/images/ic_success.png')

  function changeLang(data: Lang) {
    TranslationService.changeLang(data).then(res => {
      window.location.reload()
      //RNRestart.Restart();
      /*
      if (res) {
        ModalSwither.ShowSuccessModal(t('SystemMessages', 'success2'));
      }*/
    });
  }



  return (
    <div style={theme.STYLE.content}>
      {langs.map(function (data, index) {
        var isActive = false;
        lang.code === data.code ? (isActive = true) : (isActive = false);
        return (
          <button
            key={index}
            className='transparentButton d-flex'
            style={Style.container}
            onClick={() => {
              changeLang(data);
            }}>
            <div className='d-flex' style={{ width: '80%' }}>
              <img src={data.flag} style={{ width: 28, height: 20 }} />
              <p className='fontMediumItalic ms-2' style={Style.country}>
                {data.display}
              </p>
            </div>
            {isActive ? (
              <img src={img} style={{ height: 18, width: 28 }} alt='bildir' />
            ) : (
              <></>
            )}
          </button>
        );
      })}
    </div>
  );
}
