import { BildirimModel } from "../api/model/BildirimModel";

export interface NotificationModel {
    data:{
        screen:string,
        bildirim?:BildirimModel,
        responsibleUserId?:string
    },
    notification:{
        body:string,
        title:string,
        image:string | null
    },
    to:string
}
export const sendNotification = async (model : NotificationModel) => {
    const res =  await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'key=AAAAT22iG6w:APA91bGUFxhYlEipXVqGBlfYshS4Lf78YU16qBPr0-go4n1Tbr4frnT1-55JyWKaXLKSZYrNCLkfynM1bx1gqJEn8NP0OzFouIh5nNompgcNR6qMpOSs_pPx3AJQIST706dw3giveQ4s'
        },
        body: JSON.stringify(model)
    })
    const content = res;
}