import { useState } from 'react';
import theme from '../../../constants/theme';
import { Style } from './style';
import { Props } from './types';
export function DropdownComponent(props: Props) {
    const [show, setShow] = useState(false)
    return (
        <>
            <div style={{ position: 'relative' }} onClick={() => setShow(!show)}>
                <div style={Style.container}>
                    <p style={{ justifySelf: 'center', alignSelf: 'center', justifyContent: 'center' }}>{props.value.label}</p>
                </div>
                <div style={{ position: 'absolute', bottom: 15, right: 10 }}>
                    <img src={show ? theme.ICONS.ic_arrow_up : theme.ICONS.ic_arrow_down} style={{ height: 20, width: 20 }} />
                </div>
            </div>
            {
                show &&
                <div style={{
                    height: 150,
                    backgroundColor: theme.COLORS.componentBgColor,
                    justifySelf: 'flex-end',
                    padding: 20,
                    overflowY: 'auto'
                }}>
                    {
                        props.values.map(function (data, index) {
                            return (
                                <button
                                    className='mt-2 transparentButton fontMedium'
                                    style={{
                                        color: '#fff',
                                        width: '100%',
                                        textAlign: 'start'
                                    }}
                                    key={index}
                                    onClick={() => {
                                        props.setValue(data)
                                        setShow(false)
                                    }}>
                                    {data.label}
                                </button>
                            )
                        })
                    }
                </div>
            }
        </>

    );
}
