import { useState } from 'react';
import { TranslationService } from '../../../services/translation.service';
import theme from "../../../constants/theme";
import { BlueButtonComponent } from '../../Shared/BlueButtonComponent';
import { ModalSwither } from "../../../utils/modal-switcher";
import { Style } from '../style';
import { InputComponent } from '../../Shared/InputComponent';
import { useAuth } from '../../../context/Auth/authContext';

export const LoginStackLoginComponent = (props: any) => {
    const t = TranslationService.translate;
    const preview = require('../../../assets/images/ic_preview.png');
    const { signIn } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passType, setPassType] = useState('password')
    async function callSignIn() {
        email === '' || password === ''
            ? ModalSwither.ShowErrorModal(t('SystemMessages', 'error5'))
            : signIn(email, password).then((res) => {
                if (res) {
                    ModalSwither.HideLoginStackModal()
                }
            })
    }

    return (
        <div style={{ backgroundColor: theme.COLORS.screenBgColor }}>
            <div style={Style.container} className='text-center'>
                <img src={theme.ICONS.logo} style={Style.logo} alt='bildir' />
            </div>
            <button
                onClick={() => {
                    ModalSwither.HideLoginStackModal()
                }}
                className='transparentButton' style={{ position: 'absolute', top: 20, right: 20 }}>
                <img src={theme.ICONS.ic_delete_white} style={{ width: 24, height: 24 }} />
            </button>
            <div style={{ paddingInline: 24 }}>
                <p className='fontMedium' style={Style.title}>
                    {t('AutenticationScreen', 'signIn')}
                </p>
            </div>
            <div style={Style.form}>
                <p className='fontMediumItalic' style={Style.label}>
                    {t('AutenticationScreen', 'email')}
                </p>
                <InputComponent
                    listener={setEmail}
                    type='text'
                />
                <p className='fontMediumItalic mt-3' style={Style.label}>
                    {t('AutenticationScreen', 'password')}
                </p>
                <InputComponent
                    listener={setPassword}
                    type={passType}
                    hasIcon={true}
                    icon={preview}
                    onClickIcon={() => {
                        passType === 'password' ?
                            setPassType('text') : setPassType('password');
                    }}
                />
                <div className='text-end fontMediumItalic'>
                    <button
                        style={Style.forgetPassword}
                        onClick={() => {
                            props.setStep(2);
                        }}>
                        {t('AutenticationScreen', 'forgotPassword')}
                    </button>
                </div>
                <div style={{ marginTop: 27 }}>
                    <BlueButtonComponent
                        onClick={callSignIn}
                        text={t('AutenticationScreen', 'signIn')}
                    />
                </div>
                <div style={{ marginTop: 25 }} className='d-flex'>
                    <p className='fontMediumItalic' style={Style.haventAccount}>
                        {t('AutenticationScreen', 'haveNotAnAccount')}
                    </p>
                    <button className='transparentButton fontMediumItalic' onClick={() => {
                        props.setStep(1);
                    }}>
                        <p className='fontMediumItalic ms-3' style={Style.singUp}>
                            {t('AutenticationScreen', 'signUp')}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    )
}
