
let fontStyles;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default fontStyles = ({
    fontMedium: {
        fontFamily: 'Jiho-Medium',
        fontStyle: 'normal',
        fontWeight: '500',
    },
    fontItalic: {
        fontFamily: 'Jiho-Italic',
        fontStyle: 'italic',
        fontWeight: '500',
    },
    fontMediumItalic: {
        fontFamily: 'Jiho-MediumItalic',
        fontWeight: '500',
    },
    fontRegular: {
        fontFamily: 'Jiho-Regular',
        fontStyle: 'normal',
        fontWeight: '500',
    },
    fontBold: {
        fontFamily: 'Jiho-Bold',
        fontStyle: 'normal',
        fontWeight: '500',
    },
    fontExtraBold: {
        fontFamily: 'Jiho-ExtraBold',
        fontStyle: 'normal',
        fontWeight: '500'
    }
});
