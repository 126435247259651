import { DataFireStoreService } from '../../../api/services/data-firestore.service';
import { useEffect, useState } from 'react';
import { Props } from './types';
import { Style } from './style';
import { useAuth } from '../../../context/Auth/authContext';
import { calculatePercent } from '../../../utils/math';
import { BildirimModel } from '../../../api/model/BildirimModel';
import { TranslationService } from '../../../services/translation.service';
import theme from '../../../constants/theme';
import { BildirCardComponent } from '../../../components/BildirCardComponent';
import { useNavigate } from 'react-router-dom';
import { ModalSwither } from '../../../utils/modal-switcher';
export function MainScreenComponent({ }: Props) {
    const { user } = useAuth()
    const navigation = useNavigate()
    const t = TranslationService.translate;
    const [bildirimler, setBildirimler] = useState([] as BildirimModel[]);

    const [answered, setAnswered] = useState({
        count: 0,
        percent: 0,
        color: theme.COLORS.blue,
    });

    const [waitingAnswer, setWaitingAnswer] = useState({
        count: 0,
        percent: 0,
        color: theme.COLORS.blue,
    });

    function getBildirimler() {
        DataFireStoreService.getMainScreenBildirims(
            user?.uid as any,
            'CLOSED',
        ).then(res => {
            const answeredPercent = calculatePercent(
                res.length,
                res.filter(item => item.states === 'ANSWERED').length,
            );
            setAnswered({
                count: res.filter(item => item.states === 'ANSWERED').length,
                percent: answeredPercent,
                color: answeredPercent >= 50 ? theme.COLORS.blue : theme.COLORS.yellow,
            });
            const waitingAnswerPercent = calculatePercent(
                res.length,
                res.filter(item => item.states === 'OPEN').length,
            );
            setWaitingAnswer({
                count: res.filter(item => item.states === 'OPEN').length,
                percent: waitingAnswerPercent,
                color:
                    waitingAnswerPercent >= 50 ? theme.COLORS.blue : theme.COLORS.yellow,
            });
            setBildirimler(res);
        });
    }

    useEffect(() => {
        getBildirimler();
    }, []);

    const renderUserImage = () => {
        let imageSource;
        if ((!user?.photoURL) || user?.photoURL == '') {
            imageSource = require('../../../assets/images/bottombar/active/profile.png');
        } else {
            imageSource = user?.photoURL;
        }

        return (
            <button
                className='transparentButton absolute'
                style={{ right: 15 }}
                onClick={() => {
                    //@ts-ignore
                    user?.group?.length === 1 && user.group[0] === 5
                        ? navigation('/MyAccount')
                        : navigation('/MyAccount');
                }}>
                <img
                    alt='bildir'
                    src={imageSource}
                    style={{ height: 36, width: 36, borderRadius: 48 }}
                />
            </button>
        );
    };
    return (
        <div style={theme.STYLE.container}>
            <div className='d-flex justify-content-between relative gradientBlue' style={Style.welcome}>
                <div>
                    <p className='fontMedium' style={Style.userName}>
                        {user?.displayName}
                    </p>
                    {
                        !user &&
                        <p className='fontMedium' style={Style.userName}>
                            {t('MainScreen', 'hello')}
                        </p>
                    }
                    <p className='fontRegular' style={Style.letsReport}>
                        {t('MainScreen', 'letsReport')}
                    </p>
                </div>
                <div >
                    {renderUserImage()}
                    {/* <button
                        className='transparentButton'
                        onClick={() => {
                            if (!user) {
                                ModalSwither.ShowLoginStackModal()
                                return;
                            }
                            window.location.href = 'https://kart.bildirr.com/Profile/' + user?.uid
                        }
                        }>
                        <img
                            className='absolute'
                            src={require('../../../assets/images/bildirKartLogo.png')}
                            style={{
                                width: 100,
                                height: 20,
                                marginTop: 25,
                                right: 0,
                            }} />
                    </button> */}
                </div>
                <div className='absolute' style={Style.statisticContainer}>
                    <div className='borderWhite' style={Style.statistic}>
                        <p className='fontMedium' style={Style.letsReport}>
                            {t('MainScreen', 'bildirimStatuses')}
                        </p>
                        <div className='d-flex justify-content-between'>
                            <p className='fontMediumItalic' style={Style.letsReport}>
                                {t('MainScreen', 'answered')}
                            </p>
                            <p className='fontMediumItalic' style={Style.tite}>
                                {answered.count}
                            </p>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <p className='fontMediumItalic' style={Style.letsReport}>
                                {t('MainScreen', 'waitingAnswer')}
                            </p>
                            <p className='fontMediumItalic' style={Style.tite}>
                                {waitingAnswer.count}
                            </p>
                        </div>
                        <p className='text-center mt-2 fontMediumItalic' style={Style.seeAll}>
                            {t('MainScreen', 'seeAll')}
                        </p>
                    </div>
                </div>
            </div>
            <div style={{ paddingInline: 20, marginTop: 130, }}>
                {(user?.group?.length as any) > 1 ? (
                    <p className='fontMedium' style={Style.tite}>
                        {t('MainScreen', 'businessBildirimlerTitle')}
                    </p>
                ) : (
                    <p className='fontMedium' style={Style.tite}>
                        {t('MainScreen', 'normalUserBildirimlerTitle')}
                    </p>
                )}
                {
                    user &&
                    bildirimler.map(function (data, index) {
                        return (
                            <BildirCardComponent bildirim={data} key={index} />
                        );
                    })
                }
            </div>
        </div>
    )
}
