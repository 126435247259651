import { DataFireStoreService } from "../../../api/services/data-firestore.service";
import { useEffect, useState } from "react";
import { Props } from "./types";
import { Style } from "./style";
import theme from "../../../constants/theme";
import { BusinessModel } from "../../../api/model/BusinessModel";
import { useNavigate } from "react-router-dom";
import QrReader from "react-web-qr-reader";
import { ModalSwither } from "../../../utils/modal-switcher";
import { TranslationService } from "../../../services/translation.service";
import { UnitsModel, UnitsModelList } from "../../../api/model/UnitModel";
export function QRCodeScreenComponent({}: Props) {
  const t = TranslationService.translate;
  const navigation = useNavigate();
  const delay = 500;
  const handleScan = (result: any) => {
    console.log("QR CODE OKUNDU ; ", result);
    console.log("QR CODE DATA : ", result.data);
    if (result) {
      try {
        const str = result.data.split("/");
        const myCode = str[str.length - 1].split(":");
        const userId: string = myCode[0];
        const businessId: string = myCode[1];
        const unitId: string = myCode[2];
        if (
          userId === undefined ||
          businessId === undefined ||
          unitId === undefined
        ) {
          ModalSwither.ShowErrorModal(t("SystemMessages", "error10"));
          return;
        }
        console.log("userId ; ", userId);
        console.log("businessId ; ", businessId);
        console.log("unitId ; ", unitId);

        DataFireStoreService.getBusinessesById(userId, businessId, true)
          .then(async (business) => {
            //@ts-ignore
            const bussData = await business.data();
            console.log("business ; ", bussData);
            const businessListModel: BusinessModel = {
              ...bussData,
              id: businessId,
            };
            DataFireStoreService.getUnitById(userId, businessId, unitId, true)
              .then(async (unit) => {
                //@ts-ignore
                const unitdata = await unit.data();
                console.log("unit ; ", unitdata);
                const unitListModel: UnitsModel = {
                  ...unitdata,
                  id: unitId,
                };
                console.log("navigation running ; ", {
                  UserId: userId,
                  Business: businessListModel,
                  Units: unitListModel,
                  disableLoading: true,
                });

                navigation("/transactions", {
                  state: {
                    UserId: userId,
                    Business: businessListModel,
                    Units: unitListModel,
                    disableLoading: true,
                  },
                });
              })
              .catch((err) => {
                ModalSwither.ShowErrorModal(t("SystemMessages", "error10"));
                return;
              });
          })
          .catch((err) => {
            ModalSwither.ShowErrorModal(t("SystemMessages", "error10"));
            return;
          });
      } catch (err) {
        const t = TranslationService.translate;
        ModalSwither.ShowErrorModal(t("SystemMessages", "error10"));
        return;
      }
    }
  };

  useEffect(()=>{
    //   setTimeout(()=>{
    //       handleScan({data:'https://m.bildirr.com/qr/jU40CNS8lhTAqURLZN5BVFiOUDK2:dbbbdc6ee07846fbab53:8a34f0322b8749aeb97f'})
    //   },1000)
  },[])

  const handleError = (error: any) => {
    ModalSwither.ShowErrorModal(t("SystemMessages", "error13"));
  };

  return (
    <>
      <div className="text-center" style={{ padding: 20 }}>
        <img style={Style.logoContainer} src={theme.ICONS.logo} alt="bildir" />
        <div className="mt-5" style={{ alignItems: "center" }}>
          <QrReader delay={delay} onError={handleError} onScan={handleScan} />
        </div>
      </div>
    </>
  );
}
