import { DataFireStoreService } from '../../../../api/services/data-firestore.service';
import { useEffect, useState } from 'react';
import { Props } from './types';
import { Style } from './style';
import theme from '../../../../constants/theme';
import { BusinessModel } from '../../../../api/model/BusinessModel';
import { useLocation } from 'react-router-dom';
import { UnitsModel } from '../../../../api/model/UnitModel';
import { BackButtonComponent } from '../../../../components/Shared/BackButtonComponent';
import { useNavigate } from 'react-router-dom';

export function UnitsScreenComponent({ }: Props) {

    const { state } = useLocation();
    const navigation = useNavigate()
    // @ts-ignore
    const [business, setBusiness] = useState(state.Business as BusinessModel);
    const [units, setUnits] = useState([] as UnitsModel[]);

    function getUnitsOfBusiness(UserId: string, BusinessId: string) {
        DataFireStoreService.getUnitsOfBusiness(UserId, BusinessId).then(res => {
            setUnits(res);
        });
    }
    useEffect(() => {
        // @ts-ignore
        getUnitsOfBusiness(state.UserId as any, state.Business.id as any);
    }, []);

    return (
        <>

            <div style={{
                backgroundImage: "url(" + business.imageURL + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: 150,
                width: '100%'
            }}>
                <div className='d-flex' style={{
                    backgroundColor: theme.COLORS.containerColor,
                    opacity: 0.8,
                    height: 40,
                    alignItems:'center'
                }}>
                    <BackButtonComponent />
                    <p className='fontMediumItalic text-center' style={{
                        color:'#fff',
                        fontSize:18,
                        flex:1,
                    }}>
                        {business.title}
                    </p>
                </div>
            </div>


            <div className='mt-4 px-3'>
                {
                    units.map(function (data, index) {
                        return (
                            <button
                                className='mt-3 transparentButton d-flex'
                                style={theme.STYLE.lineStyle}
                                key={index}
                                onClick={() => {
                                    navigation('/transactions',
                                        {
                                            state: {
                                                // @ts-ignore
                                                UserId: state.UserId,
                                                // @ts-ignore
                                                Business: state.Business,
                                                Units: data
                                            }
                                        })
                                }}>
                                <img
                                    style={Style.img}
                                    src={business.imageURL}
                                />
                                <p className='ms-3 fontMedium' style={Style.unit}>
                                    {data.title}
                                </p>
                            </button>
                        )
                    })
                }
            </div>
        </>
    )
}
