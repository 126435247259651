import theme from "../../constants/theme"
export const Style = ({
    containerStyle: {
        flex: 1,
        justifyContent: 'space-around',
        alignItems: 'flex-end',
        backgroundColor:theme.COLORS.screenBgColor
    },
    content: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#fff',
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
        backgroundColor: theme.COLORS.screenBgColor,
        overflow: 'hidden',
        padding: 40
    },
    closeContainer: {
        top:20,
        right:20
    },
    text: {
        color: '#fff',
        fontSize: 12,
        marginTop: 4,
    },
    deleteIcon: {
        right: 5,
        top: 5
    },
    classRow: {
        justifyContent: 'space-around',
        marginTop:20
    }

})