import theme from '../../../constants/theme';
export const Style = ({
    userName: {
        color: 'white',
        fontSize: 22
    },
    letsReport: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    welcome: {
        height: 200,
        paddingInline: 20,
        paddingTop: 60,
    },
    statisticContainer: {
        width: '90%',
        top: '80%',
    },
    statistic: {
        paddingTop: 19,
        paddingInline: 11,
        backgroundColor: theme.COLORS.componentBgColor,
        paddingBottom: 20
    },
    tite: {
        color: '#fff',
        fontSize: 18
    },
    seeAll: {
        color: theme.COLORS.blue,
        fontSize: 14
    },
    settingButton: {
        width: 14,
        height: 14,
        zIndex: 100,
        bottom: -2,
        right: -2,
    },
});