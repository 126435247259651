import theme from "../../../../constants/theme";

export const Style = ({
    container:{
        paddingTop:170,
        alignItems:'center',
        backgroundColor: theme.COLORS.screenBgColor
    },
    infoText:{
        color:'#fff',
        fontSize:15,
    }
});
