import React, { useEffect, useRef, useState } from "react";
import theme from "../../../constants/theme";
import { useAuth } from "../../../context/Auth/authContext";
import { DataFireStoreService } from "../../../api/services/data-firestore.service";
import { MessageModel } from "../../../api/model/MessageModel";
import { Style } from "./style";
import { UserModel } from "../../../api/model/UserModel";
import { getTypeBackgroundColor } from "../../../constants/bildirimTypes";
import { MomentConverter } from "../../../utils/moment";
import { TranslationService } from "../../../services/translation.service";
import { BildirimStates, getState } from "../../../constants/bildirimStates";
import { ImageMediaPickerHalfModal } from "../../../components/ImageMediaPickerHalfModal";
import { ModalSwither } from "../../../utils/modal-switcher";
import { ImageViewerModalComponent } from "../../../components/ImageViewerModalComponent";
import { FileStorageService } from "../../../api/services/file-storage.service";
import { MicComponent } from "../../../components/MicComponent";
import { StringFormatter } from "../../../utils/string";
import { TextMessageComponent } from "../../../components/ChatComponents/TextMessageComponent";
import { ImageMessageComponent } from "../../../components/ChatComponents/ImageMessageComponent";
import { MicMessageComponent } from "../../../components/ChatComponents/MicMessageComponent";
import { HeaderComponent } from "../../../components/ChatComponents/HeaderComponent";
import { useLocation, useNavigate } from "react-router-dom";
import useRecordingsList from "../../../hooks/use-recordings-list";
import useRecorder from "../../../hooks/use-recorder";
import { UseRecorder } from "../../../constants/types";
import { fireStore } from "../../../services/firebase";
import {
  NotificationModel,
  sendNotification,
} from "../../../services/notification.service";

export function ChatScreenComponent() {
  const { recorderState, ...handlers }: UseRecorder = useRecorder();
  const { audio, file } = recorderState;
  const { recordings, deleteAudio } = useRecordingsList(audio, file as any);
  const { user } = useAuth();
  const navigation = useNavigate();
  const t = TranslationService.translate;
  const { state } = useLocation();
  const [messages, setMessages] = useState([] as MessageModel[]);
  const [userDetail, setUserDetail] = useState({
    photoURL: "",
    displayName: "",
  } as UserModel);
  const [micShow, setMicShow] = useState(false);
  const [showImageMediaPicker, setShowImageMediaPicker] = useState(false);
  const [text, setText] = useState("");
  const [imgFiles, setImgFiles] = React.useState(
    [] as { uri: string; fileName: string; file: any }[]
  );
  const [height, setHeight] = React.useState();

  function onResult(QuerySnapshot: any) {
    const list = [] as any;
    // @ts-ignore
    QuerySnapshot.forEach((x) => {
      list.push(x.data());
    });
    messages.length == 0 &&
      setMessages(list.sort(StringFormatter.dynamicSort("date")));
    scrollToBottom();
  }
  function onError(error: any) {
    console.error("error", error);
  }
  fireStore
    .collection("Users")
    // @ts-ignore
    .doc(state.data.Bildirim.user.uid)
    // @ts-ignore
    .collection("Bildirimler")
    // @ts-ignore
    .doc(state.data.Bildirim.id)
    .collection("Messages")
    .onSnapshot(onResult, onError);

  function getUserInformation() {
    // @ts-ignore

    if (user?.uid === state.data.responsibleUserId) {
      // @ts-ignore

      DataFireStoreService.getUserDetail(state.data.Bildirim.user.uid).then(
        (userResponse) => {
          setUserDetail(userResponse);
        }
      );
    } else {
      // @ts-ignore

      DataFireStoreService.getUserDetail(state.data.responsibleUserId).then(
        (userResponse) => {
          // @ts-ignore
          setUserDetail(userResponse);
        }
      );
    }
    // @ts-ignore
  }

  useEffect(() => {
    getUserInformation();
  }, []);

  function popImg(i: number) {
    const list = imgFiles.filter((item, index) => index != i);
    setImgFiles(list);
  }

  async function sendMessage() {
    if (text !== "") {
      const model: MessageModel = {
        date: new Date(),
        hasImage: false,
        hasVoice: false,
        text: text,
        user: {
          name: user?.displayName as any,
          uid: user?.uid as any,
        },
      };
      const list = messages;
      list.push(model);
      setMessages(list);
      setText("");
      await DataFireStoreService.sendMessage(
        // @ts-ignore
        state.data.Bildirim.business?.user.uid as any,
        // @ts-ignore
        state.data.responsibleUserId,
        // @ts-ignore
        state.data.Bildirim.user.uid,
        // @ts-ignore
        state.data.Bildirim.business?.id as any,
        // @ts-ignore
        state.data.Bildirim.units.id,
        // @ts-ignore
        state.data.Bildirim.services.id,
        // @ts-ignore
        state.data.Bildirim.id as any,
        model
      ).then((res) => {});
    }
    const imageListCopy = imgFiles;
    var imgURl = null;
    for (const img of imageListCopy) {
      await FileStorageService.uploadFile(
        // @ts-ignore
        state.data.Bildirim.id + "/" + img.fileName,
        img.file
      ).then(async (link) => {
        const messageImageModel: MessageModel = {
          date: new Date(),
          hasImage: true,
          imageUrl: link,
          hasVoice: false,
          user: {
            name: user?.displayName as any,
            uid: user?.uid as any,
          },
          text: "",
        };
        const list = messages;
        list.push(messageImageModel);
        imgURl = messageImageModel.imageUrl;
        //setMessages(list);
        setText("");
        setImgFiles([]);
        await DataFireStoreService.sendMessage(
          // @ts-ignore
          state.data.Bildirim.business?.user.uid as any,
          // @ts-ignore
          state.data.responsibleUserId,
          // @ts-ignore
          state.data.Bildirim.user.uid,
          // @ts-ignore
          state.data.Bildirim.business?.id as any,
          // @ts-ignore
          state.data.Bildirim.units.id,
          // @ts-ignore
          state.data.Bildirim.services.id,
          // @ts-ignore
          state.data.Bildirim.id as any,
          messageImageModel
        );
      });
    }
    if (recordings.length > 0) {
      for (const record of recordings) {
        FileStorageService.uploadFile(
          // @ts-ignore
          state.data.Bildirim.id + "/" + StringFormatter.generateId(10),
          // @ts-ignore
          record.file
        ).then(async (link) => {
          const messageImageModel: MessageModel = {
            date: new Date(),
            hasImage: false,
            hasVoice: true,
            voiceUrl: link,
            user: {
              name: user?.displayName as any,
              uid: user?.uid as any,
            },
            text: "",
          };
          const list = messages;
          list.push(messageImageModel);
          setMessages(list);
          setText("");
          setMicShow(false);
          await DataFireStoreService.sendMessage(
            // @ts-ignore
            state.data.Bildirim.business?.user.uid as any,
            // @ts-ignore
            state.data.responsibleUserId,
            // @ts-ignore
            state.data.Bildirim.user.uid,
            // @ts-ignore
            state.data.Bildirim.business?.id as any,
            // @ts-ignore
            state.data.Bildirim.units.id,
            // @ts-ignore
            state.data.Bildirim.services.id,
            // @ts-ignore
            state.data.Bildirim.id as any,
            messageImageModel
          );
        });
      }
    }
    await changeBildirimStatus();
    try {
      const notificationData: NotificationModel = {
        data: {
          screen: "Chat",
          //@ts-ignore
          bildirim: state.data.Bildirim,
          //@ts-ignore
          responsibleUserId: state.data.responsibleUserId,
        },
        notification: {
          body: text,
          title: user?.displayName as string,
          image: imgURl,
        },
        //@ts-ignore
        to: userDetail.fcmTokens[userDetail.fcmTokens?.length - 1],
      };
      sendNotification(notificationData);
    } catch (err) {}
  }

  async function changeBildirimStatus(close?: boolean) {
    var states: BildirimStates = "OPEN";
    // @ts-ignore
    if (state.data.responsibleUserId === user?.uid) {
      states = "ANSWERED";
    }
    if (close) {
      states = "CLOSED";
    }
    await DataFireStoreService.changeBildirimStatus(
      // @ts-ignore
      state.data.Bildirim.business?.user.uid as any,
      // @ts-ignore
      state.data.responsibleUserId,
      // @ts-ignore
      state.data.Bildirim.user.uid,
      // @ts-ignore
      state.data.Bildirim.business?.id as any,
      // @ts-ignore
      state.data.Bildirim.units.id,
      // @ts-ignore
      state.data.Bildirim.services.id,
      // @ts-ignore
      state.data.Bildirim.id as any,
      states
    ).then((res) => {
      scrollToBottom();
      if (states == "CLOSED") {
        navigation("/");
        ModalSwither.ShowSuccessModal(t("SystemMessages", "success4"));
      }
    });
  }

  const scrollViewRef = useRef();
  const scrollToBottom = () => {
    //@ts-ignore
    scrollViewRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const viewRef = useRef(null);

  const [showSendButtonMic, setShowSendButtonMic] = useState(false);

  useEffect(() => {
    recordings.length > 0
      ? setShowSendButtonMic(true)
      : setShowSendButtonMic(false);
  }, [recordings]);

  return (
    <div className="fixedContent" style={{ paddingBottom: 50, width: "100%" }}>
      <HeaderComponent
        closeBildirim={changeBildirimStatus}
        userDetail={userDetail as any}
        // @ts-ignore
        bildirim={state.data.Bildirim}
        userGroup={user?.group as any}
      />
      {micShow ? (
        <div style={{ marginTop: 100 }}>
          <div className="text-end">
            <button
              className="transparentButton mt-2"
              onClick={() => setMicShow(false)}
            >
              <img
                src={theme.ICONS.ic_close}
                style={{ height: 24, width: 24 }}
              />
            </button>
          </div>
          <MicComponent
            file={file as any}
            handlers={handlers}
            recorderState={recorderState}
            audio={audio}
            deleteAudio={deleteAudio}
            recordings={recordings}
          />
          {showSendButtonMic && (
            <div className="text-center">
              <button className="transparentButton mt-2" onClick={sendMessage}>
                <img
                  src={theme.ICONS.ic_tik}
                  style={{ height: 48, width: 48 }}
                />
              </button>
            </div>
          )}
        </div>
      ) : (
        <div style={{ width: "100%", paddingInline: 20, overflowY: "scroll" }}>
          <div className="d-flex" style={Style.container}>
            <div
              style={{
                width: "10%",
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
                backgroundColor: getTypeBackgroundColor(
                  //@ts-ignore
                  state.data.Bildirim.type as any
                ),
                position: "relative",
              }}
            >
              <p
                className="text-center"
                //@ts-ignore
                style={Style.typeText}
              >
                {
                  // @ts-ignore
                  state.data.Bildirim.type
                }
              </p>
            </div>
            <div style={{ marginLeft: 10, flex: 10 }}>
              <div className="d-flex" style={Style.bussinesContainer}>
                <div style={{ marginLeft: 12 }}>
                  <p className="fontRegular" style={Style.businessTitle}>
                    {
                      // @ts-ignore
                      state.data.Bildirim.business?.title
                    }
                  </p>
                  <p className="fontRegular" style={Style.businessTitle}>
                    {
                      // @ts-ignore
                      state.data.Bildirim.units.title
                    }
                  </p>
                  <p className="fontRegular" style={Style.businessTitle}>
                    {
                      // @ts-ignore
                      state.data.Bildirim.services.title
                    }
                  </p>
                  {
                    // @ts-ignore
                    state.data.Bildirim.phone && (
                      <p className="fontRegular" style={Style.businessTitle}>
                        {
                          // @ts-ignore
                          state.data.Bildirim.phone
                        }
                      </p>
                    )
                  }
                </div>
                <div style={{ marginRight: 10 }}>
                  <div
                    className="d-flex"
                    style={{
                      flex: 1,
                      justifyContent: "space-between",
                      marginTop: 6,
                    }}
                  >
                    {
                      // @ts-ignore
                      state.data.Bildirim.states === "OPEN" ||
                      // @ts-ignore
                      state.data.Bildirim.states === "ANSWERED" ? (
                        <>
                          <div
                            className="d-flex"
                            style={{
                              marginRight: 10,
                              alignItems: "center",
                            }}
                          >
                            <p className="fontRegular" style={Style.openState}>
                              {getState("OPEN")}
                            </p>
                            <img
                              style={{ height: 16, width: 14, marginLeft: 3 }}
                              src={theme.ICONS.ic_state_open}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{ flexDirection: "row", marginRight: 10 }}
                          >
                            <p
                              className="fontRegular"
                              style={Style.closedState}
                            >
                              {getState("CLOSED")}
                            </p>
                            <img
                              style={{ height: 16, width: 14, marginLeft: 3 }}
                              src={theme.ICONS.ic_state_closed}
                            />
                          </div>
                        </>
                      )
                    }
                  </div>
                  <p style={Style.date}>
                    {MomentConverter.formatDateDMY(
                      // @ts-ignore
                      state.data.Bildirim.date.seconds * 1000
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {messages.map(function (data, index) {
            var alignSelf = "flex-start";
            var backgroundColor = "#f5f5f5";
            if (data.user.uid === user?.uid) {
              alignSelf = "flex-end";
              backgroundColor = "#d1f3ff";
            }
            return (
              <div
                key={index}
                style={{ paddingInline: 20, marginTop: 10, width: "100%" }}
              >
                {data.text !== "" ? (
                  <TextMessageComponent
                    alignSelf={alignSelf}
                    backgroundColor={backgroundColor}
                    message={data.text}
                    time={data.date}
                  />
                ) : (
                  <></>
                )}
                {data.hasImage ? (
                  <ImageMessageComponent
                    alignSelf={alignSelf}
                    backgroundColor={backgroundColor}
                    message={data.imageUrl}
                    time={data.date}
                  />
                ) : (
                  <></>
                )}
                {data.hasVoice ? (
                  <MicMessageComponent
                    keu={"mic_" + index}
                    key={"mic_" + index}
                    alignSelf={alignSelf}
                    backgroundColor={backgroundColor}
                    message={data.voiceUrl}
                    time={data.date}
                  />
                ) : (
                  <></>
                )}
              </div>
            );
          })}
          {
            // @ts-ignore
            state.data.Bildirim.states !== "CLOSED" && (
              <div
                className="d-flex fixed"
                style={Style.chatContainer}
                ref={viewRef}
              >
                <textarea
                  style={{
                    paddingInline: 10,
                    height,
                    maxHeight: 150,
                    minHeight: 30,
                    border: 0,
                  }}
                  rows={1}
                  value={text}
                  placeholder={t("SharedComponents", "placeholder1")}
                  onChange={(data) => {
                    setText(data.target.value);
                  }}
                />
                <div style={Style.verticalLine}></div>
                <button
                  className="transparentButton"
                  style={{ marginLeft: 10, marginRight: 20 }}
                  onClick={() => {
                    setShowImageMediaPicker(true);
                  }}
                >
                  <img
                    src={theme.ICONS.ic_plus}
                    style={{ height: 18, width: 18 }}
                  />
                </button>
                <button
                  className="transparentButton"
                  style={{ marginLeft: 10, marginRight: 10 }}
                  onClick={sendMessage}
                >
                  <img
                    src={theme.ICONS.ic_send}
                    style={{ height: 22, width: 22 }}
                  />
                </button>
              </div>
            )
          }
        </div>
      )}
      {showImageMediaPicker ? (
        <ImageMediaPickerHalfModal
          fileListener={(model: {}) => {
            setImgFiles((x) => [...x, model as any]);
          }}
          close={() => {
            setShowImageMediaPicker(false);
          }}
          imgFiles={imgFiles as any}
          popImg={popImg}
          hasMic={true}
          micOnClick={() => {
            setShowImageMediaPicker(false);
            setMicShow(true);
          }}
        />
      ) : (
        <></>
      )}
      {imgFiles.length > 0 ? (
        <ImageViewerModalComponent
          close={() => {
            setImgFiles([]);
          }}
          imageUri={imgFiles}
          readyToSend={true}
          setText={setText}
          text={text}
          sendMessage={sendMessage}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
