import theme from '../../constants/theme';
export const Style = ({
    title:{
        color:'#fff',
        fontSize:12
    },
    content:{
        marginTop:1,
        flexDirection:'row',
        justifyContent:'space-between'
    },
    card:{
        paddingVertical:4,
        paddingHorizontal:15,
        borderRadius:8
    },
    cardText:{
        color:'#fff',
        fontSize:14
    }
});
