import moment from 'moment';

export class MomentConverter {
  static formatDateDMY(date: Date, format='D MMMM YYYY') {
    if (date == undefined) {
      return '';
    }

    return moment(date).format(format);
  }

  static formatDateYMD(date: Date) {
    if (date == undefined) {
      return '';
    }

    return moment(date).format('YYYY-MM-DD');
  }

  static getClockTime(date: any) {
    var result = '';
    try {
      result = moment(date.toDate()).format('hh : mm');
    } catch (er) {
      result = moment(date).format('hh : mm');
    }
    return result;
  }

  static checkEnableToClose(date: any) {
    const time = moment(date);
    const diff = moment(new Date()).diff(time, 'days');
    return diff > 3 ? true : false;
  }

  static getDiffHour(date: any, diffNum = 3): {status: boolean; days: number} {
    try {
      const time = moment(date.toDate());
      const diff = moment(new Date()).diff(time, 'hours');
      console.log(diff)
      return diff > diffNum
        ? {status: true, days: diff}
        : {status: false, days: diff};
    } catch (er) {
      return {status: false, days: 0};
    }
  }
}
