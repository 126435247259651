import { useState } from 'react';
import { Style } from './style';
import theme from '../../../constants/theme';
import { InputComponent } from '../../../components/Shared/InputComponent';
import { useAuth } from '../../../context/Auth/authContext';
import { TranslationService } from '../../../services/translation.service';
import { ModalSwither } from '../../../utils/modal-switcher';
import { BlueButtonComponent } from '../../../components/Shared/BlueButtonComponent';
import { Props } from './types';
import { useNavigate } from 'react-router-dom';
export function LoginScreenComponent({} : Props) {
    const navigation = useNavigate()
    const preview = require('../../../assets/images/ic_preview.png');
    const t = TranslationService.translate;
    const { signIn } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passType, setPassType] = useState('password')

    function callSignIn() {
        email === '' || password === ''
            ? ModalSwither.ShowErrorModal(t('SystemMessages', 'error5'))
            : signIn(email, password);
    }

    return (
        <>
            <div style={Style.container} className='text-center'>
                <img src={theme.ICONS.logo} style={Style.logo} alt='bildir' />
            </div>
            <div style={{ paddingInline: 24 }}>
                <p className='fontMedium' style={Style.title}>
                    {t('AutenticationScreen', 'signIn')}
                </p>
            </div>
            <div style={Style.form}>
                <p className='fontMediumItalic' style={Style.label}>
                    {t('AutenticationScreen', 'email')}
                </p>
                <InputComponent
                    listener={setEmail}
                    type='text'
                />
                <p className='fontMediumItalic mt-3' style={Style.label}>
                    {t('AutenticationScreen', 'password')}
                </p>
                <InputComponent
                    listener={setPassword}
                    type={passType}
                    hasIcon={true}
                    icon={preview}
                    onClickIcon={() => {
                        passType === 'password' ?
                            setPassType('text') : setPassType('password');
                    }}
                />
                <div className='text-end fontMediumItalic'>
                    <button
                        style={Style.forgetPassword}
                        onClick={() => { navigation('/forgotPassword')}}>
                        {t('AutenticationScreen', 'forgotPassword')}
                    </button>
                </div>
                <div style={{ marginTop: 27 }}>
                    <BlueButtonComponent
                        onClick={callSignIn}
                        text={t('AutenticationScreen', 'signIn')}
                    />
                </div>
                <div style={{ marginTop: 25 }} className='d-flex'>
                    <p className='fontMediumItalic' style={Style.haventAccount}>
                        {t('AutenticationScreen', 'haveNotAnAccount')}
                    </p>
                    <button className='transparentButton fontMediumItalic' onClick={() => {
                        navigation('/register')
                    }}>
                        <p className='fontMediumItalic ms-3' style={Style.singUp}>
                            {t('AutenticationScreen', 'signUp')}
                        </p>
                    </button>

                </div>
            </div>
        </>
    )
}