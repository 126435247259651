import { DataFireStoreService } from '../../../../api/services/data-firestore.service';
import { useEffect, useState } from 'react';
import { Props } from './types';
import { Style } from './style';
import { BusinessModel } from '../../../../api/model/BusinessModel';
import { useLocation } from 'react-router-dom';
import { BackButtonComponent } from '../../../../components/Shared/BackButtonComponent';
import { useNavigate } from 'react-router-dom';
import { TranslationService } from '../../../../services/translation.service';
import { ServiceModel } from '../../../../api/model/ServiceModel';
import theme from "../../../../constants/theme";
export function ServicesScreenComponent({ }: Props) {
    const t = TranslationService.translate;
    const { state } = useLocation();
    const navigation = useNavigate()
    // @ts-ignore
    const [business, setBusiness] = useState(state.Business as BusinessModel);
    const [services, setServices] = useState([] as ServiceModel[]);

    function getServices(UserId: string, BusinessId: string, UnitId: string) {
        DataFireStoreService.getServicesOfBusiness(UserId, BusinessId, UnitId).then(
            res => {
                setServices(res);
            },
        );
    }

    const _renderItem = (data: ServiceModel) => {
        return (
            <button
                className='pt-2'
                style={Style.card}
                onClick={() => {
                    navigation('/bildir',
                        {
                            state: {
                                // @ts-ignore
                                Business: state.Business,
                                Services: data,
                                // @ts-ignore
                                Units: state.Units,
                                // @ts-ignore
                                UserId: state.UserId,
                                // @ts-ignore
                                header: state.header,
                                ResponsibleUserId: data.responsibleUser.uid,
                            }
                        })
                }}>
                <img
                    src={data.imageURL}
                    style={{ height: 60, width: 60, marginBottom: 20 }}
                    alt='bildir'
                />
                <p className='fontMediumItalic' style={Style.cardTitle}>
                    {data.title}
                </p>
            </button>
        )
    }
    useEffect(() => {
        // @ts-ignore
        getServices(state.UserId, state.Business.id, state.Units.id);
    }, []);
    return (
        <>

            <div style={{
                backgroundImage: "url(" + business.imageURL + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: 150,
                width: '100%'
            }}>
                <div className='d-flex' style={{
                    backgroundColor: theme.COLORS.containerColor,
                    opacity: 0.8,
                    height: 40,
                    alignItems:'center'
                }}>
                    <BackButtonComponent />
                    <p className='fontMediumItalic text-center' style={{
                        color:'#fff',
                        fontSize:18,
                        flex:1,
                    }}>
                        {
                            // @ts-ignore
                            state.header
                        }
                    </p>
                </div>
            </div>

            <div className='px-3 mt-4'>

                <div className='d-flex mt-2 text-center'>
                    <p className='fontMediumItalic' style={Style.whichBildir}>
                        {t('SearchScreen', 'whichBildir')}
                    </p>
                </div>
            </div>
            <div className='row pad-mar '>
                {
                    services.map(function (data, index) {
                        return (
                            <div className='col-5' key={index}>
                                {_renderItem(data)}
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}
