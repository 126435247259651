import { useState } from 'react';
import { TranslationService } from '../../../services/translation.service';
import theme from "../../../constants/theme";
import { BlueButtonComponent } from '../../Shared/BlueButtonComponent';
import { ModalSwither } from "../../../utils/modal-switcher";
import { Style } from '../style';
import { InputComponent } from '../../Shared/InputComponent';
import { useAuth } from '../../../context/Auth/authContext';

export const LoginStackForgotPasswordComponent = (props: any) => {
    const t = TranslationService.translate;
    const { forgotPassword } = useAuth();
    const [email, setEmail] = useState('');

    const handleClick = () => {
        if (email === '' || email === undefined) {
            ModalSwither.ShowErrorModal(t('SystemMessages', 'error13'))
            return;
        }
        forgotPassword(email).then(() => props.setStep(0));
    }

    return (
        <div style={{ backgroundColor: theme.COLORS.screenBgColor, height: '100%' }}>
            <div style={Style.container} className='text-center'>
                <img src={theme.ICONS.logo} style={Style.logo} alt='bildir' />
            </div>
            <div className='ms-2'>
                <button className='transparentButton' onClick={() => {
                    props.setStep(0);
                }}>
                    <img src={theme.ICONS.ic_arrow_left} style={{ height: 20, width: 20 }} />
                </button>
            </div>
            <button
                onClick={() => {
                    ModalSwither.HideLoginStackModal()
                }}
                className='transparentButton' style={{ position: 'absolute', top: 20, right: 20 }}>
                <img src={theme.ICONS.ic_delete_white} style={{ width: 24, height: 24 }} />
            </button>
            <div style={{ paddingInline: 24 }} className='mt-3'>
                <p className='fontMedium' style={Style.title}>
                    {t('AutenticationScreen', 'forgotPassword')}
                </p>
            </div>
            <div style={Style.form}>
                <p className='fontMediumItalic' style={Style.label}>
                    {t('AutenticationScreen', 'email')}
                </p>
                <InputComponent
                    listener={setEmail}
                    placeHolder={t('AutenticationScreen', 'email')}
                />

                <div style={{ marginTop: 27 }}>
                    <BlueButtonComponent
                        onClick={handleClick}
                        text={t('AutenticationScreen', 'goOn')}
                    />
                </div>
            </div>
        </div>
    )
}
