import { FireStoreService } from '../../services/firestore.service';
import { FireStoreDocuments } from '../consts/FireStoreDocuments';
import { PathModel } from '../model/PathModel';
import { UserModel } from '../model/UserModel';
export class ProfileFireStoreService {

    static updateProfile( UserModel : UserModel): Promise<boolean> {
        console.log('calling getDevelopersData');

        const collections = [
            {
                collection: FireStoreDocuments.Users,
                document : UserModel.uid
            },
        ] as PathModel[];
      
        return FireStoreService.getInstance()
            .update<boolean>(collections)
            .setBody(UserModel)
            .execute()
            .then(response => response.getContent());

    }

}