import theme from '../../../../constants/theme';
export const Style = ({
  container: {
    justifyContent: 'space-between',
    backgroundColor: theme.COLORS.containerColor,
    paddingHorizontal: 20,
    paddingVertical: 10,
    marginTop: 15,
    width:'100%'
  },
  text: {
    color: '#fff',
    fontSize: 18,
  },
});
