import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../store/state";
import { Modal } from "react-bootstrap";
import { LoginStackLoginComponent } from "./LoginComponent";
import { LoginStackRegisterComponent } from "./RegisterComponent";
import { LoginStackForgotPasswordComponent } from "./ForgetPasswordComponent";
import theme from "../../constants/theme";
import { TranslationService } from "../../services/translation.service";
import { WithoutLogin } from "./WithoutLogin";
import {BlueButtonComponent} from "../Shared/BlueButtonComponent";
const Component = (props: any) => {
  const [step, setStep] = useState(0);
  const t = TranslationService.translate;
  useEffect(() => {
    setStep(0);
  }, [props.show]);

  return (
    <>
      {props.show && (
        <Modal style={{ marginTop: 80 }} fullscreen={true} show={true}>
          {step == 0 && (
            <LoginStackLoginComponent
              setStep={setStep}
              onClick={props.onClick}
            />
          )}
          {step == 1 && (
            <LoginStackRegisterComponent
              setStep={setStep}
              onClick={props.onClick}
            />
          )}
          {step == 2 && <LoginStackForgotPasswordComponent setStep={setStep} />}
          {step == 3 && <WithoutLogin setStep={setStep} />}
          <div className="transparentButton mt-2" style={{padding: 22}}>
          <BlueButtonComponent
              onClick={() => setStep(3)}
              text={t('SharedComponents', 'keepGoOnWithoutLogin')}
          />
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    show: state.ui.loginStackModal,
    onClick: state.ui.loginStackModalOnClick,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export const LoginStackModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
