import { useContext } from 'react';
import { AuthContextData } from './types';
import { AuthContext } from './provide';
export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
