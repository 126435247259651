import React, { useState, useEffect, useRef } from "react";
import theme from "../../../constants/theme";
import { Style } from "./style";
import { Props } from "./types";
export function InputComponent(props: Props) {
  return (
    <div style={{ position: "relative" }}>
      <input
        maxLength={props.maxLenght}
        type={props.type}
        value={props.value}
        style={Style.container}
        onChange={(inputText: any) => {
          props.listener(inputText.target.value);
        }}
        placeholder={props.placeHolder}
      />
      {props.hasIcon ? (
        <div style={{ position: "absolute", bottom: 15, right: 10 }}>
          <button onClick={props.onClickIcon} style={Style.icon}>
            <img src={props.icon} style={{ height: 20, width: 20 }} />
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
