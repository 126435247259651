import { MyAccountScreen } from "../../../screens/Home/Main/MyAccount";
import { PrivacyPolicyScreen } from "../../../screens/Home/Main/PrivacyPolicy";
import { AboutScreen } from "../../../screens/Home/Main/About";
import { SettingScreen } from "../../../screens/Home/Main/Setting";
import { ProfileInformationScreen } from "../../../screens/Home/Main/ProfileInformation";
import { NotificationAndCampaignsScreen } from "../../../screens/Home/Main/NotificationAndCampaigns";
import { CampaignDetailScreen } from "../../../screens/Home/Main/CampaignDetail";
import { CampaignsScreen } from "../../../screens/Home/Search/Campaign";
export const ProfileScreenNavigator = [
  {
    path: "/Profile",
    exact: true,
    screen: (
      <div className={"container-container"}>
        {" "}
        <MyAccountScreen />
      </div>
    ),
  },
  {
    path: "/NotificationsAndCampaigns",
    exact: true,
    screen: (
      <div className={"container-container"}>
        {" "}
        <NotificationAndCampaignsScreen />
      </div>
    ),
  },
  {
    path: "/CampaignDetail",
    exact: true,
    screen: (
      <div className={"container-container"}>
        {" "}
        <CampaignDetailScreen />
      </div>
    ),
  },
  {
    path: "/Campaigns",
    exact: true,
    screen: (
      <div className={"container-container"}>
        {" "}
        <CampaignsScreen />
      </div>
    ),
  },
  {
    path: "/PrivacyPolicy",
    exact: true,
    screen: (
      <div className={""}>
        {" "}
        <PrivacyPolicyScreen />
      </div>
    ),
  },
  {
    path: "/About",
    exact: true,
    screen: (
      <div className={"container-container"}>
        {" "}
        <AboutScreen />
      </div>
    ),
  },
  {
    path: "/Setting",
    exact: true,
    screen: (
      <div className={""}>
        {" "}
        <SettingScreen />
      </div>
    ),
  },
  {
    path: "/ProfileInformation",
    exact: true,
    screen: (
      <div className={"container-container"}>
        {" "}
        <ProfileInformationScreen />
      </div>
    ),
  },
];
