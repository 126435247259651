import useRecordingsList from "../../hooks/use-recordings-list";
import { RecordingsListProps } from "../../constants/types";
import "./styles.css";
import theme from "../../constants/theme";

export default function RecordingsList({ recordings,audio, file, deleteAudio }: RecordingsListProps) {

    return (
        <div className="recordings-container">
            {recordings.length > 0 && (
                <>
                    <div className="recordings-list">
                        {recordings.map((record: any) => (
                            <div className="record" key={record.key}>
                                <audio controls src={record.audio} />
                                <div className="delete-button-container">
                                    <button
                                        className="delete-button transparentButton"
                                        title="Delete this audio"
                                        onClick={() => deleteAudio(record.key)} >
                                        <img src={theme.ICONS.ic_trash} style={{ height: 20, width: 20 }} />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}