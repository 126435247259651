import theme from '../../constants/theme';
import { Style } from './style';
import { Props } from './types';
import { TranslationService } from '../../services/translation.service';
import { getTypeBackgroundColor } from '../../constants/bildirimTypes';
import { MomentConverter } from '../../utils/moment';
import { getState } from '../../constants/bildirimStates';
import { useNavigate } from 'react-router-dom';
export function BildirCardComponent(props: Props) {
  const t = TranslationService.translate;
  const navigation = useNavigate();
  return (
    <div
      className='d-flex mt-3'
      style={Style.container}
      onClick={() => {
        navigation('/Chat', {
          state: {
            data: {
              Bildirim: props.bildirim,
              responsibleUserId: props.bildirim.units.responsibleUser.uid,
            }
          }
        })
      }}>
      <div
        style={
          {
            width: '10%',
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
            backgroundColor: getTypeBackgroundColor(
              props.bildirim.type as any,
            ),
            position: 'relative'
          }
        }>
        <p className='fontMediumItalic' style={{
          color: '#fff',
          fontSize: 14,
          width: 136,
          textAlign: 'center',
          'transform': 'rotate(270deg)',
          justifySelf: 'center',
          position: 'absolute',
          top: 0,
          bottom: 0,
          margin: 'auto'
        }}>
          {props.bildirim.type}
        </p>
      </div>
      <div style={{ marginLeft: 10, flex: 10 }}>
        <div className='d-flex' style={Style.bussinesContainer}>
          <img
            style={Style.imgContainer}
            src={props.bildirim.business?.imageURL}
          />
          <div className='fontRegular' style={{ marginLeft: 12 }}>
            <p style={Style.businessTitle}>
              {props.showUser ?
                props.bildirim.user.name :
                props.bildirim.business?.title
              }
            </p>
            <p style={Style.businessTitle}>
              {props.bildirim.units.title}
            </p>
            <p style={Style.businessTitle}>
              {props.bildirim.services.title}
            </p>
          </div>
        </div>
        <p style={Style.date}>
          {
            // @ts-ignore
            MomentConverter.formatDateDMY(props.bildirim.date.toDate ? props.bildirim.date.toDate() : props.bildirim.date)
          }
        </p>
        <div className='fontMediumItalic d-flex justify-content-between'>
          {props.bildirim.states === 'OPEN' ||
            props.bildirim.states === 'ANSWERED' ? (
            <>
              <p style={Style.businessTitle}>
                {t('SharedComponents', 'waitingAnswer')}
              </p>
              <div
                className='d-flex'
                style={{
                  flexDirection: 'row',
                  marginRight: 10,
                  alignItems: 'center',
                }}>
                <p style={Style.openState}>
                  {getState('OPEN')}
                </p>
                <img
                  style={{ height: 16, width: 14, marginLeft: 3 }}
                  src={theme.ICONS.ic_state_open}
                />
              </div>
            </>
          ) : (
            <>
              <p className='fontMediumItalic' style={Style.businessTitle}>
                {t('SharedComponents', 'ANSWERED')}
              </p>
              <div
                className='d-flex'
                style={{
                  flexDirection: 'row',
                  marginRight: 10,
                  alignItems: 'center',
                }}>
                <p className='fontMediumItalic' style={Style.closedState}>
                  {getState('CLOSED')}
                </p>
                <img
                  style={{ height: 16, width: 14, marginLeft: 3 }}
                  src={theme.ICONS.ic_state_closed}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div >
  );
}
