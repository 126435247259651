export const Style = ({
    text: {
        color: '#fff',
        marginTop: 50,
        fontSize: 15,
        letterSpacing: .23,
    },
    header: { 
        color: '#fff', 
        fontSize: 18 ,
        width:'100%'
    },
});
