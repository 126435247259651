export const Style = ({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    chatContainer:{
        backgroundColor:'#fff',
        paddingBlock:5,
        paddingInline:10,
        alignItems:'center'
    }
});
