import theme from "../../../../constants/theme";
export const Style = {
  title: {
    color: "white",
    fontWeight: "bold",
  },
  tabText: {
    color:'white',
  },
};
