import { Props } from "./types";
import { useLocation } from "react-router-dom";
import { BackButtonComponent } from "../../../../components/Shared/BackButtonComponent";
import theme from "../../../../constants/theme";

export function InformationOfUnitComponent({}: Props) {
  const { state } = useLocation();
  //@ts-ignore
  const { description, unit, business } = state;
  const imageUrl = business.imageURL ? business.imageURL : business.image;

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + imageUrl + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: 150,
          width: "100%",
        }}
      >
        <div
          className="d-flex"
          style={{
            backgroundColor: theme.COLORS.containerColor,
            opacity: 0.8,
            height: 40,
            alignItems: "center",
          }}
        >
          <BackButtonComponent
            goBack={
              //@ts-ignore
              state.goBack ? state.goBack : undefined
            }
          />
          <p
            className="fontMediumItalic text-center"
            style={{
              color: "#fff",
              fontSize: 18,
              flex: 1,
            }}
          >
            {business.title + " " + unit.title}
          </p>
        </div>
      </div>
      <div
        className="px-3 mt-4"
        style={{ backgroundColor: "#fff", height: "100%", paddingBlock: 20 }}
      >
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </>
  );
}
