import { FireStoreService } from "../../services/firestore.service";
import { FireStoreDocuments } from "../consts/FireStoreDocuments";
import { ConditionModel } from "../../services/firestore.request";
import { PathModel } from "../model/PathModel";
import { BusinessModel } from "../model/BusinessModel";
import { UnitsModel } from "../model/UnitModel";
import { ServiceModel, ServiceModelList } from "../model/ServiceModel";
import { BildirimModel, BildirimModelList } from "../model/BildirimModel";
import { MessageModel } from "../model/MessageModel";
import { AnketModel } from "../model/AnketModel";
import { CevapModel } from "../model/CevapModel";
import { typeModel } from "../../constants/bildirimTypes";
import { BildirimStates } from "../../constants/bildirimStates";
import { TranslationService } from "../../services/translation.service";
import { UserModel } from "../model/UserModel";
import { WaiterRequestModel } from "../model/WaiterRequestModel";
import { ActionMenuModel, ActionMenuModelList } from "../model/ActionMenuModel";
import { CommentModel } from "../model/CommentModel";
import {
  NotificationModel,
  NotificationModelList,
} from "../model/NotificationModel";
export class DataFireStoreService {
  static async getUserDetail(UserId: string): Promise<UserModel> {
    console.log("calling getUserDetail");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
    ] as PathModel[];
    const data = await FireStoreService.getInstance()
      .get<UserModel>(collections)
      .execute()
      .then((response) => response.getContent());
    //@ts-ignore
    const user = data.data();
    console.log("jasndjasndjnas", user);
    return Promise.resolve(user);
  }

  static async getBusinessesById(
    userId: string,
    businessId: string,
    loadingDisabled: boolean = false
  ): Promise<BusinessModel> {
    console.log("calling getBusinessesById");
    const collections2 = [
      {
        collection: FireStoreDocuments.Businesses,
        document: businessId,
      },
    ] as PathModel[];
    const model = await FireStoreService.getInstance()
      .get<BusinessModel>(collections2)
      .setLoadingDisabled(loadingDisabled)
      .execute()
      .then((response) => response.getContent());
    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: userId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: businessId,
      },
    ] as PathModel[];

    const image = await FireStoreService.getInstance()
      .get<BusinessModel>(collections)
      .setLoadingDisabled(loadingDisabled)
      .execute()
      .then((response) => response.getContent());
    model.imageURL = image.image;
    return model;
  }

  static getUnitById(
    userId: string,
    businessId: string,
    unitId: string,
    loadingDisabled: boolean = false
  ): Promise<UnitsModel> {
    console.log("calling getUnitById");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: userId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: businessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: unitId,
      },
    ] as PathModel[];

    return FireStoreService.getInstance()
      .get<UnitsModel>(collections)
      .setLoadingDisabled(loadingDisabled)
      .execute()
      .then((response) => response.getContent());
  }

  static async getBusinessesData(
    index: number,
    search?: string
  ): Promise<BusinessModel[]> {
    console.log("calling getBusinessesData");

    const collections = [
      {
        collection: FireStoreDocuments.Businesses,
      },
    ] as PathModel[];

    const data = await FireStoreService.getInstance()
      .get<BusinessModel[]>(collections)
      .setOrderBy("title")
      .setLoadingDisabled(true)
      .setStartAt(search as any)
      .setEndAt(search + "\uf8ff")
      .setLimit(10)
      .execute()
      .then((response) => response.getContent());
    const list: BusinessModel[] = [];
    data.forEach((element) => {
      // @ts-ignore
      const bildirim = element.data();
      list.push(bildirim);
    });
    console.log('List: ',list)
    return list;
  }

  static async getUnitsOfBusiness(
    UserId: string,
    BusinessId: string
  ): Promise<UnitsModel[]> {
    console.log("calling getUnitsOfBusiness");
    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
      },
    ] as PathModel[];
    const conditionList: ConditionModel[] = [
      {
        field: "published",
        filter: "==",
        value: true,
      },
    ];
    const data = await FireStoreService.getInstance()
      .get<UnitsModel[]>(collections)
      .setCondition(conditionList)
      .execute()
      .then((response) => response.getContent());
    const list: UnitsModel[] = [];
    data.forEach((element) => {
      // @ts-ignore
      const bildirim = element.data();
      list.push(bildirim);
    });
    return list;
  }

  static async getServicesOfBusiness(
    UserId: string,
    BusinessId: string,
    UnitId: string
  ): Promise<ServiceModel[]> {
    console.log("calling getServicesOfBusiness");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Services,
      },
    ] as PathModel[];
    const conditionList: ConditionModel[] = [
      {
        field: "published",
        filter: "==",
        value: true,
      },
    ];
    const list: ServiceModel[] = [];

    const data = await FireStoreService.getInstance()
      .get<ServiceModel[]>(collections)
      .setCondition(conditionList)
      .execute()
      .then((response) => response.getContent());
    data.forEach((element) => {
      // @ts-ignore
      const bildirim = element.data();
      list.push(bildirim);
    });
    return list;
  }

  static createBildirim(
    UserId: string,
    BusinessId: string,
    UnitId: string,
    ServicesId: string,
    BildirimModel: BildirimModel,
    BusinessModel: any,
    ResponsibleUserId: string
  ): Promise<string> {
    console.log("calling createBildirim");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Services,
        document: ServicesId,
      },
      {
        collection: FireStoreDocuments.Bildirimler,
      },
    ] as PathModel[];

    return FireStoreService.getInstance()
      .add<string>(collections)
      .setBody(BildirimModel)
      .execute()
      .then(async (response) => {
        BildirimModel.business = BusinessModel;
        const collectionsCurrentUser = [
          {
            collection: FireStoreDocuments.Users,
            document: BildirimModel.user.uid,
          },
          {
            collection: FireStoreDocuments.Bildirimler,
          },
        ] as PathModel[];
        const _id = response.getContent();
        BildirimModel.id = _id;
        await FireStoreService.getInstance()
          .add<String>(collectionsCurrentUser)
          .setId(_id)
          .setBody(BildirimModel)
          .execute()
          .then(async (response) => {
            const collectionsCurrentUser = [
              {
                collection: FireStoreDocuments.Users,
                document: ResponsibleUserId,
              },
              {
                collection: FireStoreDocuments.UnitBildirimler,
              },
            ] as PathModel[];
            await FireStoreService.getInstance()
              .add<String>(collectionsCurrentUser)
              .setId(_id)
              .setBody(BildirimModel)
              .execute();
          });
        return _id;
      });
  }

  static sendMessage(
    BusinessUserId: string,
    ResponsibleUserId: string,
    UserId: string,
    BusinessId: string,
    UnitId: string,
    ServicesId: string,
    BildirimId: string,
    MessageModel: MessageModel
  ): Promise<string> {
    console.log("calling sendMessage");
    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Services,
        document: ServicesId,
      },
      {
        collection: FireStoreDocuments.Bildirimler,
        document: BildirimId,
      },
      {
        collection: FireStoreDocuments.Messages,
      },
    ] as PathModel[];

    return FireStoreService.getInstance()
      .add<string>(collections)
      .setLoadingDisabled(true)
      .setBody(MessageModel)
      .execute()
      .then(async (response) => {
        const collectionsCurrentUser = [
          {
            collection: FireStoreDocuments.Users,
            document: UserId,
          },
          {
            collection: FireStoreDocuments.Bildirimler,
            document: BildirimId,
          },
          {
            collection: FireStoreDocuments.Messages,
          },
        ] as PathModel[];
        const _id = response.getContent();
        await FireStoreService.getInstance()
          .add<String>(collectionsCurrentUser)
          .setLoadingDisabled(true)
          .setId(_id)
          .setBody(MessageModel)
          .execute()
          .then(async (res) => {
            const collectionsCurrentUser = [
              {
                collection: FireStoreDocuments.Users,
                document: ResponsibleUserId,
              },
              {
                collection: FireStoreDocuments.UnitBildirimler,
                document: BildirimId,
              },
              {
                collection: FireStoreDocuments.Messages,
              },
            ] as PathModel[];
            await FireStoreService.getInstance()
              .add<String>(collectionsCurrentUser)
              .setId(_id)
              .setLoadingDisabled(true)
              .setBody(MessageModel)
              .execute();
          });
        return response.getContent();
      });
  }

  static changeBildirimStatus(
    BusinessUserId: string,
    ResponsibleUserId: string,
    UserId: string,
    BusinessId: string,
    UnitId: string,
    ServicesId: string,
    BildirimId: string,
    states: BildirimStates
  ): Promise<string> {
    console.log("calling changeBildirimStatus");
    var updated = { states: states };
    if (states === "CLOSED") {
      //@ts-ignore
      updated = { states: states, date_closed: new Date() };
    }
    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Services,
        document: ServicesId,
      },
      {
        collection: FireStoreDocuments.Bildirimler,
        document: BildirimId,
      },
    ] as PathModel[];

    return FireStoreService.getInstance()
      .update<string>(collections)
      .setLoadingDisabled(true)
      .setBody({ states: states })
      .execute()
      .then(async (response) => {
        const collectionsCurrentUser = [
          {
            collection: FireStoreDocuments.Users,
            document: UserId,
          },
          {
            collection: FireStoreDocuments.Bildirimler,
            document: BildirimId,
          },
        ] as PathModel[];
        await FireStoreService.getInstance()
          .update<String>(collectionsCurrentUser)
          .setLoadingDisabled(true)
          .setBody(updated)
          .execute()
          .then(async (res) => {
            const collectionsCurrentUser = [
              {
                collection: FireStoreDocuments.Users,
                document: ResponsibleUserId,
              },
              {
                collection: FireStoreDocuments.UnitBildirimler,
                document: BildirimId,
              },
            ] as PathModel[];
            await FireStoreService.getInstance()
              .update<String>(collectionsCurrentUser)
              .setLoadingDisabled(true)
              .setBody(updated)
              .execute();
          });
        return response.getContent();
      });
  }

  static async getNormalUserBildirims(
    UserId: string,
    index: number,
    search: string,
    types?: typeModel[],
    states?: BildirimStates[]
  ): Promise<BildirimModel[]> {
    console.log("calling getNormalUserBildirims");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Bildirimler,
      },
    ] as PathModel[];
    const conditionList: ConditionModel[] = [];
    if (states) {
      if (states.length > 0) {
        const condition: ConditionModel = {
          field: "states",
          filter: "==",
          value: states[0],
        };
        conditionList.push(condition);
      }
    }
    if (types) {
      const t = TranslationService.translate;
      types.forEach((element) => {
        const model: ConditionModel = {
          field: "type",
          filter: "==",
          value: t("SearchScreen", element.text),
        };
        conditionList.push(model);
      });
    }
    const list: BildirimModel[] = [];
    const data = await FireStoreService.getInstance()
      .get<BildirimModel[]>(collections)
      .setOrderBy("date", "desc")
      .setCondition(conditionList)
      .setLimit(10)
      .execute()
      .then((response) => {
        return response.getContent();
      });
    data.forEach((element) => {
      // @ts-ignore
      const bildirim = element.data();
      list.push(bildirim);
    });
    return list;
  }

  static async getMainScreenBildirims(
    UserId: string,
    state: BildirimStates
  ): Promise<BildirimModel[]> {
    console.log("calling getNormalUserBildirims");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Bildirimler,
      },
    ] as PathModel[];
    const conditionList: ConditionModel[] = [
      {
        field: "states",
        filter: "!=",
        value: state,
      },
    ];
    const data = await FireStoreService.getInstance()
      .get<BildirimModelList[]>(collections)
      .setCondition(conditionList)
      .execute()
      .then((response) => {
        return response.getContent();
      });
    const list: BildirimModel[] = [];
    data.forEach((element) => {
      // @ts-ignore
      const bildirim = element.data();
      list.push(bildirim);
    });
    return list;
  }

  static async getUnitBildirims(
    UserId: string,
    index: number,
    search: string,
    types?: typeModel[],
    states?: BildirimStates[]
  ): Promise<BildirimModel[]> {
    console.log("calling getUnitBildirims");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.UnitBildirimler,
      },
    ] as PathModel[];
    const conditionList: ConditionModel[] = [];
    if (states) {
      if (states.length > 0) {
        const condition: ConditionModel = {
          field: "states",
          filter: "==",
          value: states[0],
        };
        conditionList.push(condition);
      }
    }
    if (types) {
      const t = TranslationService.translate;
      types.forEach((element) => {
        const model: ConditionModel = {
          field: "type",
          filter: "==",
          value: t("SearchScreen", element.text),
        };
        conditionList.push(model);
      });
    }

    const data = await FireStoreService.getInstance()
      .get<BildirimModelList[]>(collections)
      .setOrderBy("date", "desc")
      .setCondition(conditionList)
      .setLimit(10)
      .execute()
      .then((response) => {
        return response.getContent();
      });

    const list: BildirimModel[] = [];
    data.forEach((element) => {
      // @ts-ignore
      const bildirim = element.data();
      list.push(bildirim);
    });
    return list;
  }

  static async getAnketList(
    UserId: string,
    BusinessId: string,
    UnitId: string,
  ): Promise<AnketModel[]> {
    console.log("calling getAnketList");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Services,
      },
    ] as PathModel[];

    const Services = await FireStoreService.getInstance()
      .get<ServiceModel[]>(collections)
      .execute()
      .then((response) => {
        return response.getContent();
      });

    const list: AnketModel[] = [];

    for (const service of Services) {
      // @ts-ignore
      const s = service.data();
      const collections = [
        {
          collection: FireStoreDocuments.Users,
          document: UserId,
        },
        {
          collection: FireStoreDocuments.Business,
          document: BusinessId,
        },
        {
          collection: FireStoreDocuments.Units,
          document: UnitId,
        },
        {
          collection: FireStoreDocuments.Services,
          document: s.id,
        },
        {
          collection: FireStoreDocuments.Anketler,
        },
      ] as PathModel[];
      const conditionList: ConditionModel[] = [
        {
          field: "published",
          filter: "==",
          value: true,
        },
        // {
        //   field: 'date',
        //   filter: '>=',
        //   value: new Date(),
        // }
      ];
      const surveys = await FireStoreService.getInstance()
        .get<AnketModel[]>(collections)
        .setCondition(conditionList)
        .execute()
        .then((response) => {
          return response.getContent();
        });
      surveys.forEach((element) => {
        // @ts-ignore
        list.push(element.data());
      });
    }
    return list;
  }

  static async createAnketCevap(
    UserId: string,
    BusinessId: string,
    UnitId: string,
    ServiceId: string,
    AnketId: string,
    CevapModel: CevapModel
  ): Promise<string> {
    console.log("calling createAnketCevap");
    console.log(UserId);
    console.log(BusinessId);
    console.log(UnitId);
    console.log(ServiceId);
    console.log(AnketId);
    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Services,
        document: ServiceId,
      },
      {
        collection: FireStoreDocuments.Anketler,
        document: AnketId,
      },
      {
        collection: FireStoreDocuments.Cevaplar,
      },
    ] as PathModel[];
    return await FireStoreService.getInstance()
      .add<string>(collections)
      .setBody(CevapModel)
      .execute()
      .then((response) => {
        return response.getContent();
      });
  }

  static async getNormalUserMessages(
    UserId: string,
    BildirimId: string,
    index: number
  ): Promise<MessageModel[]> {
    console.log("calling getNormalUserMessages");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Bildirimler,
        document: BildirimId,
      },
      {
        collection: FireStoreDocuments.Messages,
      },
    ] as PathModel[];
    const list: MessageModel[] = [];
    const data = await FireStoreService.getInstance()
      .get<MessageModel[]>(collections)
      .setLoadingDisabled(true)
      .setOrderBy("date", "desc")
      //.setStartAt(5)
      //.setEndAt(10)
      .setLimit(index * 10)
      .execute()
      .then((response) => {
        return response.getContent().reverse();
      });
    data.forEach((element) => {
      //@ts-ignore
      list.push(element.data());
    });
    return list;
  }

  static async subscribe(
    BusinessUserId: string,
    BusinessId: string,
    UnitId: string,
    UserId: string
  ): Promise<boolean> {
    console.log("calling subscribe");
    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
    ] as PathModel[];
    const data = await FireStoreService.getInstance()
      .get<string>(collections)
      .execute()
      .then((response) => {
        return response.getContent();
      });
    //@ts-ignore
    const unit = data.data();
    var isAlreadySubcribe = false;
    unit.subscribedUsers.forEach(function (user: string) {
      console.log(user);
      if (user === UserId) {
        isAlreadySubcribe = true;
      }
    });
    if (isAlreadySubcribe) return Promise.resolve(true);
    else {
      var list = unit.subscribedUsers;
      list.push(UserId);
      await FireStoreService.getInstance()
        .update<string>(collections)
        .setBody({ subscribedUsers: list })
        .execute()
        .then((response) => {
          return response.getContent();
        });
      return true;
    }
  }

  static async unSubscribe(
    BusinessUserId: string,
    BusinessId: string,
    UnitId: string,
    UserId: string
  ): Promise<boolean> {
    console.log("calling subscribe");
    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
    ] as PathModel[];
    const data = await FireStoreService.getInstance()
      .get<string>(collections)
      .execute()
      .then((response) => {
        return response.getContent();
      });
    //@ts-ignore
    const unit = data.data();

    //@ts-ignore
    if (!unit.subscribedUsers) {
      //@ts-ignore
      unit.subscribedUsers = [];
    }
    //@ts-ignore
    var list = unit.subscribedUsers.filter((item) => item !== UserId);
    //@ts-ignore
    await FireStoreService.getInstance()
      .update<string>(collections)
      .setBody({ subscribedUsers: list })
      .execute()
      .then((response) => {
        return response.getContent();
      });
    return true;
  }
  static async callWaiterRequest(Model: WaiterRequestModel): Promise<boolean> {
    console.log("calling subscribe");
    const collections = [
      {
        collection: FireStoreDocuments.WaiterRequests,
      },
    ] as PathModel[];
    await FireStoreService.getInstance()
      .add<boolean>(collections)
      .setBody(Model)
      .execute()
      .then((response) => {
        return response.getContent();
      });
    return true;
  }

  static async getUnit(
    BusinessUserId: string,
    BusinessId: string,
    UnitId: string,
    loadingDisabled: boolean = false,
  ): Promise<UnitsModel> {
    console.log("calling subscribe");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
    ] as PathModel[];
    const data = await FireStoreService.getInstance()
      .get<UnitsModel>(collections)
      .setLoadingDisabled(loadingDisabled)
      .execute()
      .then((response) => {
        return response.getContent();
      });
    //@ts-ignore
    const unit = data.data();
    return unit;
  }

  static async getBusinessByBusinessId(
    BusinessId: string
  ): Promise<BusinessModel> {
    console.log("calling getBusinessByBusinessId");

    const collections = [
      {
        collection: FireStoreDocuments.Businesses,
        document: BusinessId,
      },
    ] as PathModel[];
    const data = await FireStoreService.getInstance()
      .get<BusinessModel>(collections)
      .execute()
      .then((response) => {
        return response.getContent();
      });
    //@ts-ignore
    const unit = data.data();
    return unit;
  }

  static async getUnitActionMenus(
    BusinessUserId: string,
    BusinessId: string,
    UnitId: string,
    loadingDisabled: boolean = true
  ): Promise<ActionMenuModel[]> {
    console.log("calling subscribe");
    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.ActionMenus,
      },
    ] as PathModel[];
    const list: ActionMenuModel[] = [];
    const data = await FireStoreService.getInstance()
      .get<ActionMenuModel[]>(collections)
      .setLoadingDisabled(loadingDisabled)
      .execute()
      .then((response) => {
        return response.getContent();
      });
    data.forEach((element) => {
      //@ts-ignore
      list.push(element.data());
    });
    return list;
  }

  static async getUnitComments(
    BusinessUserId: string,
    BusinessId: string,
    UnitId: string
  ): Promise<CommentModel[]> {
    console.log("calling getBusinessByBusinessId");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Comments,
      },
    ] as PathModel[];
    const conditionList: ConditionModel[] = [
      {
        field: "isPublished",
        filter: "==",
        value: true,
      },
    ];
    const list = [] as CommentModel[];
    const data = await FireStoreService.getInstance()
      .get<CommentModel[]>(collections)
      .setCondition(conditionList)
      .execute()
      .then((response) => response.getContent());
    data.forEach((element) => {
      //@ts-ignore
      list.push(element.data());
    });
    return list;
  }

  static async getComment(
    BusinessUserId: string,
    BusinessId: string,
    UnitId: string,
    UserId: string,
    disableLoading = false,
    startDate?: Date,
  ): Promise<CommentModel[]> {
    console.log("calling getBusinessByBusinessId");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Comments,
      },
    ] as PathModel[];
    const conditionList: ConditionModel[] = [
      {
        field: "user.uid",
        filter: "==",
        value: UserId,
      },
    ];
    if (startDate) {
      conditionList.push({
        field: 'date',
        filter: '>',
        value: startDate,
      });
    }
    const list = [] as CommentModel[];
    const data = await FireStoreService.getInstance()
      .get<CommentModel[]>(collections)
      .setLoadingDisabled(disableLoading)
      .setCondition(conditionList)
      .execute()
      .then((response) => response.getContent());
    data.forEach((element) => {
      //@ts-ignore
      list.push(element.data());
    });
    return list;
  }

  static async sendComment(
    BusinessUserId: string,
    BusinessId: string,
    UnitId: string,
    model: CommentModel
  ): Promise<string> {
    console.log("calling getBusinessByBusinessId");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: BusinessUserId,
      },
      {
        collection: FireStoreDocuments.Business,
        document: BusinessId,
      },
      {
        collection: FireStoreDocuments.Units,
        document: UnitId,
      },
      {
        collection: FireStoreDocuments.Comments,
      },
    ] as PathModel[];

    await FireStoreService.getInstance()
      .add<string>(collections)
      .setBody(model)
      .execute()
      .then(async (response) => {
        const _id = response.getContent();
        collections[collections.length - 1].document = _id;
        model.id = _id;
        await FireStoreService.getInstance()
          .update<string>(collections)
          .setBody(model)
          .execute();
      });
    return "successs";
  }
  static async getNotifications(UserId: string): Promise<NotificationModel[]> {
    console.log("calling getNotifications");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Notifications,
      },
    ] as PathModel[];
    const list = [] as NotificationModel[];
    const data = await FireStoreService.getInstance()
      .get<NotificationModel[]>(collections)
      .execute()
      .then((response) => response.getContent());
    data.forEach((element) => {
      //@ts-ignore
      list.push(element.data());
    });
    return list;
  }

  static async markAsReadNotification(
    UserId: string,
    notificationId: string,
    loadingDisabled = true
  ): Promise<string> {
    console.log("calling markAsReadNotification");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Notifications,
        document: notificationId,
      },
    ] as PathModel[];

    return FireStoreService.getInstance()
      .update<string>(collections)
      .setBody({ markAsRead: true })
      .setLoadingDisabled(loadingDisabled)
      .execute()
      .then((response) => response.getContent());
  }

  static async getUserBildirs(
    UserId: string,
    UnitId: string,
    disableLoading = false,
    startDate?: Date
  ): Promise<BildirimModel[]> {
    console.log("calling getBusinessByBusinessId");

    const collections = [
      {
        collection: FireStoreDocuments.Users,
        document: UserId,
      },
      {
        collection: FireStoreDocuments.Bildirimler,
      },
    ] as PathModel[];
    const conditionList: ConditionModel[] = [
      {
        field: "units.id",
        filter: "==",
        value: UnitId,
      },
    ];
    if (startDate) {
      conditionList.push({
        field: "date",
        filter: ">",
        value: startDate,
      });
    }
    const list: BildirimModel[] = [];

    const data = await FireStoreService.getInstance()
      .get<BildirimModelList[]>(collections)
      .setLoadingDisabled(disableLoading)
      .setCondition(conditionList)
      .execute()
      .then((response) => response.getContent());

    data.forEach((element) => {
      //@ts-ignore
      list.push(element.data());
    });
    return list;
  }

  static async getAnketAnswers(
    BusinessUserId: string,
    BusinessId: string,
    UnitId: string,
    UserId: string,
    startDate?: Date,
  ): Promise<number> {
    console.log('calling getAnketList');
    const anketler = await this.getAnketList(
      BusinessUserId,
      BusinessId,
      UnitId,
    );
    let count = 0;
    console.log('anketler', anketler);
    for (const anket of anketler) {
      const collections = [
        {
          collection: FireStoreDocuments.Users,
          document: BusinessUserId,
        },
        {
          collection: FireStoreDocuments.Business,
          document: BusinessId,
        },
        {
          collection: FireStoreDocuments.Units,
          document: UnitId,
        },
        {
          collection: FireStoreDocuments.Services,
          document: anket.serviceId,
        },
        {
          collection: FireStoreDocuments.Anketler,
          document: anket.id,
        },
        {
          collection: FireStoreDocuments.Cevaplar,
        },
      ] as PathModel[];

      const conditionList: ConditionModel[] = [
        {
          field: 'user.id',
          filter: '==',
          value: UserId,
        },
      ];
      if (startDate) {
        conditionList.push({
          field: 'date',
          filter: '>=',
          value: startDate,
        });
      }
      const answers = await FireStoreService.getInstance()
        .get<ServiceModelList[]>(collections)
        .setCondition(conditionList)
        .execute()
        .then(response => {
          return response.getContent();
        });
      console.log(startDate);
      console.log('answers', answers);
      count += answers.length;
    }

    return count;
  }
}
