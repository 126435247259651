import { SearchScreen } from "../../../screens/Home/Search";
import { UnitsScreen } from "../../../screens/Home/Search/Units";
import { TransactionsScreen } from "../../../screens/Home/Search/Transactions";
import { ServicesScreen } from "../../../screens/Home/Search/Services";
import { BildirScreen } from "../../../screens/Home/Search/Bildir";
import { AnketListScreen } from "../../../screens/Home/Search/AnketList";
import { AnketScreen } from "../../../screens/Home/Search/Anket";
import { CallWaiterRequestScreen } from "../../../screens/Home/Search/CallWaiterRequest";
import { BildirsByBusinessScreen } from "../../../screens/Home/Search/BildirsByBusiness";
import { InformationOfUnitScreen } from "../../../screens/Home/Search/InformationOfUnit";
import { UnitsCommentScreen } from "../../../screens/Home/Search/UnitComments";
export const SearchScreenNavigator = [
    {
        path: '/search',
        exact: true,
        screen:  <div className={"container-container"}> <SearchScreen /></div>
    },
    {
        path: '/units',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><UnitsScreen /></div>,
    },
    {
        path: '/transactions',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><TransactionsScreen /></div>,
    },
    {
        path: '/services',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><ServicesScreen /></div>,
    },
    {
        path: '/bildir',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><BildirScreen /></div>,
    },
    {
        path: '/anketList',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><AnketListScreen /></div>,
    },
    {
        path: '/anket',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><AnketScreen /></div>,
    },
    {
        path: '/callWaiterRequest',
        exact: true,
        screen:<div className={"container-container-without-bottom-tabs"}><CallWaiterRequestScreen /></div>,
    },
    {
        path: '/BildirByBusiness',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><BildirsByBusinessScreen /></div>,
    },
    {
        path: '/InformationOfUnit',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><InformationOfUnitScreen /></div>,
    },
    {
        path: '/comments',
        exact: true,
        screen: <div className={"container-container-without-bottom-tabs"}><UnitsCommentScreen /></div>,
    },
]
