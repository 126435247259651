import fontStyles from './fonts';
import { TranslationService } from '../services/translation.service';
const { innerWidth: width, innerHeight: height } = window;
export default {
    COLORS: {
        screenBgColor: '#1f1e2e',
        containerColor: '#2f2f42',
        containerLabelColor: '#858597',
        containerTextColor: '#b8b8d2',
        componentBgColor: '#3e3e55',
        blue: '#0a72bb',
        yellow: '#ffc200',
        tertiary: '#eaeaff',
        green: '#34a853',
        red: '#ff0000',
    },
    FONTS: {
        fontMedium: fontStyles.fontMedium,
        fontItalic: fontStyles.fontItalic,
        fontMediumItalic: fontStyles.fontMediumItalic,
        fontRegular: fontStyles.fontRegular,
        fontBold: fontStyles.fontBold,
        fontExtraBold: fontStyles.fontExtraBold,
    },
    SPACES: {
        paddingHorizontal: 24,
    },
    STYLE: {
        inputLabel: {
            color: '#858597',
            fontSize: 14,
        },
        container: {
            backgroundColor: '#1f1e2e',
        },
        headerStyle: {
            backgroundColor: '#1f1e2e',
            borderBottomWidth: 0,
            elevation: 0,
            shadowOpacity: 0,
            transform: [{ scaleX: TranslationService.getLang().isRTL ? -1 : 1 }],
        },
        content: {
            marginTop: 10,
            height: height * 1.4,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            paddingInline: 24,
            paddingTop: 24,
            backgroundColor: '#2f2f42',
        },
        lineStyle: {
            backgroundColor: '#2f2f42',
            padding: 14,
            borderRadius: 8,
            alignItems: 'center' as any,
            width: '100%',
        },
        logoContainer: {
            width: 37,
            height: 44,
            alignSelf: 'center' as any,
            marginTop: 5,
        },
        img: {
            height: 68,
            width: 68,
            borderRadius: 8,
        },
        searchMessage: {
            marginTop: 14,
            color: '#fff',
            fontSize: 18,
        },
        verticalLine: {
            height: 25,
            borderWidth: 0.5,
            borderColor: '#adadad',
            marginRight: 10,
        },
    },
    ICONS: {
        logo: require('../assets/images/logo.png'),
        ic_arrow_right: require('../assets/images/ic_arrow_right.png'),
        ic_arrow_down: require('../assets/images/ic_arrow_down.png'),
        ic_arrow_up: require('../assets/images/ic_arrow_up.png'),
        ic_search: require('../assets/images/ic_search.png'),
        ic_delete: require('../assets/images/ic_delete.png'),
        ic_arrow_left: require('../assets/images/ic_arrow_left.png'),
        ic_survey: require('../assets/images/ic_survey.png'),
        ic_call_waiter: require('../assets/images/ic_call_waiter.png'),
        ic_follow: require('../assets/images/ic_follow.png'),
        ic_followed: require('../assets/images/ic_followed.png'),
        ic_message: require('../assets/images/ic_message.png'),
        ic_manager: require('../assets/images/ic_manager.png'),
        ic_food_service: require('../assets/images/ic_food_service.png'),
        ic_cleaning_service: require('../assets/images/ic_cleaning_service.png'),
        ic_flavor: require('../assets/images/ic_flavor.png'),
        ic_mic: require('../assets/images/ic_mic.png'),
        ic_close: require('../assets/images/ic_close.png'),
        ic_delete_white: require('../assets/images/ic_delete_white.png'),
        ic_media: require('../assets/images/ic_media.png'),
        ic_cam: require('../assets/images/ic_cam.png'),
        ic_recording: require('../assets/images/ic_recording.png'),
        ic_play: require('../assets/images/ic_play.png'),
        ic_pause: require('../assets/images/ic_pause.png'),
        ic_trash: require('../assets/images/ic_trash.png'),
        ic_state_closed: require('../assets/images/ic_state_closed.png'),
        ic_state_open: require('../assets/images/ic_state_open.png'),
        ic_clock: require('../assets/images/ic_clock.png'),
        ic_anket: require('../assets/images/ic_anket.png'),
        ic_filter: require('../assets/images/ic_filter.png'),
        ic_dots: require('../assets/images/ic_dots.png'),
        ic_plus: require('../assets/images/ic_plus.png'),
        ic_send: require('../assets/images/ic_send.png'),
        ic_smile: require('../assets/images/ic_smile.png'),
        logoSplash: require('../assets/images/logoSplash.png'),
        ic_camera: require('../assets/images/ic_camera.png'),
        ic_tik: require('../assets/images/ic_tik.png'),
        ic_bildir: require('../assets/images/ic_bildir.png'),
        ic_menu: require('../assets/images/ic_menu.png'),
        ic_info: require('../assets/images/ic_info.png'),
        ic_comment: require('../assets/images/ic_comment.png'),
        ic_lock: require('../assets/images/ic_lock.png'),
        ic_campaing: require('../assets/images/ic_campaing.png')
    },
};
