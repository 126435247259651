import theme from '../../../../constants/theme';
import { TranslationService } from '../../../../services/translation.service';
import { Style } from './style';
import { useAuth } from '../../../../context/Auth/authContext';
import { useNavigate } from 'react-router-dom';
import { BackButtonComponent } from '../../../../components/Shared/BackButtonComponent';
export function MyAccountScreenComponent() {
  const navigation = useNavigate()
  const LineComponent = (
    url: string,
    text: string,
    isNavigation: boolean,
    marginTop: number = 2
  ) => {
    return (
      <button
        className='transparentButton d-flex'
        onClick={() => {
          if (isNavigation) {
            navigate(url)
          } else { signOut(); navigation('/') };
        }}
        style={{
          justifyContent: 'space-between',
          backgroundColor: theme.COLORS.containerColor,
          paddingInline: 20,
          paddingBlock: 10,
          marginTop: marginTop,
          width: '100%'
        }}>
        <p className='fontMedium' style={Style.text}>{text}</p>
        <img
          src={theme.ICONS.ic_arrow_right}
          style={{ alignSelf: 'center', width: 16, height: 16 }}
        />
      </button>
    );
  };
  const t = TranslationService.translate;

  function navigate(url: string) {
    navigation(url);
  }

  const { signOut, user } = useAuth();

  return (
    <div className='full-height' style={{ paddingTop: 120 }}>
      <BackButtonComponent />
      <div className='mt-5' />
      {
        user && LineComponent(
          '/ProfileInformation',
          t('MainScreen', 'profileInformation'),
          true
        )
      }
      {
        user && LineComponent(
          '/NotificationsAndCampaigns',
          t('MainScreen', 'notificationAndCampaings'),
          true
        )
      }
      {LineComponent('/Setting', t('MainScreen', 'setting'), true)}
      {LineComponent(
        '/PrivacyPolicy',
        t('MainScreen', 'privacyPolicy'),
        true,
      )}
      {LineComponent('/About', t('MainScreen', 'about'), true)}
      {user && LineComponent('About', t('MainScreen', 'signOut'), false, 20)}
    </div>
  );
}
