import theme from '../../../../constants/theme';
export const Style = ({
    headerContainer:{
        backgroundColor: theme.COLORS.containerColor, 
        paddingVertical: 10, 
    },
    header:{
        color:'#fff',
        fontSize:18
    },
    whichBildir: { 
        color: '#fff', 
        fontSize: 18 ,
        width:'100%'
    },
    card:{
        alignItems:'center',
        justifyContent:'center',
        height:120,
        width:150,
        backgroundColor:theme.COLORS.containerColor,
        marginLeft:30,
        borderRadius:12,
        marginTop:20
    },
    cardTitle : {
        color:'#fff',
        fontSize:19, 
        bottom:10
    }
});