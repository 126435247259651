import { TranslationService } from "../services/translation.service"

export type BildirimStates = 'OPEN' | 'ANSWERED' | 'CLOSED'

export const getState = (type: BildirimStates) => {
    const t = TranslationService.translate;
    switch (type) {
        case 'OPEN':
            return t('SharedComponents', 'OPEN')
        case 'ANSWERED':
            return t('SharedComponents', 'ANSWERED')
        case 'CLOSED':
            return t('SharedComponents', 'CLOSED')
    }
}

export const bildirimStateList: BildirimStates[] = [
    'OPEN', 'CLOSED'
]