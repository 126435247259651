import { useState } from 'react';
import { Props } from './types';
import { Style } from './style';
import theme from '../../../constants/theme';
import { TranslationService } from '../../../services/translation.service';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { BlueButtonComponent } from '../../Shared/BlueButtonComponent';
import { useAuth } from '../../../context/Auth/authContext';

export function HeaderComponent(props: Props) {

    const [show, setShow] = useState(false)
    const navigation = useNavigate()
    const t = TranslationService.translate;
    const [isOpen, setIsOpen] = useState(props.bildirim.states === 'CLOSED' ? false : true);
    const [isClose, setIsClose] = useState(props.bildirim.states === 'CLOSED')
    const { user } = useAuth()
    return (
        <>
            <div className='d-flex' style={Style.header}>
                <button className='transparentButton' style={{ flex: 1 }} onClick={() => { navigation(-1) }}>
                    <img src={theme.ICONS.ic_arrow_left} style={{ height: 16, width: 16 }} />
                </button>
                <div className='d-flex' style={{ alignItems: 'center', flex: 8 }}>
                    <img src={props.userDetail ? props.userDetail.photoURL : ''} style={{ height: 36, width: 36, borderRadius: 48 }} />
                    <p className='ms-2' style={Style.userDetailName}>{props.userDetail ? props.userDetail.displayName : ''}</p>
                </div>
                {
                    //@ts-ignore
                    (user?.group?.length !== 1 && user.group[0] !== 5) &&
                    <>
                        {
                            !show ?
                                <button className='transparentButton' onClick={() => setShow(true)} >
                                    <img src={theme.ICONS.ic_dots} style={{ height: 17, width: 6 }} />
                                </button> :
                                <button className='transparentButton' onClick={() => setShow(false)}>
                                    <img src={theme.ICONS.ic_delete_white} style={{ width: 16, height: 16 }} />
                                </button>
                        }
                    </>
                }
            </div>
            {
                show &&
                <Modal
                    show={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div style={{ backgroundColor: theme.COLORS.screenBgColor }}>
                        <div className='text-end mt-2'>
                            <button className='transparentButton' onClick={() => setShow(false)}>
                                <img src={theme.ICONS.ic_delete_white} style={{ width: 16, height: 16 }} />
                            </button>
                        </div>
                        <div className='fontMedium text-center' style={{ marginTop: 26, color: '#fff' }}>
                            {t('SharedComponents', 'updateStatus')}
                        </div>
                        <div className='d-flex mt-3' style={{ justifyContent: 'center' }}>
                            <button className='transparentButton' style={{
                                width: 100,
                                padding: 5,
                                backgroundColor: isOpen ? theme.COLORS.green : theme.COLORS.containerLabelColor,
                                color: '#fff'
                            }} onClick={() => {
                                setIsOpen(true)
                                setIsClose(false)
                            }} >
                                {t('SharedComponents', 'OPEN')}
                            </button>
                            <button className='transparentButton' style={{
                                width: 100,
                                backgroundColor: isClose ? theme.COLORS.red : theme.COLORS.containerLabelColor,
                                marginLeft: 20,
                                color: '#fff'
                            }} onClick={() => {
                                setIsOpen(false)
                                setIsClose(true)
                            }} >
                                {t('SharedComponents', 'CLOSE')}
                            </button>
                        </div>
                        <p className='fontMedium text-center mt-4' style={{ fontSize: 12, marginTop: 26, color: '#fff' }}>
                            {
                                isClose ?
                                    t('SharedComponents', 'markClose') :
                                    t('SharedComponents', 'markKeepOn')
                            }
                        </p>
                        <div className='my-3 text-center mx-5 mt-4'>
                            <BlueButtonComponent
                                text={t('SharedComponents', 'save')}
                                onClick={() => {
                                    isClose && props.closeBildirim(true)
                                    setShow(false)
                                }}
                            />
                        </div>
                    </div>
                </Modal>
                // <div style={{
                //     padding: 10,
                //     backgroundColor: theme.COLORS.containerColor,
                //     width: '40%',
                //     position: 'absolute',
                //     right: 0,
                //     top: 50,
                //     zIndex: 5
                // }}>
                //     <button className='transparentButton' onClick={() => { props.closeBildirim(true) }}>
                //         <p className='fontMediumItalic text-center' style={Style.dropdownText}>
                //             {t('SharedComponents', 'closeBildirim')}
                //         </p>
                //     </button>
                // </div>
            }
        </>
    );
}