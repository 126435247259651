import theme from '../../constants/theme';
export const Style = ({
    container:{
        backgroundColor:theme.COLORS.componentBgColor,
        borderRadius:12,
        height:136,
    },
    bussinesContainer:{
        marginTop:26
    },
    imgContainer:{
        height:48,
        width:48,
        borderRadius:12
    },
    businessTitle:{
        color:'#fff',
        fontSize:12
    },
    openState:{
        color:theme.COLORS.green,
        fontSize:15
    },
    closedState:{
        color:theme.COLORS.red,
        fontSize:15
    },
    date:{
        color:theme.COLORS.containerTextColor,
        fontSize:12,
        marginTop:20
    }
});
