import theme from "../../../constants/theme";
export const Style = ({
    centeredView: {
        flex: 1,
        marginBottom: 170,
        backgroundColor: theme.COLORS.screenBgColor
    },
    modalView: {
        backgroundColor: theme.COLORS.containerColor,
        borderRadius: 20,
        paddingInline: 35,
        paddingBlock: 45,
    },
    modalText: {
        marginTop: 10,
        color: theme.COLORS.containerTextColor,
        fontSize: 15
    },
    iconContainer: {
        width: 72,
        paddingInline: 15,
        paddingBlock: 20,
        backgroundColor: '#34a853',
        borderRadius: 36,
        marginLeft: '42%'
    }
});
