import theme from '../../constants/theme';
export const Style = ({
    title: {
        color: '#fff',
        fontSize: 12
    },
    content: {
        marginTop: 1,
        justifyContent: 'space-between'
    },
    card: {
        height: 22,
        width: 22,
        borderRadius: 33,
        alignItems: 'center',
        justifyContent: 'center'
    },
    container: {
        backgroundColor: '#2f2f42',
        padding: 14,
        borderRadius: 8,
    },
    cardText:{
        color:theme.COLORS.containerLabelColor,
        fontSize:12
    }
});
