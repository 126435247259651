import { DataFireStoreService } from "../../../../api/services/data-firestore.service";
import { useState } from "react";
import { Props } from "./types";
import { Style } from "./style";
import theme from "../../../../constants/theme";
import { BusinessModel } from "../../../../api/model/BusinessModel";
import { useLocation } from "react-router-dom";
import { BackButtonComponent } from "../../../../components/Shared/BackButtonComponent";
import { useNavigate } from "react-router-dom";
import { TranslationService } from "../../../../services/translation.service";
import { BildirTypeComponent } from "../../../../components/BildirTypeComponent";
import { BildirPointComponent } from "../../../../components/BildirPointComponent";
import { BlueButtonComponent } from "../../../../components/Shared/BlueButtonComponent";
import { ImageMediaPickerHalfModal } from "../../../../components/ImageMediaPickerHalfModal";
import { BildirInputMediaComponent } from "../../../../components/BildirInputMediaComponent";
import { UseRecorder } from "../../../../constants/types";
import useRecorder from "../../../../hooks/use-recorder";
import useRecordingsList from "../../../../hooks/use-recordings-list";
import { ModalSwither } from "../../../../utils/modal-switcher";
import { BildirimModel } from "../../../../api/model/BildirimModel";
import { useAuth as UseAuth } from "../../../../context/Auth/authContext";
import { MessageModel } from "../../../../api/model/MessageModel";
import { FileStorageService } from "../../../../api/services/file-storage.service";
import { StringFormatter } from "../../../../utils/string";
import {
  NotificationModel,
  sendNotification,
} from "../../../../services/notification.service";
export function BildirScreenComponent({}: Props) {
  const { recorderState, ...handlers }: UseRecorder = useRecorder();
  const { audio, file } = recorderState;
  const { recordings, deleteAudio } = useRecordingsList(audio, file as any);
  const t = TranslationService.translate;
  const { state } = useLocation();
  const { user, publicUser } = UseAuth();
  const navigation = useNavigate();
  // @ts-ignore
  const [business, setBusiness] = useState(state.Business as BusinessModel);
  const [type, setType] = useState(t("SearchScreen", "thanks"));
  const [point, setPoint] = useState(0);
  const [showImagePicker, setShowImagePicker] = useState(false);
  const [imgFiles, setImgFiles] = useState(
    [] as { uri: string; fileName: string }[]
  );
  const [text, setText] = useState("");
  const [micPath, setMicPath] = useState("");
  function popImg(i: number) {
    const list = imgFiles.filter((item, index) => index != i);
    setImgFiles(list);
  }

  const bildir = () => {
    if (
      user &&
      user.isMemberOf &&
      //@ts-ignore
      user.isMemberOf.businessID === state.Business.id
    ) {
      ModalSwither.ShowErrorModal(t("SystemMessages", "error16"));
      return;
    }
    if (text === "") {
      ModalSwither.ShowErrorModal(t("SystemMessages", "error11"));
      return;
    }
    if (!user && !publicUser) {
      ModalSwither.ShowLoginStackModal();
      return;
    }
    let refUser = user ?? publicUser;
    const model: BildirimModel = {
      states: publicUser !== undefined ? "CLOSED" : "OPEN",
      user: {
        name: refUser?.displayName as any,
        uid: refUser?.uid as any,
      },
      puan: point,
      type: type,
      date: new Date(),
      //@ts-ignore
      units: {
        //@ts-ignore
        title: state.Units.title,
        //@ts-ignore
        id: state.Units.id,
        responsibleUser: {
          //@ts-ignore
          uid: state.ResponsibleUserId,
        },
      },
      //@ts-ignore
      services: state.Services,
    };
    if(!user && publicUser) {
      model.phone = publicUser.phone;
    }
    DataFireStoreService.createBildirim(
      //@ts-ignore
      state.UserId,
      //@ts-ignore
      state.Business.id,
      //@ts-ignore
      state.Units.id,
      //@ts-ignore
      state.Services.id,
      model,
      //@ts-ignore
      state.Business,
      //@ts-ignore
      state.ResponsibleUserId
    ).then(async (BildirimId) => {
      if (text !== "") {
        const messageTextModel: MessageModel = {
          date: new Date(),
          hasImage: false,
          hasVoice: false,
          user: {
            name: refUser?.displayName as any,
            uid: refUser?.uid as any,
            //@ts-ignore
            photoURL: refUser?.photoURL ? refUser?.photoURL : "",
          },
          text: text,
        };
        await DataFireStoreService.sendMessage(
          //@ts-ignore
          state.UserId,
          //@ts-ignore
          state.ResponsibleUserId,
          refUser?.uid as any,
          //@ts-ignore
          state.Business.id,
          //@ts-ignore
          state.Units.id,
          //@ts-ignore
          state.Services.id,
          BildirimId,
          messageTextModel
        );
      }
      for (const img of imgFiles) {
        await FileStorageService.uploadFile(
          BildirimId + "/" + img.fileName,
          img.uri
        ).then(async (link) => {
          const messageImageModel: MessageModel = {
            date: new Date(),
            hasImage: true,
            imageUrl: link,
            hasVoice: false,
            user: {
              name: refUser?.displayName as any,
              uid: refUser?.uid as any,
              //@ts-ignore
              photoURL: refUser?.photoURL ? user?.photoURL : "",
            },
            text: "",
          };
          await DataFireStoreService.sendMessage(
            //@ts-ignore
            state.UserId,
            //@ts-ignore
            state.ResponsibleUserId,
            refUser?.uid as any,
            //@ts-ignore
            state.Business.id,
            //@ts-ignore
            state.Units.id,
            //@ts-ignore
            state.Services.id,
            BildirimId,
            messageImageModel
          );
        });
      }
      if (micPath !== "") {
        await FileStorageService.uploadFile(
          BildirimId + "/" + StringFormatter.generateId(10),
          micPath
        ).then(async (link) => {
          const messageImageModel: MessageModel = {
            date: new Date(),
            hasImage: false,
            hasVoice: true,
            voiceUrl: link,
            user: {
              name: refUser?.displayName as any,
              uid: refUser?.uid as any,
              //@ts-ignore
              photoURL: refUser?.photoURL ? refUser?.photoURL : "",
            },
            text: "",
          };
          await DataFireStoreService.sendMessage(
            //@ts-ignore
            state.UserId,
            //@ts-ignore
            state.ResponsibleUserId,
            refUser?.uid as any,
            //@ts-ignore
            state.Business.id,
            //@ts-ignore
            state.Units.id,
            //@ts-ignore
            state.Services.id,
            BildirimId,
            messageImageModel
          );
        });
      }
      try {
        //@ts-ignore
        if (user) {
          const resUser = await DataFireStoreService.getUserDetail(
            //@ts-ignore
            state.ResponsibleUserId
          );
          const notificationData: NotificationModel = {
            data: {
              screen: "Chat",
              bildirim: model,
              //@ts-ignore
              responsibleUserId: state.ResponsibleUserId,
            },
            notification: {
              body: t("SystemMessages", "newBildir"),
              title: "BILDIR",
              image: null,
            },
            //@ts-ignore
            to: resUser.fcmTokens[resUser.fcmTokens.length - 1],
          };
          await sendNotification(notificationData);
        }
      } catch (err) {
      } finally {
        ModalSwither.ShowSuccessModal(t("SystemMessages", "success3"));
        navigation("/");
      }
    });
  };

  return (
    <div style={{}}>
      <div
        style={{
          backgroundImage: "url(" + business.imageURL + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: 150,
          width: "100%",
        }}
      >
        <div
          className="d-flex"
          style={{
            backgroundColor: theme.COLORS.containerColor,
            opacity: 0.8,
            height: 40,
            alignItems: "center",
          }}
        >
          <BackButtonComponent />
          <p
            className="fontMediumItalic text-center"
            style={{
              color: "#fff",
              fontSize: 18,
              flex: 1,
            }}
          >
            {
              // @ts-ignore
              state.header
            }
          </p>
        </div>
      </div>

      <div className="px-3 mt-4">
        <div className="d-flex">
          <p className="fontMediumItalic text-center" style={Style.whichBildir}>
            {
              // @ts-ignore
              state.Services.title + " " + t("SearchScreen", "bildirLowerCase")
            }
          </p>
        </div>
        <div className="mt-2">
          <BildirTypeComponent
            title={t("SearchScreen", "bildirType")}
            listener={setType}
          />
        </div>
        <div className="mt-2">
          <BildirPointComponent
            title={t("SearchScreen", "point")}
            listener={setPoint}
          />
        </div>
        <div className="mt-2">
          <BildirInputMediaComponent
            title={t("SearchScreen", "description")}
            textListener={setText}
            micFileListener={setMicPath}
            audio={audio}
            handlers={handlers}
            recorderState={recorderState}
            file={file as any}
            deleteAudio={deleteAudio}
            recordings={recordings as any}
          />
        </div>
        <div className="mt-2">
          <div
            className="d-flex"
            style={{ justifyContent: "space-between", marginTop: 23 }}
          >
            <button
              className="transparentButton"
              onClick={() => {
                setShowImagePicker(true);
              }}
            >
              <img
                src={theme.ICONS.ic_media}
                style={{ height: 50, width: 50 }}
              />
            </button>
            <div style={{ width: 120 }}>
              <BlueButtonComponent
                text={t("SearchScreen", "send")}
                onClick={bildir}
              />
            </div>
          </div>
        </div>
      </div>
      {showImagePicker ? (
        <ImageMediaPickerHalfModal
          close={() => {
            setShowImagePicker(false);
          }}
          imgFiles={imgFiles as any}
          popImg={popImg}
          fileListener={(model: {}) => {
            setImgFiles((x) => [...x, model as any]);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
