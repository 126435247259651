import React, { useState } from 'react';
import { Props } from './types';
import { Style } from './style';
import theme from '../../../constants/theme';
import { InputComponent } from '../../../components/Shared/InputComponent';
import { BlueButtonComponent } from '../../../components/Shared/BlueButtonComponent';
import { useAuth } from '../../../context/Auth/authContext';
import { TranslationService } from '../../../services/translation.service';
import { BackButtonComponent } from '../../../components/Shared/BackButtonComponent';
import { ModalSwither } from '../../../utils/modal-switcher';
import { useNavigate } from 'react-router-dom';

export function ForgotPasswordScreenComponent({ }: Props) {
  const t = TranslationService.translate;
  const navigation = useNavigate()
  const { forgotPassword } = useAuth();
  const [email, setEmail] = useState('');

  const handleClick = () => {
    if (email === '' || email === undefined) {
      ModalSwither.ShowErrorModal(t('SystemMessages', 'error13'))
      return;
    }
    forgotPassword(email).then(() => navigation('/'));
  }

  return (
    <>
      <div style={Style.container} className='text-center'>
        <img src={theme.ICONS.logo} style={Style.logo} alt='bildir' />
      </div>
      <div className='ms-2'>
        <BackButtonComponent />
      </div>
      <div style={{ paddingInline: 24 }} className='mt-3'>
        <p className='fontMedium' style={Style.title}>
          {t('AutenticationScreen', 'forgotPassword')}
        </p>
      </div>
      <div style={Style.form}>
        <p className='fontMediumItalic' style={Style.label}>
          {t('AutenticationScreen', 'email')}
        </p>
        <InputComponent
          listener={setEmail}
          placeHolder={t('AutenticationScreen', 'email')}
        />

        <div style={{ marginTop: 27 }}>
          <BlueButtonComponent
            onClick={handleClick}
            text={t('AutenticationScreen', 'goOn')}
          />
        </div>
      </div>
    </>
  );
}