import { Props } from './types';
import { TranslationService } from '../../../services/translation.service';
export function TextAreaComponent(props: Props) {
    const t = TranslationService.translate;

    return (
        <textarea
            className='fontMediumItalic'
            value={props.value}
            autoFocus={props.autoFocus}
            rows={6}
            style={{ color: '#fff', width: '100%', backgroundColor:'transparent' }}
            onChange={(value)=>{
                props.listener(value.target.value)
            }}
            placeholder={t('SearchScreen', 'writeMessage')}
        />
    )
}