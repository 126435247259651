import { formatMinutes, formatSeconds } from "../../utils/format-time";
import { RecorderControlsProps } from "../../constants/types";
import "./styles.css";
import theme from "../../constants/theme";

export default function RecorderControls({ recorderState, handlers }: RecorderControlsProps) {
    const { recordingMinutes, recordingSeconds, initRecording } = recorderState;
    const { startRecording, saveRecording } = handlers;

    return (
        <div
           >
            <img src={theme.ICONS.ic_recording} style={{ height: 64, width: 64 }} />
            <br />
            <span>{formatMinutes(recordingMinutes)}</span>
            <span>:</span>
            <span>{formatSeconds(recordingSeconds)}</span>
            <div className="controls-container">
                <div className="start-button-container">
                    {initRecording ? (
                        <button
                            className="transparentButton"
                            title="Save recording"
                            disabled={recordingSeconds === 0}
                            onClick={saveRecording}
                        >
                            <img src={theme.ICONS.ic_pause} style={{ height: 20, width: 20 }} />
                        </button>
                    ) : (
                        <button className="transparentButton" title="Start recording" onClick={startRecording}>
                            <img src={theme.ICONS.ic_play} style={{ height: 20, width: 20 }} />
                        </button>
                    )}
                </div>
            </div>
        </div>

    );
}