import React, { useState } from 'react';
import theme from '../../constants/theme';
import { Style } from './style';
import { Props } from './types';
import { TranslationService } from '../../services/translation.service';
export function BildirPointComponent(props: Props) {
    const t = TranslationService.translate;
    const [point, setPoint] = useState(0)
    const [list, setList] = useState([
        {
            text: 1,
            isActive: false
        },
        {
            text: 2,
            isActive: false
        },
        {
            text: 3,
            isActive: false
        },
        {
            text: 4,
            isActive: false
        },
        {
            text: 5,
            isActive: false
        },
        {
            text: 6,
            isActive: false
        },
        {
            text: 7,
            isActive: false
        },
        {
            text: 8,
            isActive: false
        },
        {
            text: 9,
            isActive: false
        },
        {
            text: 10,
            isActive: false
        },
    ])

    const pointListener = (data: number) => {
        setPoint(data)
        props.listener(data)
    }
    return (
        <>
            <p className='fontMediumItalic' style={Style.title}>{props.title}</p>
            <div style={Style.container}>
                <div className='d-flex' style={Style.content}>
                    {
                        list.map(function (data, index) {
                            data.text <= point ? data.isActive = true : data.isActive = false;
                            const specialStyle = {
                                height: 22,
                                width: 22,
                                borderRadius: 33,
                                border:0,
                                backgroundColor: data.isActive ? theme.COLORS.blue : theme.COLORS.tertiary
                            }
                            const textStyle = {
                                color: data.isActive ? '#fff' : '#000',
                            }
                            return (
                                <button key={index} style={specialStyle} onClick={() => {
                                    pointListener(data.text)
                                }}>
                                    <p className='fontMediumItalic' style={textStyle}>{data.text}</p>
                                </button>
                            )
                        })
                    }
                </div>
                <div className='mt-2 d-flex' style={Style.content}>
                    <p  className='fontMediumItalic' style={Style.cardText}>
                        {t('SearchScreen', 'bad')}
                    </p>
                    <p className='fontMediumItalic' style={Style.cardText}>
                        {t('SearchScreen', 'good')}
                    </p>
                </div>
            </div>
        </>
    )
}