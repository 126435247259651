import { Style } from "./style";
import { connect } from 'react-redux';
import { GlobalState } from "../../../store/state";
import theme from "../../../constants/theme";
import { BlueButtonComponent } from "../BlueButtonComponent";
import { ModalSwither } from "../../../utils/modal-switcher";
import { TranslationService } from "../../../services/translation.service";
import { Modal } from 'react-bootstrap';
const Component = (props: any) => {
    const t = TranslationService.translate;
    const img = require('../../../assets/images/ic_success.png')
    const logo = require('../../../assets/images/logo.png')
    return (
        <>
            {
                props.show &&
                <Modal
                    show={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="text-center" style={{ flex: 1, backgroundColor: theme.COLORS.screenBgColor }}>
                        <div style={{ alignItems: 'center', marginTop: 26 }}>
                            <img src={logo} style={{ height: 58, width: 48 }} />
                        </div>
                        <div className="mt-2" style={Style.centeredView}>
                            <div className="text-center" style={Style.modalView}>
                                <div style={Style.iconContainer}>
                                    <img src={img} style={{height: 32, width: 32}} />
                                </div>
                                <p className="fontItalic mt-2" style={{ marginTop: 10, color: '#fff', fontSize: 16 }}>
                                    {t('SystemMessages', 'success')}
                                </p>
                                <p className="fontMedium text-center mt-2 mb-2" style={Style.modalText}>
                                    {props.message}
                                </p>
                                <BlueButtonComponent
                                    onClick={() => {
                                        ModalSwither.HideSuccessModal();

                                    }}
                                    text={t('SystemMessages', 'ok')}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

const mapStateToProps = (state: GlobalState) => {
    return {
        show: state.ui.successModal,
        message: state.ui.success
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const SuccessModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);