import theme from '../../constants/theme';
export const Style = ({
    title: {
        color: '#fff',
        fontSize: 12
    },
    content:{
        justifyContent:'space-between',
        paddingTop:12
    },
    card:{
        height:150,
        backgroundColor:theme.COLORS.componentBgColor,
        borderWidth:1,
        borderColor:'#d8d8d8',
        borderRadius:12,
        paddingVertical:19,
        paddingHorizontal:12,
        width:'45%',
        alignItems: 'center', 
        justifyContent: 'center' 
    },
    textAreaContainer:{
        justifyContent: 'space-between', 
        alignItems: 'center',
        height:150,
        width:'100%',
        backgroundColor:theme.COLORS.componentBgColor,
        borderRadius:12,
        paddingHorizontal:theme.SPACES.paddingHorizontal,
        marginTop:5,
        zIndex:-1
    },
    closeMic:{
        position: 'absolute', 
        right: 10, 
        top: 10 
    },
    actions:{
        flexDirection: 'row', 
        marginTop:15
    }
});
