import { Modal } from 'react-bootstrap';
import { GlobalState } from "../../../store/state";
import { connect } from 'react-redux';

function Component({ show }: any) {
    return (
        <Modal
            show={show}
            centered
        >
            <div className='text-center' style={{ height: 0, borderWidth: 0 }}>
                <div className="spinner-border text-primary" role="status" />
            </div>
        </Modal>
    );
}

const mapStateToProps = (state: GlobalState) => {
    return {
        show: state.ui.loading,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const Loading = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);