import { Props } from './types';
import { Style } from './style';
import { MomentConverter } from '../../../utils/moment';
import theme from '../../../constants/theme';

export function MicMessageComponent(props: Props) {
  const className = props.alignSelf === 'flex-end' ? 'text-end' : 'text-start';

  return (
    <>
      <div className={className}>
        <div className='row'>
          {
            className === 'text-end' &&
            <div className='col-3' style={{ position: 'relative' }} >
              <p style={{
                position: 'absolute', bottom: 10, right: 0, color: '#9d9d9d', fontSize: 10,
              }}>
                {MomentConverter.getClockTime(props.time)}
              </p>
            </div>
          }
          <div className='col-9 text-end'>
            <div key={props.keu}>
              <audio style={{ width: '100%' }} controls src={props.message} />
            </div>
          </div>
          {
            className !== 'text-end' &&
            <div className='col-3' style={{ position: 'relative' }} >
              <p style={{
                position: 'absolute', bottom: 10, left: 0, color: '#9d9d9d', fontSize: 10,
              }}>
                {MomentConverter.getClockTime(props.time)}
              </p>
            </div>
          }
        </div>
      </div>
    </>
  );
}
