import theme from '../../../../constants/theme';

export const Style = ({
    headerContainer:{
        backgroundColor: theme.COLORS.containerColor, 
        paddingVertical: 10, 
        alignItems: 'center'
    },
    header:{
        color:'#fff',
        fontSize:18
    },
    whichBildir: { 
        color: '#fff', 
        fontSize: 18 ,
        width:'100%'
    },
    textAreaContainer:{
        height:150,
        width:'100%',
        backgroundColor:theme.COLORS.componentBgColor,
        borderRadius:12,
        paddingHorizontal:theme.SPACES.paddingHorizontal,
        zIndex:-1
    },
});
