import { useState, useEffect } from "react";
import { deleteAudio } from "../handlers/recordings-list";
import { Audio } from "../constants/types";
import moment from "moment";

export default function useRecordingsList(audio: string | null, file: Blob ) {
  const [recordings, setRecordings] = useState<Audio[]>([]);

  useEffect(() => {
    if (audio)
      setRecordings((prevState: Audio[]) => {
        return [...prevState, { key: moment(new Date()).format('mm:dd:ss'), audio, file }];
      });
  }, [audio]);

  return {
    recordings,
    deleteAudio: (audioKey: string) => deleteAudio(audioKey, setRecordings),
  };
}