import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../context/Auth/authContext";
import theme from "../../../../constants/theme";
import { Style } from "./style";
import { TranslationService } from "../../../../services/translation.service";
import { useLocation } from "react-router-dom";
import {
  CampaignModel,
  UserCampaignModel,
} from "../../../../api/model/CampaignModel";
import { BackButtonComponent } from "../../../../components/Shared/BackButtonComponent";
import { BlueButtonComponent } from "../../../../components/Shared/BlueButtonComponent";
import { DataFireStoreService } from "../../../../api/services/data-firestore.service";
import { LoadingSwither } from "../../../../utils/loading-switcher";
import { MomentConverter } from "../../../../utils/moment";
import { ModalSwither } from "../../../../utils/modal-switcher";
import { StringFormatter } from "../../../../utils/string";
import { fireStore } from "../../../../services/firebase";
import firebase from "firebase";
export function CampaignDetailScreenComponent() {
  const [joined, setJoined] = useState(false);
  const { user } = useAuth();

  const t = TranslationService.translate;
  const { state } = useLocation();
  //@ts-ignore
  const campaign: CampaignModel = state.campaign;
  //@ts-ignore
  const startDate= new Date(campaign.startDate.seconds * 1000);
  const checkBildir = async (
    amount: number,
    controlDate: boolean
  ): Promise<boolean> => {
    const bildirims = await DataFireStoreService.getUserBildirs(
      user?.uid!,
      campaign.unitId,
      true,
      controlDate ? startDate : undefined
    )
      .then((res) => {
        return res.length;
      })
      .catch((er) => {
        return 0;
      });
    return Promise.resolve(bildirims >= amount);
  };

  const checkComment = async (
    amount: number,
    controlDate: boolean
  ): Promise<boolean> => {
    const comments = await DataFireStoreService.getComment(
      campaign.businessUserId,
      campaign.businessId,
      campaign.unitId,
      user?.uid!,
      true,
      controlDate ? startDate : undefined
    )
      .then((res) => {
        return res.length;
      })
      .catch((er) => {
        return 0;
      });
    return Promise.resolve(comments >= amount);
  };

  const checkSubscribe = async () => {
    const result = await DataFireStoreService.getUnit(
      user?.uid!,
      campaign.businessId,
      campaign.unitId,
      true
    ).then((res) => {
      return res.subscribedUsers.indexOf(user?.uid as never) === -1
        ? false
        : true;
    });
    return Promise.resolve(result);
  };

  const checkAnket = async (amount: number, controlDate: boolean) => {
    const result = await DataFireStoreService.getAnketAnswers(
      campaign.businessUserId,
      campaign.businessId,
      campaign.unitId,
      user?.uid!,
      controlDate ? startDate: undefined
    );
    return Promise.resolve(result >= amount);
  };

  const join = async () => {
    LoadingSwither.ShowLoading();
    const errorList: string[] = [];
    var result = true;
    for (var i = 0; i < campaign.conditions.length; i++) {
      var kosul = campaign.conditions[i];
      const dateControl = kosul.dateControl ?? false;
      if (kosul.id == 1) {
        const resultComment = await checkComment(kosul.amount, dateControl);
        if (!resultComment) {
          const atLeast =
            "en az " + kosul.amount + " adet yorum yapmalısınız.\n";
          const errMessage = dateControl
            ?
              MomentConverter.formatDateDMY(startDate) +
              " tarihinden itibaren " +
              atLeast
            : "Bu işletmeye " + atLeast;
          errorList.push(errMessage);
        }
        result = result ? resultComment : false;
      } else if (kosul.id == 2) {
        const bildirResult = await checkBildir(kosul.amount, dateControl);
        if (!bildirResult) {
          const atLeast =
            "en az " + kosul.amount + " adet bildirim açmış olmalısınız.\n";
          const errMessage = dateControl
            ? 
              MomentConverter.formatDateDMY(startDate) +
              " tarihinden itibaren " +
              atLeast
            : "Bu işletmeye " + atLeast;
          errorList.push(errMessage);
        }
        result = result ? bildirResult : false;
      } else if (kosul.id == 3) {
        const subscribeResult = await checkSubscribe();
        if (!subscribeResult) {
          errorList.push("Bu işletmeye abone olmalısınız.\n");
        }
        result = result ? subscribeResult : false;
      } else if (kosul.id == 4) {
        const anketResult = await checkAnket(kosul.amount, dateControl);
        if (!anketResult) {
          const atLeast =
            "en az " + kosul.amount + " adet anketine katılmış olmalısınız.\n";
          const errMessage = dateControl
            ? 
              MomentConverter.formatDateDMY(startDate) +
              " tarihinden itibaren bu işletmenin " +
              atLeast
            : "Bu işletmenin " + atLeast;
          errorList.push(errMessage);
        }
        result = result ? anketResult : false;
      }
    }
    console.log(startDate)
    //@ts-ignore
    console.log(new Date(startDate.seconds * 1000))
    if (!result) {
      LoadingSwither.HideLoading();
      //@ts-ignore
      ModalSwither.ShowErrorModal(t("SystemMessages", errorList));
      return;
    }

    const model: UserCampaignModel = {
      uid: user?.uid!,
      displayName: user?.displayName!,
      campaignCode: StringFormatter.generateId(8),
      isUsed: false,
      receivedDate: new Date(),
      endDate: campaign.endDate,
      campaignId: campaign.uid,
      businessId: campaign.businessId,
      businessUserId: campaign.businessUserId,
      unitId: campaign.unitId,
      userPhotoURL: user?.photoURL ?? "",
    };
    fireStore
      .collection("Users")
      .doc(campaign.businessUserId)
      .collection("Business")
      .doc(campaign.businessId)
      .collection("Units")
      .doc(campaign.unitId)
      .collection("Campaigns")
      .doc(campaign.uid)
      .update({ users: firebase.firestore.FieldValue.arrayUnion(model) })
      .then(() => {
        fireStore
          .collection("Users")
          .doc(user?.uid)
          .collection("JoinedCampaigns")
          .doc(campaign.uid)
          .set(model)
          .then(() => {
            campaign.users.push(model);
            setJoined(true);
            LoadingSwither.HideLoading();
            ModalSwither.ShowSuccessModal(t("SystemMessages", "success7"));
            return;
          })
          .catch(() => {
            LoadingSwither.HideLoading();
            ModalSwither.ShowErrorModal(t("SystemMessages", "error14"));
            return;
          });
      })
      .catch(() => {
        LoadingSwither.HideLoading();
        ModalSwither.ShowErrorModal(t("SystemMessages", "error14"));
        return;
      });
  };

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + campaign.imageURL + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: 150,
          width: "100%",
        }}
      >
        <div
          className="d-flex"
          style={{
            backgroundColor: theme.COLORS.containerColor,
            opacity: 0.8,
            height: 40,
            alignItems: "center",
          }}
        >
          <BackButtonComponent />
          <p
            className="fontMediumItalic text-center me-5"
            style={{
              color: "#fff",
              fontSize: 18,
              flex: 1,
            }}
          >
            {campaign.title}
          </p>
        </div>
      </div>
      <div className="px-3 mt-4">
        <p
          className="fontMediumItalic"
          style={{
            color: "#fff",
            fontSize: 18,
            flex: 1,
          }}
        >
          {campaign.title}
        </p>
        <p
          className="fontRegular mt-2"
          style={{
            color: "#fff",
            fontSize: 14,
          }}
        >
          {campaign.description}
        </p>
        <div dangerouslySetInnerHTML={{ __html: campaign.conditionDetails }} />
        {!joined &&
        user &&
        user.uid &&
        campaign.users.filter((item) => item.uid === user?.uid!).length < 1 ? (
          <div className="mt-2">
            <BlueButtonComponent
              onClick={join}
              text={t("SearchScreen", "join")}
            />
          </div>
        ) : (
          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            <p style={Style.katildiniz}>
              {t("SharedComponents", "joined") + " : "}
            </p>
            <p style={Style.katildiniz}>
              {" " +
                campaign.users.filter((item) => item.uid === user?.uid!)[0]
                  .campaignCode}
            </p>
          </div>
        )}
      </div>
    </>
  );
}
