import theme from '../../../constants/theme';
export const Style = ({
  header: {
    backgroundColor: theme.COLORS.componentBgColor,
    paddingBlock: 10,
    flexDirection: 'row',
    paddingInline: 20,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userDetailName: {
    color: '#fff',
    fontSize: 21,
    marginLeft: 10,
  },
  container: {
    backgroundColor: theme.COLORS.componentBgColor,
    borderRadius: 12,
    marginTop: 10,
    height: 80
  },
  typeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    flex: 1,
    paddingInline: 10,
    paddingBlock: 40,
  },
  typeText: {
    color: '#fff',
    fontSize: 10,
    textAlign: 'center',
    'transform': 'rotate(270deg)',
    justifySelf: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 25,
    margin: 'auto'
  },
  bussinesContainer: {
    marginTop: 7,
    justifyContent: 'space-between',
  },
  imgContainer: {
    height: 48,
    width: 48,
    borderRadius: 12,
  },
  businessTitle: {
    color: '#fff',
    fontSize: 12,
    marginTop: 4,
  },
  openState: {
    color: theme.COLORS.green,
    fontSize: 15,
  },
  closedState: {
    color: theme.COLORS.red,
    fontSize: 15,
  },
  date: {
    color: theme.COLORS.containerTextColor,
    fontSize: 12,
    marginTop: 20,
  },
  messageTextContainer: {
    maxWidth: 260,
    padding: 7,
    borderRadius: 5,
    marginTop: 11,
  },
  messageMicContainer: {
    maxWidth: 260,
    padding: 7,
    borderRadius: 5,
    marginTop: 11,
  },
  messageImageContainer: {
    maxWidth: 260,
    padding: 2,
    borderRadius: 12,
    marginTop: 11,
  },
  messageImage: {
    width: 250,
    height: 178,
    borderRadius: 12,
  },
  messageTime: {
    alignSelf: 'flex-end',
    marginRight: 8,
    color: '#9d9d9d',
  },
  chatContainer: {
    backgroundColor: '#fff',
    paddingBlock: 5,
    paddingInline: 10,
    alignItems: 'center',
    bottom: 0,
    width: '100%',
  },
  verticalLine: {
    height: '100%',
    borderWidth: 0.5,
    borderColor: '#adadad',
    marginRight: 10,
  },
});
