import theme from '../../../../constants/theme';
export const Style = ({
    line:{
        borderBottomWidth:1,
        borderColor:theme.COLORS.containerLabelColor,
        width:'30%',
        height:10
    },
    haveAnAccount:{
        color:theme.COLORS.containerTextColor,
        fontSize:14,
    }
});
