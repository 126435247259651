import React, { useState } from "react";
import theme from "../../../constants/theme";
import { Style } from "./style";
import { Props } from "./types";
import { TranslationService } from "../../../services/translation.service";
//import {FilterHalfModalComponent} from '../FilterHalfModalComponent';
export function SearchComponent(props: Props) {
  const t = TranslationService.translate;
  const [showSearchMessage, setShowSearchMessage] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  function callSearch() {
    props.callSearch();
    setShowSearchMessage(true);
  }

  function clearSearch() {
    props.clearSearch();
    setShowSearchMessage(false);
  }

  return (
    <>
      <div className="d-flex" style={Style.container}>
        <button onClick={callSearch} style={Style.icon}>
          <img
            src={theme.ICONS.ic_search}
            style={{ height: 17, width: 17, marginRight: 5 }}
          />
        </button>
        <input
          className="fontMediumItalic"
          style={Style.inputcontainer}
          onChange={(value) => {
            props.listener(value.target.value);
          }}
          placeholder={t("SharedComponents", "search")}
          value={props.value}
        />
        <button onClick={clearSearch} style={Style.icon}>
          <img src={theme.ICONS.ic_delete} style={{ height: 24, width: 24 }} />
        </button>
        {props.hasFilter ? (
          <button
            onClick={() => {
              setShowFilter(true);
            }}
            style={Style.icon}
          >
            <img
              src={theme.ICONS.ic_filter}
              style={{ height: 20, width: 20 }}
            />
          </button>
        ) : (
          <></>
        )}
      </div>
      {showSearchMessage ? (
        <p className="fontMedium" style={theme.STYLE.searchMessage}>
          {t("SearchScreen", "searchMessage")}
        </p>
      ) : (
        <></>
      )}
      {/* {showFilter ? (
        <FilterHalfModalComponent
          setShow={setShowFilter}
          stateListListener={props.stateListListener as any}
          typeListListener={props.typeListListener as any}
          applyFilter={props.applyFilter as any}
          stateList={props.stateList}
          typeList={props.typeList}
        />
      ) : (
        <></>
      )} */}
    </>
  );
}
