import { ErrorModal } from "./Shared/ErrorModal"
import { SuccessModal } from "./Shared/SuccessModal"
import { LoginStackModal } from "./LoginStackModal"
import { Loading } from "./Shared/LoadingComponent"
export function ModalStack() {
    return (
        <>
            <Loading/>
            <ErrorModal />
            <SuccessModal />
            <LoginStackModal/>
        </>
    )
}