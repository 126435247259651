import { Style } from './style';
import { Props } from './types';
export function BlueButtonComponent(props: Props) {

    return (
        <button
            style={Style.container}
            className='fontMedium'
            onClick={props.onClick}>
            {props.text}
        </button>
    );
}
