import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginScreen } from '../../screens/Initial/LoginScreen';
import { RegisterScreen } from '../../screens/Initial/RegisterScreen';
import { ForgotPasswordScreen } from "../../screens/Initial/ForgotPasswordScreen";

export function InitialRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginScreen />} />
                <Route path='/register' element={<RegisterScreen/>}/>
                <Route path='/forgotPassword' element={<ForgotPasswordScreen/>}/>
            </Routes>
        </BrowserRouter>
    )
}