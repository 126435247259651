import { TranslationService } from "../../services/translation.service";
import { Style } from "./style";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth/authContext";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import { ModalSwither } from "../../utils/modal-switcher";
export function BottomTabComponent() {
    const t = TranslationService.translate;
    const navigation = useNavigate()
    const { user } = useAuth();
    const img = require('../../assets/images/bottombar/Footer.png')
    const location = useLocation();
    const [show, setShow] = useState(true)
    useEffect(() => {
        if (location.pathname === '/Home' || location.pathname === '/' || location.pathname === '/Search' ||
            location.pathname === '/Notifications' || location.pathname === '/Profile' || location.pathname === '/Business' ||
            location.pathname === '/QRCode' || location.pathname === '/ProfileInformation') {
            !show && setShow(true)
        } else {
            show && setShow(false)
        }
    }, [location])

    const _render = (img: string, name: string) => {

        return (
            <div className="text-center" onClick={() => {
                if(name === 'Notifications' && !user){
                    ModalSwither.ShowLoginStackModal();
                    return;
                } 
                navigation('/' + name)
            }}>
                <img src={img} style={{ width: 24, height: 24 }} alt='bildir' />
                <p className="fontRegular" style={Style.title}>
                    {t('BottomBar', name)}
                </p>
            </div>
        )
    }
    return (
        <>
            {
                show &&
                <div style={{ position: 'absolute', bottom: 0 }}>
                    <img src={img} className='img-fluid' style={{ position: 'relative' }} />
                    <div
                        onClick={() => navigation('/QRCode')}
                        style={{ position: 'absolute', top: '15%', left: '45%' }}>
                        <img
                            src={require('../../assets/images/bottombar/active/qr@3x.png')}
                            style={{ width: 36, height: 36 }}
                            alt='bildir' />
                    </div>
                    <div style={{ paddingInline: 20, width: '100%', position: 'absolute', top: '50%' }}>
                        <div className="d-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                            {_render(require('../../assets/images/bottombar/active/home@3x.png'), 'Home')}
                            {_render(require('../../assets/images/bottombar/active/search@3x.png'), 'Search')}
                            <div style={{ width: 24 }}></div>
                            {_render(require('../../assets/images/bottombar/active/notification@3x.png'), 'Notifications')}
                            {
                                user?.group?.length as any > 1 ?
                                    _render(require('../../assets/images/bottombar/active/business@3x.png'), 'Business')
                                    :
                                    _render(require('../../assets/images/bottombar/active/profile@3x.png'), 'Profile')
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}