import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyB9m4__ONUo4n5bNIX1gjxn8Pwg2w_Ho4U",
  authDomain: "studied-anchor-403110.firebaseapp.com",
  projectId: "studied-anchor-403110",
  storageBucket: "studied-anchor-403110.appspot.com",
  messagingSenderId: "546975233552",
  appId: "1:546975233552:web:486d2229e18ac7704bfc95",
  measurementId: "G-KGVGTMY3W7"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const fireStore = firebaseApp.firestore()
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage()