import theme from '../../../constants/theme';
export const Style = ({
  container: {
    backgroundColor: theme.COLORS.componentBgColor,
    borderRadius: 12,
    paddingHorizontal: theme.SPACES.paddingHorizontal,
    marginTop: 5,
    zIndex: -1,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  icon: {
    justifyContent: 'center',
    backgroundColor: theme.COLORS.componentBgColor,
    border: 0
  },
  inputcontainer: {
    backgroundColor: theme.COLORS.componentBgColor,
    color: '#fff',
    width: '80%',
    border:0
  }
});
