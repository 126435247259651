import React, { createContext, useState, useEffect } from "react";
import { AuthContextData } from "./types";
import { StorageService } from "../../services/storage.service";
import { ModalSwither } from "../../utils/modal-switcher";
import { LoadingSwither } from "../../utils/loading-switcher";
import { TranslationService } from "../../services/translation.service";
import { defaultLang, langs } from "../../constants/langs";
import { auth, fireStore } from "../../services/firebase";
import firebase from "firebase/app";
import { UserModel } from "../../api/model/UserModel";

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(undefined);
  const [publicUser, setPublicUserInfo] = useState<
    { phone: string; uid: string; displayName: string } | undefined
  >(undefined);

  const [initializing, setInitializing] = useState(true);
  const [isTutorial, setIsTutorial] = useState(false);
  const t = TranslationService.translate;

  const onAuthStateChanged = async (user: any) => {
    const lang = await StorageService.getLang();
    if (lang) {
      TranslationService.changeLang(lang);
    } else {
      const phoneLang = TranslationService.getPhoneLang();
      phoneLang === "tr_TR"
        ? TranslationService.changeLang(langs[0])
        : phoneLang === "en_US"
        ? TranslationService.changeLang(langs[1])
        : phoneLang === "de_DE"
        ? TranslationService.changeLang(langs[2])
        : phoneLang === "ru_RU"
        ? TranslationService.changeLang(langs[3])
        : phoneLang === "fr_FR"
        ? TranslationService.changeLang(langs[4])
        : phoneLang === "gr_GR"
        ? TranslationService.changeLang(langs[5])
        : phoneLang === "ar_EG"
        ? TranslationService.changeLang(langs[6])
        : TranslationService.changeLang(defaultLang);
    }
    console.log("AUTH USER AUTH", user);
    LoadingSwither.HideLoading();
    const db = fireStore;
    if (user) {
      db.collection("Users")
        .doc(user.uid)
        .get()
        .then(async (documentSnapshot) => {
          console.log(
            "AUTH USER documentSnapshot exists",
            documentSnapshot.exists
          );
          console.log("AUTH USER documentSnapshot", documentSnapshot.data());
          if (documentSnapshot.exists) {
            var userForSet = documentSnapshot.data() as any;
            var displayName = "";
            // @ts-ignore
            if (!userForSet.displayName) {
              displayName = user.email.substr(0, user.email.indexOf("@"));
              displayName = displayName.replace(".", " ");
              displayName = displayName.replace("-", " ");
              displayName = displayName.replace("_", " ");
            } else {
              displayName = userForSet.displayName;
            }
            userForSet.displayName = displayName;

            // Get the device token and Register every login
            // await messaging
            //   .getToken()
            //   .then(async (token) => {
            //     if (!userForSet.fcmTokens.includes(token)) {
            //       userForSet.fcmTokens = [...userForSet.fcmTokens, token];
            //       await db.collection('Users').doc(user.uid).update({ fcmTokens: userForSet.fcmTokens });
            //     }
            //   })
            //   .catch((err) => console.log('USERTOKEN ERROR: ', err));

            userForSet.provider = user.providerData
              ? user.providerData[0].providerId
              : null;

            userForSet.metadata = {
              creationTime: new Date(
                user.metadata.creationTime
              ).toLocaleTimeString("tr", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
              lastSignInTime: new Date(
                user.metadata.lastSignInTime
              ).toLocaleTimeString("tr", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
            };
            console.log("AUTH USER user for set", userForSet);
            setUser(userForSet);
            if (initializing) setInitializing(false);
          } else {
            //Register User From Social Auth
            console.log("AUTH USER REGISTER", user);
            displayName = "";
            if (user.displayName) {
              displayName = user.displayName;
            } else {
              displayName = user.email.substr(0, user.email.indexOf("@"));
              displayName = displayName.replace(".", " ");
              displayName = displayName.replace("-", " ");
              displayName = displayName.replace("_", " ");
            }
            // await messaging
            //   .getToken()
            //   .then((token) => {
            //     user.fcmTokens = [token];
            //   })
            user.fcmTokens = [];
            const userCreated = {
              displayName,
              email: user.email,
              group: [5],
              fcmTokens: user.fcmTokens,
              uid: user.uid,
              photoURL: "",
              provider: user.providerData
                ? user.providerData[0].providerId
                : null,
              metadata: {
                creationTime: new Date(
                  firebase.firestore.Timestamp.now().seconds * 1000
                ).toLocaleTimeString("tr", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }),
                lastSignInTime: new Date(
                  firebase.firestore.Timestamp.now().seconds * 1000
                ).toLocaleTimeString("tr", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }),
              },
            };

            console.log("AUTH USER  CREATED", userCreated);

            db.collection("Users")
              .doc(user.uid)
              .set(userCreated)
              .then(() => {
                setUser(userCreated as any);
                if (initializing) setInitializing(false);
              });
          }
        });
    } else {
      const isTutorial = await StorageService.getSawTutorial();
      if (!isTutorial) setIsTutorial(true);
      if (initializing) setInitializing(false);
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);

  const signIn = async (email: string, password: string) => {
    LoadingSwither.ShowLoading();
    return auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        //@ts-ignore
        setUser(res.user);
        return Promise.resolve(true);
      })
      .catch((error) => {
        ModalSwither.ShowErrorModal(t("SystemMessages", "error1"));
        LoadingSwither.HideLoading();
        return Promise.resolve(false);
      });
  };

  const signOut = () => {
    auth.signOut().then(() => {
      StorageService.removeUserModel();
      setUser(undefined as any);
    });
  };

  const signUp = async (email: string, password: string) => {
    LoadingSwither.ShowLoading();
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        ModalSwither.ShowSuccessModal(t("SystemMessages", "success1"));
        return Promise.resolve(true);
      })
      .catch((error) => {
        LoadingSwither.HideLoading();
        if (error.code === "auth/email-already-in-use") {
          ModalSwither.ShowErrorModal(t("SystemMessages", "error2"));
        } else if (error.code === "auth/invalid-email") {
          ModalSwither.ShowErrorModal(t("SystemMessages", "error3"));
        } else {
          ModalSwither.ShowErrorModal(t("SystemMessages", "error4"));
        }
        return Promise.resolve(false);
      });
  };

  const sawTutorial = () => {
    StorageService.setSawTutorial();
    setIsTutorial(false);
  };

  const forgotPassword = async (email: string) => {
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        ModalSwither.ShowSuccessModal(t("SystemMessages", "success5"));
      })
      .catch((err) => {
        ModalSwither.ShowErrorModal(t("SystemMessages", "error3"));
      });
  };

  const setPublicUser = (phone: string) => {
    setPublicUserInfo({
      phone,
      uid: "Y6Y4LfkHw4buOcfqgNvB",
      displayName: "Anononim Kullanıcı",
    });
  };
  return (
    <AuthContext.Provider
      value={{
        initializing,
        isTutorial,
        user,
        signOut,
        signIn,
        signUp,
        sawTutorial,
        forgotPassword,
        setPublicUser,
        publicUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
