import {connect} from 'react-redux';
import {BildirsByBusinessScreenComponent} from './component';
import { GlobalState } from '../../../../store/state';

const mapStateToProps = (state: GlobalState) => {
  return {
    lang : state.ui.lang
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
  
  };
};

export const BildirsByBusinessScreen = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BildirsByBusinessScreenComponent);
