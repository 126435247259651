import React, { useEffect, useState } from 'react';
import theme from '../../constants/theme';
import { Style } from './style';
import { Props } from './types';
import { TranslationService } from '../../services/translation.service';
import { bildirTypeList } from '../../constants/bildirimTypes';

export function BildirTypeComponent(props: Props) {
    const t = TranslationService.translate;
    const [selected, setSelected] = useState(0);

    const typeListener = (index: number) => {
        setSelected(index)
        props.listener(t('SearchScreen', bildirTypeList[index].text))
    }

    return (
        <>
            <p className='fontMediumItalic' style={Style.title}>{props.title}</p>
            <div style={theme.STYLE.lineStyle}>
                <div className='d-flex justify-content-between'>
                    {
                        bildirTypeList.map(function (data, index) {
                            const styling = {
                                paddingVertical: 4,
                                paddingHorizontal: 15,
                                borderRadius: 8,
                                border:0
                            }
                            const backgroundColor = index === selected ? data.color : data.color + '48';
                            // @ts-ignore
                            styling.backgroundColor = backgroundColor
                            return (
                                <button key={index} style={styling} onClick={() => {
                                    typeListener(index)
                                }}>
                                    <p className='fontMediumItalic' style={Style.cardText}>
                                        {t('SearchScreen', data.text)}
                                    </p>
                                </button>
                            )
                        })
                    }
                </div>

            </div>
        </>

    )
}