export function shouldLoadMoretoDown(height: number, yValue: number, contentSize: number) {
    return height + yValue >= contentSize - 20
}

export function shouldLoadMoreToUp(height: number, yValue: number, contentSize: number) {
    return height + yValue <= 1000
}

export function calculatePercent(total: number, has: number) {
    if(total == 0 || has == 0){
        return 0;
    }
    const divide = 100 / total;
    return divide * has;
}