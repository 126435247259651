import { useState } from "react";
import { Style } from "./style";
import { Props } from "./types";
import { TranslationService } from "../../services/translation.service";
import { MomentConverter } from "../../utils/moment";
import { useAuth } from "../../context/Auth/authContext";
import { DataFireStoreService } from "../../api/services/data-firestore.service";
import { fireStore } from "../../services/firebase";
import { CampaignModel } from "../../api/model/CampaignModel";
import { useNavigate } from "react-router-dom";

export function NotificationCardComponent({ notification }: Props) {
  const t = TranslationService.translate;
  const [isRead, setIsRead] = useState(true);
  const { user } = useAuth();
  const navigation = useNavigate();

  const onPress = () => {
    if (!isRead) {
      setIsRead(true);
      DataFireStoreService.markAsReadNotification(user?.uid!, notification.uid);
    }
    if (notification.type === "webview") {
      window.open(notification.webViewUrl, "_blank", "noreferrer");
    } else if (notification.type === "campaign" && notification.campaignId) {
      fireStore
        .collectionGroup("Campaigns")
        .where("uid", "==", notification.campaignId)
        .get()
        .then((res) => {
          console.log(notification.campaignId)
          const list: CampaignModel[] = [];
          res.forEach((element) => {
            //@ts-ignore
            list.push(element.data());
          });
          navigation("/CampaignDetail", { state: { campaign: list[0] } })
        });
    }
  };

  return (
    <button
      className="transparentButton d-flex p-3 line"
      style={{ position: "relative" }}
      onClick={onPress}
    >
      {notification.imageURL && (
        <img src={notification.imageURL} style={Style.image} />
      )}
      <div className="ms-2">
        <p
          className="fontMediumItalic text-start"
          style={isRead ? { ...Style.readTitle } : { ...Style.unReadTitle }}
        >
          {notification.title}
        </p>
        <p
          className="fontMediumItalic text-start"
          style={isRead ? { ...Style.readDesc } : { ...Style.unReadDesc }}
        >
          {notification.description}
        </p>
      </div>
      {!isRead ? (
        <div style={{ ...Style.dot, position: "absolute" }} />
      ) : (
        <p style={{ ...Style.date, position: "absolute" }}>
          {
            //@ts-ignore
            MomentConverter.formatDateDMY(notification.date.toDate())
          }
        </p>
      )}
    </button>
  );
}
