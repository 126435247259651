import { Style } from './style';
import { TranslationService } from '../../../../services/translation.service';
import theme from '../../../../constants/theme';
import { BusinessModel } from '../../../../api/model/BusinessModel';
import { useLocation } from 'react-router-dom';
import { UnitsModel } from '../../../../api/model/UnitModel';
import { BackButtonComponent } from '../../../../components/Shared/BackButtonComponent';
import { useNavigate } from 'react-router-dom';
import { DataFireStoreService } from '../../../../api/services/data-firestore.service';
import { useAuth } from '../../../../context/Auth/authContext';
import { ModalSwither } from '../../../../utils/modal-switcher';
import { InputComponent } from '../../../../components/Shared/InputComponent';
import { useState } from 'react';
import { BlueButtonComponent } from '../../../../components/Shared/BlueButtonComponent';
import { WaiterRequestModel } from '../../../../api/model/WaiterRequestModel';
export function CallWaiterRequestScreenComponent() {
    const { user } = useAuth()
    const { state } = useLocation();
    const [info, setInfo] = useState('')
    const navigation = useNavigate()
    const t = TranslationService.translate;
    // @ts-ignore
    const business = state.Business as BusinessModel;
    // @ts-ignore
    const unit = state.Units as UnitsModel;

    const callWaiterRequest = () => {
        if(info === ''){
            ModalSwither.ShowErrorModal((t('SearchScreen','fillTableInfo')));
            return;
        }
        if (!user) {
            ModalSwither.ShowLoginStackModal()
            return;
        }
        const model: WaiterRequestModel = {
            requestDate: new Date(),
            unitID: unit.id,
            unitInfo: info,
            // @ts-ignore
            userID: user?.uid,
            // @ts-ignore
            userName: user?.displayName,
            // @ts-ignore
            userPhotoURL: user?.photoURL
        }
        DataFireStoreService.callWaiterRequest(model)
            .then(res=>{
                res && ModalSwither.ShowSuccessModal(t('SearchScreen','success'))
                navigation('/Home');
            })
    }

    return (
        <>

            <div style={{
                backgroundImage: "url(" + business.imageURL + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: 150,
                width: '100%'
            }}>
                <div className='d-flex' style={{
                    backgroundColor: theme.COLORS.containerColor,
                    opacity: 0.8,
                    height: 40,
                    alignItems:'center'
                }}>
                    <BackButtonComponent />
                    <p className='fontMediumItalic text-center' style={{
                        color:'#fff',
                        fontSize:18,
                        flex:1,
                    }}>
                        {business.title + ' ' + unit.title}
                    </p>
                </div>
            </div>

            <div className='px-3 mt-4'>
                <p className='fontMediumItalic text-center' style={{
                    color:'#fff',
                    fontSize:18,
                    flex:1,
                }}>
                    {t('SearchScreen', 'infoTable')}
                </p>
                <InputComponent
                    listener={setInfo}
                    type='text'
                    placeHolder=''
                />
                <div className='mt-3'>
                    <BlueButtonComponent
                        onClick={callWaiterRequest}
                        text={t('SearchScreen', 'send')}
                    />
                </div>
            </div>
        </>
    )
}
