export const Style = ({

    messageImageContainer: {
        maxWidth: 260,
        padding: 2,
        borderRadius: 12,
        marginTop: 11
    },
    messageImage: {
        width: 250,
        height: 178,
        borderRadius: 12
    },
    messageTime: {
        alignSelf: 'flex-end',
        marginRight: 8,
        color: '#9d9d9d',
        fontSize: 10,
    }
});
