import theme from "../../constants/theme";
export const Style = {
  image: {
    height: 72,
    width: 72,
    borderRadius: 36,
    resizeMode: "contain",
  },
  unReadDesc: {
    color: "white",
    fontSize: 12,
  },
  readDesc: {
    color: theme.COLORS.containerTextColor,
    fontSize: 12,
  },
  unReadTitle: {
    color: "white",
    fontSize: 14,
  },
  readTitle: {
    color: theme.COLORS.containerTextColor,
    fontSize: 14,
  },
  dot: {
    height: 16,
    width: 16,
    backgroundColor: theme.COLORS.blue,
    borderRadius: 8,
    bottom: 8,
    right: 8,
  },
  date: {
    bottom: 8,
    right: 8,
    fontSize: 10,
    color: theme.COLORS.containerTextColor,
  },
};
