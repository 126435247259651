import React, { useState } from 'react';
import { Props } from './types';
import { Style } from './style';
import { MomentConverter } from '../../../utils/moment';
import { ImageViewerModalComponent } from '../../ImageViewerModalComponent';

export function ImageMessageComponent(props: Props) {
    const [showImage, setShowImage] = useState(false)
    const [imageUri, setImageUri] = useState([])
    const className = props.alignSelf === 'flex-end' ? 'text-end' : 'text-start';

    return (
        <>
            <div className={className}>
                <div className='row'>
                    {
                        className === 'text-end' &&
                        <div className='col-3' style={{ position: 'relative' }} >
                            <p style={{
                                position: 'absolute', bottom: 10, right: 0, color: '#9d9d9d', fontSize: 10,
                            }}>
                                {MomentConverter.getClockTime(props.time)}
                            </p>
                        </div>
                    }
                    <div className='col-9'>
                        <button
                            className='transparentButton'
                            style={{
                                backgroundColor: props.backgroundColor,
                                padding: 2,
                                borderRadius: 12,
                                marginTop: 11
                            }}
                            onClick={() => {
                                setImageUri([{ uri: props.message }] as any)
                                setShowImage(true)
                            }}>
                            <img src={props.message} style={Style.messageImage} />
                        </button>
                    </div>
                    {
                        className !== 'text-end' &&
                        <div className='col-3' style={{ position: 'relative' }} >
                            <p style={{
                                position: 'absolute', bottom: 10, left: 0, color: '#9d9d9d', fontSize: 10,
                            }}>
                                {MomentConverter.getClockTime(props.time)}
                            </p>
                        </div>
                    }
                </div>
            </div>
            {
                showImage ?
                    <ImageViewerModalComponent
                        close={() => {
                            setShowImage(false)
                        }}
                        imageUri={imageUri}
                    /> : <></>
            }
        </>

    );
}
