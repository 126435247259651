import { Lang } from "../../constants/langs";

export enum ActionType {
    LOADING = 'LOADING',
    ERROR_MODAL = 'ERROR_MODAL',
    ERROR_MODAL_DATA = 'ERROR_MODAL_DATA',
    SUCCESS_MODAL = 'SUCCESS_MODAL',
    SUCCESS_MODAL_DATA = 'SUCCESS_MODAL_DATA ',
    LANG = 'LANG',
    LOGIN_STACK_MODAL = 'LOGIN_STACK_MODAL',
    LOGIN_STACK_MODAL_ON_CLICK = 'LOGIN_STACK_MODAL_ON_CLICK'
}

interface loadingAction {
    type: ActionType.LOADING,
    payload: boolean,
}

interface errorModalAction {
    type: ActionType.ERROR_MODAL,
    payload: boolean,
}

interface errorModalDataAction {
    type: ActionType.ERROR_MODAL_DATA,
    payload: string,
}

interface successModalAction {
    type: ActionType.SUCCESS_MODAL,
    payload: boolean,
}

interface successModalDataAction {
    type: ActionType.SUCCESS_MODAL_DATA,
    payload: string,
}

interface langAction {
    type: ActionType.LANG,
    payload: Lang,
}

interface LoginStackModal {
    type: ActionType.LOGIN_STACK_MODAL,
    payload: boolean,
}

interface LoginStackModalOnClick {
    type: ActionType.LOGIN_STACK_MODAL_ON_CLICK,
    payload: Function,
}

interface test {
    type: ActionType.LOADING,
    payload: boolean,
}

export type Action = LoginStackModal | LoginStackModalOnClick | loadingAction | test | errorModalAction | errorModalDataAction | successModalAction | successModalDataAction | langAction;
