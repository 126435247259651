import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../context/Auth/authContext";
import theme from "../../../../constants/theme";
import { Style } from "./style";
import { TranslationService } from "../../../../services/translation.service";
import { BackButtonComponent } from "../../../../components/Shared/BackButtonComponent";
import { DataFireStoreService } from "../../../../api/services/data-firestore.service";
import { NotificationModel } from "../../../../api/model/NotificationModel";
import { NotificationCardComponent } from "../../../../components/NotificationCardComponent";
import { fireStore } from "../../../../services/firebase";
import { CampaignModel, CampaignModelList } from "../../../../api/model/CampaignModel";
import { CampaignCardComponent } from "../../../../components/CampaignCardComponent";

export function NotificationAndCampaignsScreenComponent() {
  const { user } = useAuth();
  const [tabState, setTabState] = useState<number>(0);
  const [notifications, setNotifications] = useState(
    [] as NotificationModel[],
  );
  const [campaigns, setCampaigns] = useState([] as CampaignModel[]);

  const t = TranslationService.translate;

  useEffect(()=>{
    if(tabState === 0){
      user &&
      DataFireStoreService.getNotifications(user.uid!)
        .then(res=>{
          setNotifications(res)
        })
    } else {
      fireStore
      .collectionGroup('Campaigns')
      .get()
      .then(res => {
        const list : CampaignModel[] = []
        res.forEach((element) => {
          //@ts-ignore
          list.push(element.data());
        });
        setCampaigns(list);
      });
    }
  },[tabState])
  return (
    <div style={{ ...theme.STYLE.container }}>
      <div className="d-flex p-3">
        <BackButtonComponent />
        <p className="ms-4 mt-1 fontMedium" style={Style.title}>
          {t("MainScreen", "notificationAndCampaings")}
        </p>
      </div>
      <div className="d-flex justify-content-between mt-4 text-center">
        <div
          className={tabState === 0 ? "line w-50" : "w-50"}
          onClick={() => setTabState(0)}
        >
          <button className="transparentButton" style={{ ...Style.tabText }}>
            {t("MainScreen", "Notifications")}
          </button>
        </div>
        <div
          className={tabState === 1 ? "line w-50" : "w-50"}
          onClick={() => setTabState(1)}
        >
          <button className="transparentButton" style={{ ...Style.tabText }}>
            {t("MainScreen", "Campaigns")}
          </button>
        </div>
      </div>
      <div className="mt-2">
        {
          tabState === 0 ?
          notifications.map((not, index) => {
            return <NotificationCardComponent notification={not} key={index}/>
          })
          :
          campaigns.map((not, index) => {
            return <CampaignCardComponent campaign={not} key={index}/>
          })
        }
      </div>
    </div>
  );
}
