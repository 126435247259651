import theme from '../../../constants/theme';
export const Style = ({
    container: {
       backgroundColor:theme.COLORS.blue,
       width:'100%',
       padding:13,
       borderColor:'transparent',
       borderRadius:12,
       color:'white',
       fontSize:20
    }
});
