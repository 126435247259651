import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../context/Auth/authContext';
import theme from '../../../../constants/theme';
import { Style } from './style';
import { InputComponent } from '../../../../components/Shared/InputComponent';
import { MomentConverter } from '../../../../utils/moment';
import { GENDERs } from '../../../../constants/bottomTabItems';
import { ProfileFireStoreService } from '../../../../api/services/profile-firestore.service';
import { UserModel } from '../../../../api/model/UserModel';
import { BlueButtonComponent } from '../../../../components/Shared/BlueButtonComponent';
import { TranslationService } from '../../../../services/translation.service';
import { DropdownComponent } from '../../../../components/Shared/DropdownComponent';
import { ModalSwither } from '../../../../utils/modal-switcher';
import { auth } from '../../../../services/firebase';
import firebase from "firebase/app";
export function ProfileInformationScreenComponent() {
  const { user } = useAuth();
  const t = TranslationService.translate;

  const [name, setName] = useState(user?.displayName as string);
  const [date, setDate] = useState(new Date(2000, 1, 1));
  const [value, setValue] = useState(GENDERs[0]);
  const [city, setCity] = useState(user?.city);
  const [district, setDistrict] = useState(user?.district);
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [enableChangePassword, setEnableChangePassword] = useState(false)

  useEffect(() => {

    if (user?.birthdate)
      //@ts-ignore
      setDate((new Date(user?.birthdate.toDate())));
    user?.gender ? setValue(GENDERs[0]) : setValue(GENDERs[1]);
    if (user?.provider === 'password') {
      setEnableChangePassword(true)
    }
  }, []);

  async function save() {
    if ((password !== '' && password !== undefined) ||
      (rePassword !== '' && rePassword !== undefined)) {
      if (password === rePassword) {
        const currentUser = auth.currentUser;
        await currentUser?.updatePassword(password)
      } else {
        ModalSwither.ShowErrorModal(t('SystemMessages', 'error15'))
      }
    }
    const userModel = {
      uid: user?.uid as any,
      birthdate: firebase.firestore.Timestamp.fromDate(date),
      city: city ? city : '',
      displayName: name,
      gender: value.value,
      district: district ? district : '',
    };
    //@ts-ignore
    ProfileFireStoreService.updateProfile(userModel).then(res => {
      if (res) {
        window.location.reload();
      }
    });
  }

  function listenGender(data: any) {
    setValue(data)
  }

  return (
    <div style={theme.STYLE.container}>
      <div style={theme.STYLE.content}>
        <p className='fontItalic' style={theme.STYLE.inputLabel}>
          {t('MainScreen', 'nameSurname')}
        </p>
        <InputComponent
          listener={setName}
          value={name}
          type='text'
        />
        <p className='fontItalic mt-3' style={theme.STYLE.inputLabel}>
          {t('MainScreen', 'birthdate')}
        </p>
        <InputComponent
          listener={setDate}
          value={MomentConverter.formatDateYMD(date)}
          type='date'
        />
        <p className='fontItalic mt-3' style={theme.STYLE.inputLabel}>
          {t('MainScreen', 'sex')}
        </p>
        <DropdownComponent
          values={GENDERs}
          value={value}
          setValue={listenGender}
        />
        {/*dropdown*/}
        <p className='fontItalic mt-3' style={theme.STYLE.inputLabel}>
          {t('MainScreen', 'city')}
        </p>
        <InputComponent
          type='text'
          listener={setCity}
          value={city}
        />
        <p className='fontItalic mt-3' style={theme.STYLE.inputLabel}>
          {t('MainScreen', 'district')}
        </p>
        <InputComponent
          type='text'
          listener={setDistrict}
          value={district}
        />
        <div className='d-flex justify-content-between'>
          <div className='line30 mt-3' style={{ alignSelf: 'center' }} />
          <p className='fontItalic mt-3' style={Style.haveAnAccount}>
            {t('MainScreen', 'changePassword')}
          </p>
          <div className='line30 mt-3' style={{ alignSelf: 'center' }} />
        </div>

        {
          enableChangePassword &&
          <>
            <p className='fontItalic mt-3' style={theme.STYLE.inputLabel}>
              {t('MainScreen', 'password')}
            </p>
            <InputComponent
              type='password'
              listener={setPassword}
              value={password}
            />
            <p className='fontItalic mt-3' style={theme.STYLE.inputLabel}>
              {t('MainScreen', 'rePassword')}
            </p>
            <InputComponent
              type='password'
              listener={setRePassword}
              value={rePassword}
            />
          </>
        }

        <div className='mt-2'>
          <BlueButtonComponent
            onClick={save}
            text={t('MainScreen', 'save')}
          />
        </div>
      </div>
    </div>

  );
}
