import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BusinessModel } from "../../../api/model/BusinessModel";
import { UnitsModel } from "../../../api/model/UnitModel";
import { DataFireStoreService } from "../../../api/services/data-firestore.service";
import { TranslationService } from "../../../services/translation.service";
import { ModalSwither } from "../../../utils/modal-switcher";

export function QRScreen() {
    const navigation = useNavigate()
    const t = TranslationService.translate;

    useEffect(() => {
        try {
            const str = window.location.href.split('/');
            const myCode = str[str.length - 1].split(':');
            const userId: string = myCode[0];
            const businessId: string = myCode[1];
            const unitId: string = myCode[2];
            if (
                userId === undefined ||
                businessId === undefined ||
                unitId === undefined
            ) {
                ModalSwither.ShowErrorModal(t('SystemMessages', 'error10'));
                return;
            }
            DataFireStoreService.getBusinessesById(userId, businessId, true)
                .then(async (business) => {
                    //@ts-ignore
                    const bussData = await business.data()
                    const businessListModel: BusinessModel = {
                        ...bussData, id: businessId,
                    };
                    DataFireStoreService.getUnitById(userId, businessId, unitId, true)
                        .then(async (unit) => {
                            //@ts-ignore
                            const unitdata = await unit.data()
                            const unitListModel: UnitsModel = {
                                ...unitdata, id: unitId,
                            };
                            navigation('/transactions', {
                                state: {
                                    UserId: userId,
                                    Business: businessListModel,
                                    Units: unitListModel,
                                    disableLoading: true,
                                    goBack: '/',
                                    fromQRCode: true
                                }
                            });
                        })
                        .catch(err => {
                            ModalSwither.ShowErrorModal(t('SystemMessages', 'error10'));
                        });
                })
                .catch(err => {
                    ModalSwither.ShowErrorModal(t('SystemMessages', 'error10'));
                });
        } catch (err) {
            const t = TranslationService.translate;
            ModalSwither.ShowErrorModal(t('SystemMessages', 'error10'));
        }
    }, [])

    return (
        <></>
    )
}