import { storage } from "../../services/firebase";
export class FileStorageService {
    static async uploadFile(urlPath: string, file: File | string): Promise<string> {
        const reference = storage.ref(urlPath);
        if (typeof file == 'string') {
            return await reference.putString(file,'base64').then(async (res) => {
                const url = await reference.getDownloadURL().catch((error) => { throw error })
                return url;
            }).catch(err => {
                return 'error';
            });
        } else {
            return await reference.put(file).then(async (res) => {
                const url = await reference.getDownloadURL().catch((error) => { throw error })
                return url;
            }).catch(err => {
                return 'error';
            });
        }
    }
}