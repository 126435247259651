import theme from '../../../constants/theme';
export const Style = ({
    container: {
        backgroundColor: theme.COLORS.componentBgColor,
        borderWidth:0,
        borderRadius: 12,
        color: '#fff',
        width: '100%',
        height: 50,
        paddingTop:10,
        paddingLeft:10
    },
    dropdownContent:{
        height: 150, 
        backgroundColor: theme.COLORS.componentBgColor,
        right:0,
        padding:10,
        overflowY: 'auto'
    },
    icon: {
        backgroundColor:'transparent',
        border:0,
    }
});
