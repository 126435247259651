import { MainScreen } from "../../../screens/Home/Main";
import { MyAccountScreen } from "../../../screens/Home/Main/MyAccount";
import { QRCodeScreen } from "../../../screens/Home/QRCode";
import { QRScreen } from "../../../screens/Home/qr";
export const MainScreenNavigator = [
    {
        path: '/',
        exact: true,
        screen:<div className={"container-container"}> <MainScreen /></div>
    },
    {
        path: '/Home',
        exact: true,
        screen: <div className={"container-container"}> <MainScreen /></div>,
    },
    {
        path: '/MyAccount',
        exact: true,
        screen: <div className={"container-container"}> <MyAccountScreen /></div>,
    },
    {
        path: '/QRCode',
        exact: true,
        screen: <div className={"container-container"}> <QRCodeScreen /></div>,
    },
     {
        path: '/qr/:id',
        exact: true,
        screen: <div className={"container-container"}> <QRScreen /></div>,
    },
]
