import { Style } from './style';
import theme from '../../../../constants/theme';
import { TranslationService } from '../../../../services/translation.service';
export function PrivacyPolicyScreenComponent() {
  const t = TranslationService.translate;
  return (
    <div style={theme.STYLE.content}>
      <div className='px-4 py-3'>
        <p className='fontRegular mt-3' style={Style.text}>
          {t('SharedComponents', 'privacyText1')}
        </p>
        <p className='fontRegular mt-5' style={Style.text}>
          {t('SharedComponents', 'privacyText2')}
        </p>
      </div>
    </div>
  );
}
