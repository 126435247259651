import { useEffect, useState } from "react";
import { useAuth } from "../../../../context/Auth/authContext";
import theme from "../../../../constants/theme";
import { Style } from "./style";
import { TranslationService } from "../../../../services/translation.service";
import { BackButtonComponent } from "../../../../components/Shared/BackButtonComponent";
import { fireStore } from "../../../../services/firebase";
import { CampaignModel } from "../../../../api/model/CampaignModel";
import { CampaignCardComponent } from "../../../../components/CampaignCardComponent";
import { useLocation } from "react-router-dom";
export function CampaignsScreenComponent() {
  const { user } = useAuth();
  const [campaigns, setCampaigns] = useState([] as CampaignModel[]);

  const t = TranslationService.translate;
  const { state } = useLocation();
  useEffect(() => {
    //@ts-ignore
    fireStore
      .collectionGroup("Campaigns")
      //@ts-ignore
      .where("businessId", "==", state.business.id)
      .get()
      .then((res) => {
        const list: CampaignModel[] = [];
        res.forEach((element) => {
          //@ts-ignore
          list.push(element.data());
        });
        setCampaigns(list);
      });
  }, []);
  return (
    <div style={{ ...theme.STYLE.container }}>
      <div className="d-flex p-3">
        <BackButtonComponent />
        <p className="ms-4 mt-1 fontMedium" style={Style.title}>
          {t("MainScreen", "Campaigns")}
        </p>
      </div>

      <div className="mt-2">
        {campaigns.map((not, index) => {
          return <CampaignCardComponent campaign={not} key={index} />;
        })}
      </div>
    </div>
  );
}
