import React, { useEffect } from 'react';
import theme from '../../constants/theme';
import { Props } from './types';
import { TranslationService } from '../../services/translation.service';
import useRecorder from '../../hooks/use-recorder';
import { UseRecorder } from '../../constants/types';
import RecorderControls from '../recorder-controls';
import RecordingsList from '../recordings-list';
export function MicComponent(props: Props) {
    const t = TranslationService.translate;

    useEffect(() => {

    }, [])

    return (
        <div className={"text-center"}
            style={{
                paddingBlock: 10,
                width: '100%',
                backgroundColor: theme.COLORS.componentBgColor,
                borderRadius: 12,
                paddingInline: theme.SPACES.paddingHorizontal,
                marginTop: 5,
                zIndex: -1
            }}>
            <RecorderControls recorderState={props.recorderState} handlers={props.handlers} />
            <RecordingsList recordings={props.recordings} audio={props.audio} file={props.file} deleteAudio={props.deleteAudio}/>
        </div>
        // <div style={{
        //     alignItems: 'center', justifyContent: 'center', height: 150,
        //     width: '100%',
        //     backgroundColor: theme.COLORS.componentBgColor,
        //     borderRadius: 12,
        //     paddingInline: theme.SPACES.paddingHorizontal,
        //     marginTop: 5,
        //     zIndex: -1
        // }}>
        //     <img src={theme.ICONS.ic_recording} style={{ height: 64, width: 64 }} />
        //     <button className='transparentButton position-absolute' style={Style.closeMic} onClick={() => {
        //         props.close()
        //     }}>
        //         <img src={theme.ICONS.ic_close} style={{ height: 20, width: 20 }} />
        //     </button>
        //     <div style={Style.actions}>
        //         {
        //             isDeleted ?
        //                 <>
        //                     <p className='fontMediumItalic' style={Style.timeZone}>{micStatus.recordTime}</p>
        //                     <button className='transparentButton' onClick={() => {
        //                         setIsDeleted(false)
        //                     }} style={{ marginTop: 5 }}>
        //                         <img src={theme.ICONS.ic_play} style={{ height: 20, width: 20 }} />
        //                     </button>
        //                 </>
        //                 :
        //                 micActive ?
        //                     <>
        //                         <p className='fontMediumItalic' style={Style.timeZone}>{micStatus.recordTime}</p>
        //                         <button className='transparentButton' onClick={() => {
        //                         }} style={{ marginTop: 5 }}>
        //                             <img src={theme.ICONS.ic_pause} style={{ height: 20, width: 20 }} />
        //                         </button>
        //                     </> :
        //                     <>
        //                         <p className='fontMediumItalic' style={Style.timeZone}>
        //                             {micStatus.playTime ? micStatus.playTime : '00:00:00'} / {micStatus.recordTime ? micStatus.recordTime : '00:00:00'}
        //                         </p>
        //                         <div className='d-flex' style={{ marginTop: 5, justifyContent: 'space-around', width: '30%' }}>
        //                             <button className='transparentButton' onClick={() => {
        //                             }}>
        //                                 <img src={theme.ICONS.ic_play} style={{ height: 20, width: 20 }} />
        //                             </button>
        //                             <button className='transparentButton' onClick={() => {
        //                             }}>
        //                                 <img src={theme.ICONS.ic_pause} style={{ height: 20, width: 20 }} />
        //                             </button>

        //                             <button className='transparentButton' onClick={() => {
        //                             }}>
        //                                 <img src={theme.ICONS.ic_trash} style={{ height: 20, width: 20 }} />
        //                             </button>
        //                         </div>
        //                     </>
        //         }
        //     </div>
        // </div>
    )
}