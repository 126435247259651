import { Style } from "./style";
import { Props } from "./types";
import theme from "../../constants/theme";
import { TranslationService } from "../../services/translation.service";
import { Modal } from 'react-bootstrap';
import { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import moment from "moment";

export function ImageMediaPickerHalfModal(props: Props) {

    const t = TranslationService.translate;
    const webcamRef = useRef(null);
    const [camera, setCamera] = useState(false);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = useCallback(() => {
        //@ts-ignore
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        setCamera(false)
    }, [webcamRef, setImgSrc]);

    const fileInputRef = useRef();
    function closeModal() {
        props.close()
    }

    const changeHandler = (event: any) => {
        for (const element of event.target.files) {
            const fileName = element.name.split('.');
            if (
                fileName[fileName.length - 1] === 'jpg' ||
                fileName[fileName.length - 1] === 'png' ||
                fileName[fileName.length - 1] === 'jpeg') {
                props.fileListener && props.fileListener({
                    uri: URL.createObjectURL(element),
                    fileName: event.target.files[0].name,
                    file: element
                })
            }
        }
    };

    return (
        <Modal
            show={true}
            centered
            aria-labelledby="contained-modal-title-center"
        >
            <div className="d-flex" style={Style.containerStyle}>
                <div style={Style.content}>
                    <button className="transparentButton absolute" style={Style.closeContainer}
                        onClick={closeModal}>
                        <img style={{
                            width: 16,
                            height: 16
                        }} src={theme.ICONS.ic_delete_white} />
                    </button >
                    <div style={Style.classRow}>
                        {imgSrc &&
                            <div className="text-center" >
                                <img
                                    className="relative"
                                    src={imgSrc}
                                    alt='bildir'
                                />
                                <button className="transparentButton absolute" style={Style.closeContainer}
                                    onClick={() => {
                                        setImgSrc(null)
                                        setCamera(true)
                                    }}>
                                    <img
                                        style={{
                                            width: 16,
                                            height: 16
                                        }}
                                        src={theme.ICONS.ic_close}
                                        alt='bildir'
                                    />
                                </button >
                                <button className="transparentButton" style={Style.closeContainer}
                                    onClick={() => {
                                        props.fileListener && props.fileListener({
                                            uri: imgSrc,
                                            fileName: moment(new Date()).format('hh:mm:ss') + '.jpg',
                                            //@ts-ignore
                                            file: imgSrc.replace('data:image/jpeg;base64,', '')
                                        })
                                        closeModal()
                                    }}>
                                    <img
                                        className="mt-3"
                                        style={{
                                            width: 32,
                                            height: 32
                                        }} src={theme.ICONS.ic_tik}
                                        alt='bildir'
                                    />
                                </button >
                            </div>

                        }
                        {
                            camera &&
                            <div className="text-center" style={{ alignItems: 'center' }}>
                                <Webcam
                                    videoConstraints={
                                        { facingMode: { exact: "environment" } }
                                    }
                                    style={{ width: '100%' }}
                                    size={.1}
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                />
                                <button className="text-center transparentButton" onClick={capture}>
                                    <img src={theme.ICONS.ic_camera} alt='bildir' style={{ height: 24, width: 24 }} />
                                </button>
                            </div>
                        }
                        <div className="d-flex justify-content-around">
                            {
                                props.imgFiles.map(function (data, index) {
                                    return (
                                        <div key={index}>
                                            <img
                                                //@ts-ignore
                                                src={data.uri}
                                                className='relative'
                                                style={{ height: 100, width: 100 }}
                                            />
                                            <button className="transparentButton "
                                                style={Style.deleteIcon} onClick={() => {
                                                    props.popImg(index)
                                                }}>
                                                <img style={{
                                                    width: 16,
                                                    height: 16,
                                                }} src={theme.ICONS.ic_delete_white} />
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        (!camera && !imgSrc) &&
                        <div className="d-flex" style={Style.classRow}>
                            <div style={{ alignItems: 'center' }}>
                                <button className="transparentButton" onClick={() => setCamera(true)}>
                                    <img style={{
                                        width: 32,
                                        height: 32,
                                        alignSelf: 'center',
                                    }} src={theme.ICONS.ic_cam} />
                                    <p className="fontMediumItalic" style={Style.text}>
                                        {t('SharedComponents', 'camera')}
                                    </p>
                                </button>
                            </div>
                            <div style={{ alignItems: 'center' }}>
                                <button className="transparentButton" onClick={() => {
                                    //@ts-ignore
                                    fileInputRef.current.click()
                                }}>
                                    <input type="file" ref={fileInputRef as any} onChange={changeHandler} hidden={true} multiple />
                                    <img style={{
                                        width: 32,
                                        height: 32,
                                        alignSelf: 'center',
                                    }} src={theme.ICONS.ic_media} />
                                    <p className="fontMediumItalic" style={Style.text}>
                                        {t('SharedComponents', 'gallery')}
                                    </p>
                                </button>
                            </div>
                            {
                                props.hasMic ?
                                    <div style={{ alignItems: 'center' }}>
                                        <button className="transparentButton" onClick={() => {
                                            props.micOnClick && props.micOnClick()
                                        }}>
                                            <img style={{
                                                width: 16,
                                                height: 32,
                                                alignSelf: 'center',
                                            }} src={theme.ICONS.ic_mic} />
                                            <p className="fontMediumItalic" style={Style.text}>
                                                {t('SharedComponents', 'mic')}
                                            </p>
                                        </button>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    }
                </div>
            </div>
        </Modal >
    )
}