import React, { } from 'react';
import { Style } from './style';
import { Props } from './types';
import { TranslationService } from '../../services/translation.service';
import theme from '../../constants/theme';
import { MomentConverter } from '../../utils/moment';
export function AnketCardComponent(props: Props) {
    const t = TranslationService.translate;
    return (
        <div className='d-flex' style={Style.container}>
            <img src={theme.ICONS.ic_anket} style={{ height: 48, width: 48 }} />
            <div style={{ marginLeft: 16 }}>
                <p className='fontMediumItalic' style={Style.title}>{props.Anket.title}</p>
                <div className='d-flex align-items-center mt-2' >
                    <img src={theme.ICONS.ic_clock} style={{ height: 11, width: 11 }} />
                    <p className='fontMediumItalic ms-2' style={Style.time}>
                        {
                            // @ts-ignore
                            MomentConverter.formatDateDMY(props.Anket.date.toDate())
                        }
                    </p>
                </div>
            </div>
        </div >
    )
}