import theme from "../../../../constants/theme";
export const Style = {
  container: {
    padding: 10,
    backgroundColor: theme.COLORS.componentBgColor,
    borderRadius: 5,
    marginTop: 10,
    paddingBottom: 25,
  },
  commentcardPP: {
    height: 64,
    width: 64,
    borderRadius: 38,
  },
  commentDetail: {
    marginLeft: 10,
    paddingRight: 70,
  },
  userName: {
    color: "#fff",
    fontWeight: "500",
  },
  commentText: {
    color: "#fff",
    marginTop: 5,
  },
  commentDate: {
    marginTop: 5,
    color: theme.COLORS.containerTextColor,
    fontSize: 12,
  },
  formArea: {
    backgroundColor: theme.COLORS.componentBgColor,
    padding: 20,
    width: "100%",
  },
  textArea: {
    width: "90%",
    borderRadius: 20,
  },
};
