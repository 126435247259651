import theme from '../../../../constants/theme';

export const Style = ({
    headerContainer:{
        backgroundColor: theme.COLORS.containerColor, 
        paddingVertical: 10, 
        alignItems: 'center'
    },
    header:{
        color:'#fff',
        fontSize:18
    },
    whichBildir: { 
        color: '#fff', 
        fontSize: 18 ,
        width:'100%'
    },
});
