import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from './context/Auth/authContext';
import { SplashScreen } from './screens/Initial/SplashScreen';
import { InitialRouter } from './navigations/Initial';
import { ModalStack } from "./components/ModalStack";
import { HomeRouter } from "./navigations/Home";
import './App.css'

function App() {
  const { user, initializing } = useAuth()

  return (
    <div className='row pad-mar'>
      <div className="col-xs-0 col-sm-0 col-md-4 col-xl-4"></div>
      <div className="col-xs-12 col-sm-12 col-md-4 col-xl-4 pad-mar" >
        <ModalStack />
        {
          initializing ?
            <BrowserRouter>
              <Routes>
                <Route path="*" element={<SplashScreen />} />
              </Routes>
            </BrowserRouter>
            :
            <>
              <InitialRouter /> 
              <HomeRouter />
            </>
        }
      </div>
      <div className="col-xs-12 col-sm-12 col-md-4 col-xl-4"></div>
    </div>
  );
}

export default App;