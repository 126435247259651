import theme from '../../constants/theme';
export const Style = ({
    container: {
        backgroundColor: theme.COLORS.containerColor,
        borderRadius: 12,
        paddingInline: 16,
        paddingBlock: 12,
        marginTop: 20,
    },
   // box-shadow: 0 8px 12px -4px rgba(184, 184, 210, 0.2);
    title: {
        color: '#fff',
        fontSize: 14
    },
    time: {
        color: theme.COLORS.containerTextColor,
        fontSize: 14,
        marginLeft: 5
    }
});
