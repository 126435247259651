import { useEffect, useState } from 'react';
import { Props } from './types';
import theme from '../../../constants/theme';
import { useAuth } from '../../../context/Auth/authContext';
import { DataFireStoreService } from '../../../api/services/data-firestore.service';
import { BildirimModel } from '../../../api/model/BildirimModel';
import { SearchComponent } from '../../../components/Shared/SearchComponent';
import { BildirCardComponent } from '../../../components/BildirCardComponent';
import { BildirimStates } from '../../../constants/bildirimStates';
import { typeModel } from '../../../constants/bildirimTypes';
import { useNavigate } from 'react-router-dom';
import { Style } from './style';

export function BusinessScreenComponent(props: Props) {
  const { user } = useAuth();
  const navigation = useNavigate()
  const [bildirims, setBildirims] = useState([] as BildirimModel[]);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [typeList, setTypeList] = useState([] as typeModel[]);
  const [stateList, setStateList] = useState([] as BildirimStates[]);

  function getNormalUserBildirims(
    index: number,
    searchText?: string,
    types?: typeModel[],
    states?: BildirimStates[],
  ) {
    DataFireStoreService.getUnitBildirims(
      user?.uid as any,
      index,
      searchText as any,
      types,
      states,
    ).then(res => {
      if (searchText !== '' && searchText !== undefined) {
        const filtered = res.filter(item => item.user.name.includes(searchText))
        setBildirims(filtered);
      } else {
        setBildirims(res)
      }
    });
  }

  function callSearch() {
    getNormalUserBildirims(page, search);
  }

  function clearSearch() {
    setSearch('');
    getNormalUserBildirims(page);
  }

  function applyFilter(typeList: typeModel[], stateList: BildirimStates[]) {
    getNormalUserBildirims(page, search, typeList, stateList);
  }

  useEffect(() => {
    if (search.length > 3) {
      getNormalUserBildirims(page, search);
    } else if (search.length == 2 || search.length == 0) {
      getNormalUserBildirims(page,'')
    }
  }, [search]);



  return (
    < >
      <div className='text-center' style={{ paddingInline: 20 }}>
        <img style={theme.STYLE.logoContainer} src={theme.ICONS.logo} />
      </div>
      <div style={{ paddingInline: 20 }}>
        <div style={{ marginTop: 20, }}>
          <SearchComponent
            callSearch={callSearch}
            clearSearch={clearSearch}
            value={search}
            listener={setSearch}
            hasFilter={true}
            typeListListener={setTypeList}
            stateListListener={setStateList}
            applyFilter={applyFilter}
            typeList={typeList}
            stateList={stateList}
          />
        </div>
        <div style={{ marginTop: 26, }}>
          {bildirims.map((data: BildirimModel, index: number) => {
            return (
              <div key={index} style={{ marginTop: 14 }}>
                <BildirCardComponent bildirim={data} showUser={true} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
