export class StringFormatter {
    static generateId(length: number) {
        // todo time ekle
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    static getCurrentStack() {
        return window.location.pathname.split('/')[0]
    }
    static dynamicSort(property: string) {
        var sortOrder = 1;
        //@ts-ignore
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
}