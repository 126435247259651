import React, { useEffect, useState } from 'react';
import { Props } from './types';
import { Style } from './style';
import { useAuth } from '../../../context/Auth/authContext';
import { TranslationService } from '../../../services/translation.service';
import { ModalSwither } from '../../../utils/modal-switcher';
import { BlueButtonComponent } from '../../../components/Shared/BlueButtonComponent';
import { useNavigate } from 'react-router-dom';
import { InputComponent } from '../../../components/Shared/InputComponent';
import theme from '../../../constants/theme';
export function RegisterScreenComponent({ }: Props) {
    const navigation = useNavigate()
    const preview = require('../../../assets/images/ic_preview.png');
    const t = TranslationService.translate;
    const { signUp } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passType, setPassType] = useState('password')
    const [isAccept, setIsAccept] = useState(false);

    function callSignUp() {
        email === '' || password === ''
            ? ModalSwither.ShowErrorModal(t('SystemMessages', 'error5'))
            : isAccept
                ? signUp(email, password)
                : ModalSwither.ShowErrorModal(t('SystemMessages', 'error6'));
    }

    return (
        <>
            <>
                <div style={Style.container} className='text-center'>
                    <img src={theme.ICONS.logo} style={Style.logo} alt='bildir' />
                </div>
                <div style={{ paddingInline: 24 }}>
                    <p className='fontMedium' style={Style.title}>
                        {t('AutenticationScreen', 'signUp')}
                    </p>
                </div>
                <div style={Style.form}>
                    <p className='fontMediumItalic' style={Style.label}>
                        {t('AutenticationScreen', 'email')}
                    </p>
                    <InputComponent
                        listener={setEmail}
                        type='text'
                    />
                    <p className='fontMediumItalic mt-3' style={Style.label}>
                        {t('AutenticationScreen', 'password')}
                    </p>
                    <InputComponent
                        listener={setPassword}
                        type={passType}
                        hasIcon={true}
                        icon={preview}
                        onClickIcon={() => {
                            passType === 'password' ?
                                setPassType('text') : setPassType('password');
                        }}
                    />
                    <div className='fontMediumItalic d-flex align-items-center'>
                        <input type={'checkbox'} id='privacy' onChange={(value)=>{
                            setIsAccept(!isAccept)
                        }}/>
                        <button
                            style={Style.privacy}
                            onClick={() => { }}>
                            {<span style={{color:theme.COLORS.blue}}>{t('AutenticationScreen', 'privacyPolicy')}</span>}{t('AutenticationScreen', 'accept')}
                        </button>
                    </div>
                    <div style={{ marginTop: 27 }}>
                        <BlueButtonComponent
                            onClick={callSignUp}
                            text={t('AutenticationScreen', 'signUp')}
                        />
                    </div>
                    <div style={{ marginTop: 25 }} className='d-flex'>
                        <p className='fontMediumItalic' style={Style.haventAccount}>
                            {t('AutenticationScreen', 'haveAnAccount')}
                        </p>
                        <button className='transparentButton fontMediumItalic' onClick={() => {
                            navigation('/')
                        }}>
                            <p className='fontMediumItalic' style={Style.singUp}>
                                {t('AutenticationScreen', 'signIn')}
                            </p>
                        </button>

                    </div>
                </div>
            </>
        </>
    )
}