import { useEffect, useState } from 'react';
import { TranslationService } from '../../../../services/translation.service';
import { BackButtonComponent } from '../../../../components/Shared/BackButtonComponent';
import { Style } from './style';
import { AnketPointComponent } from '../../../../components/AnketPointComponent';
import { TextAreaComponent } from '../../../../components/Shared/TextAreaComponent';
import { BlueButtonComponent } from '../../../../components/Shared/BlueButtonComponent';
import { CevapModel } from '../../../../api/model/CevapModel';
import { useAuth } from '../../../../context/Auth/authContext';
import { DataFireStoreService } from '../../../../api/services/data-firestore.service';
import { ModalSwither } from '../../../../utils/modal-switcher';
import { useLocation } from 'react-router-dom';
import theme from "../../../../constants/theme";

export function AnketScreenComponent() {
  const { user } = useAuth()
  const { state } = useLocation();

  const t = TranslationService.translate;
  const [comment, setComment] = useState('')
  const [cevap, setCevap] = useState({
    cevaplar: [],
    date: new Date(),
    user: {
      id: user?.uid as any,
      name: user?.displayName as any
    },
    yorum: comment
  } as CevapModel)

  useEffect(() => {
    const list = [] as any
    // @ts-ignore
    state.Anket.questions.forEach(function (question: string, index: number) {
      const cevapModel = {
        puan: 0,
        soru: question
      }
      list.push(cevapModel)
    })
    cevap.cevaplar = list
  }, [])

  function setPoint(value: number, index: number) {
    cevap.cevaplar[index].puan = value
  }

  function save() {
    if (!user) {
      ModalSwither.ShowLoginStackModal()
      return;
    }
    cevap.yorum = comment
    DataFireStoreService.createAnketCevap(
      // @ts-ignore
      state.UserId,
      // @ts-ignore
      state.Business.id,
      // @ts-ignore
      state.UnitsId,
      // @ts-ignore
      state.ServiceId,
      // @ts-ignore
      state.Anket.id,
      cevap
    ).then(res => {
      ModalSwither.ShowSuccessModal(t('SystemMessages', 'success3'));
    })
  }

  // @ts-ignore
  const imageUrl = state.Business.imageURL;
  return (

    <>

      <div style={{
        backgroundImage: "url(" + imageUrl + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: 150,
        width: '100%'
      }}>
        <div className='d-flex' style={{
          backgroundColor: theme.COLORS.containerColor,
          opacity: 0.8,
          height: 40,
          alignItems: 'center'
        }}>
          <BackButtonComponent />
          <p className='fontMediumItalic text-center' style={{
            color: '#fff',
            fontSize: 18,
            flex: 1,
          }}>
            {
              // @ts-ignore
              state.header
            }
          </p>
        </div>
      </div>



      <div className='px-3 mt-4'>
        <div className='d-flex'>
          <p className='fontMediumItalic text-center' style={{
            color: '#fff',
            fontSize: 18,
            flex: 1,
          }}>
            {
              // @ts-ignore
              state.Anket.title
            }
          </p>
        </div>
        <div className='mt-2'>
          {
            // @ts-ignore
            state.Anket.questions.map(function (data, index) {
              return (
                <div key={index} style={{ marginTop: 10 }}>
                  <AnketPointComponent
                    listener={(value) => {
                      setPoint(value, index)
                    }}
                    title={data}
                    index={index + 1}
                  />
                </div>
              )
            })
          }
        </div>
        <div className='mt-3'>
          <p className='fontMediumItalic' style={{ color: '#fff', fontSize: 12 }}>{t('SearchScreen', 'comment')}</p>
          <div style={Style.textAreaContainer}>
            <TextAreaComponent
              listener={setComment}
              value={comment}
            />
          </div>
          <div style={{ alignItems: 'flex-end', marginTop: 20 }}>
            <BlueButtonComponent
              onClick={save}
              text={t('SearchScreen', 'send')}
            />
          </div>
        </div>
      </div>
    </>
  );
}
