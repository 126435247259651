import { NotificationsScreen } from "../../../screens/Home/Notifications";
import { ChatScreen } from "../../../screens/Home/ChatScreen";
import { BusinessScreen } from "../../../screens/Home/Business";
export const NotificationScreenNavigator = [
    {
        path: '/Notifications',
        exact: true,
        screen: <div className={"container-container"}> <NotificationsScreen /></div>,
    },
    {
        path: '/Chat',
        exact: true,
        screen: <div className={"container-container"}> <ChatScreen /></div>,
    },
    {
        path: '/Business',
        exact: true,
        screen: <div className={"container-container"}> <BusinessScreen /></div>,
    }
]