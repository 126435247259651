import { useState } from "react";
import { Style } from "./style";
import { Props } from "./types";
import { TranslationService } from "../../services/translation.service";
import { MomentConverter } from "../../utils/moment";
import { useAuth } from "../../context/Auth/authContext";
import theme from "../../constants/theme";
import { useNavigate } from "react-router-dom";
export function CampaignCardComponent({ campaign }: Props) {
  const t = TranslationService.translate;
  const { user } = useAuth();
  const navigation = useNavigate();
  return (
    <div
      className="p-2"
      onClick={() => navigation("/CampaignDetail", { state: { campaign: campaign } })}
    >
      <div className="d-flex mt-3" style={Style.container}>
        <div
          style={{
            width: "10%",
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 12,
            backgroundColor: theme.COLORS.green,
            position: "relative",
          }}
        >
          <p
            className="fontMediumItalic"
            style={{
              color: "#fff",
              fontSize: 14,
              width: 136,
              textAlign: "center",
              transform: "rotate(270deg)",
              justifySelf: "center",
              position: "absolute",
              top: 0,
              bottom: 0,
              margin: "auto",
            }}
          ></p>
        </div>
        <div style={{ marginLeft: 10, flex: 10 }}>
          <div className="d-flex" style={Style.bussinesContainer}>
            <img style={Style.imgContainer} src={campaign.imageURL} />
            <div className="fontRegular" style={{ marginLeft: 12 }}>
              <p style={{ ...Style.businessTitle, fontSize: 14 }}>
                {campaign.title}
              </p>
              <p className="threeLineText" style={Style.businessTitle}>
                {campaign.description}
              </p>
            </div>
          </div>
          <div className="line mt-1" />
          <p style={Style.date}>
            {t("SharedComponents", "endDate") +
              " " +
              //@ts-ignore
              MomentConverter.formatDateDMY(campaign.endDate.toDate())}
          </p>
          {user &&
            user.uid &&
            campaign.users.filter((item) => item.uid === user?.uid!).length >
              0 && (
              <p
                style={{
                  ...Style.katildiniz,
                  textAlign: "right",
                  paddingRight: 15,
                  marginBottom: 30,
                }}
              >
                {t("SharedComponents", "joined")}
              </p>
            )}
        </div>
      </div>
    </div>
  );
}
