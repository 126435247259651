import { FireStoreRequest } from "./firestore.request";
import { PathModel } from "../api/model/PathModel";
import { LoadingSwither } from "../utils/loading-switcher";
import { fireStore } from "./firebase";

export class FireStoreService {
    private static instance: FireStoreService;

    private constructor() {
    }

    public get<T>(collections: PathModel[]): FireStoreRequest<T> {
        return new FireStoreRequest<T>('get', collections, this);
    }

    public add<T>(collections: PathModel[]): FireStoreRequest<T> {
        return new FireStoreRequest<T>('add', collections, this);
    }

    public update<T>(collections: PathModel[]): FireStoreRequest<T> {
        return new FireStoreRequest<T>('update', collections, this);
    }

    public delete<T>(collections: PathModel[]): FireStoreRequest<T> {
        return new FireStoreRequest<T>('delete', collections, this);
    }

    protected async getQuery<T>(firestoreRequest: FireStoreRequest<T>, method: string): Promise<any> {
        var db = fireStore;
        var path = firestoreRequest.getPath();
        var conditions = firestoreRequest.getCondition();
        var limit = firestoreRequest.getLimit();
        var orderBy = firestoreRequest.getOrderBy();
        var startAt = firestoreRequest.getStartAt();
        var endAt = firestoreRequest.getEndAt();
        var startAfter = firestoreRequest.getStartAfter();
        var endBefore = firestoreRequest.getEndBefore();
        path.forEach((element) => {
            if (element.document)
                // @ts-ignore
                db = db.collection(element.collection).doc(element.document);
            else
                // @ts-ignore
                db = db.collection(element.collection);
        });

        if (method === 'add' && !path[path.length - 1].document) {
            // ancak collection'larda add komutu kullanılabailir. Son elemen doc ise buraya girmez
            const model = firestoreRequest.getBody();
            const id = firestoreRequest.getId()
            if (id !== '') {
                return db.doc(id.toString()).set(model)
            }
            // @ts-ignore
            return db.add(model);
        }

        else if ((method === 'update' || method === 'delete') && path[path.length - 1].document) {
            // ancak documentlar'larda update ve delete methodları kullanılabailir. Son elemen collection ise buraya girmez
            // @ts-ignore
            return method === 'update' ? db.update(firestoreRequest.getBody()) : db.delete();
        }

        else if (method === 'get') {
            if (conditions.length > 0 && !path[path.length - 1].document) {
                // ancak collection'larda where komutu kullanılabailir. Son elemen doc ise buraya girmez
                conditions.forEach(condition => {
                    // @ts-ignore
                    db = db.where(condition.field, condition.filter, condition.value);
                });
            }
            // @ts-ignore
            if (orderBy.fieldPath !== '' && !path[path.length - 1].document) {
                // startAt, endAt , startAfter , endBefore ==== OrderBy ile kullanılan özellikler
                // bkz : orderBy('point').startAt(3)  ===> sıralamaya 3'ün üstündekilerden başla
                // @ts-ignore
                db = db.orderBy(orderBy.fieldPath, orderBy.directionStr);
                // @ts-ignore
                startAt ? db = db.startAt(startAt) : db = db;
                // @ts-ignore
                endAt ? db = db.endAt(endAt) : db = db;
                // @ts-ignore
                startAfter ? db = db.startAfter(startAfter) : db = db;
                // @ts-ignore
                endBefore ? db = db.endBefore(endBefore) : db = db;
            }
            // @ts-ignore
            limit > 0 ? db = db.limit(limit) : db = db;
            // @ts-ignore
            return db.get();
        }
    }

    public async doApiCall<T>(firestoreRequest: FireStoreRequest<T>): Promise<{ [key: string]: any }> {
        const loadingDisabled = firestoreRequest.getLoadingDisabled()
        console.log('loading Status : ', loadingDisabled)
        !loadingDisabled && LoadingSwither.ShowLoading();
        const method = firestoreRequest.getMethod();
        const db = this.getQuery(firestoreRequest, method)
        return db
            .then((docRef) => {
                LoadingSwither.HideLoading();
                if (method === 'add') {
                    console.log('add', docRef ? docRef.id : true)
                    return docRef ? docRef.id : true;
                }
                else if (method === 'add' || method === 'update' || method === 'delete') {
                    return !docRef ? true : false;
                }
                console.log(docRef.docs ? docRef.docs : docRef._data)
                return docRef.docs ? docRef.docs : docRef
            })
            .catch((e: any) => {
                console.log(e)
                LoadingSwither.HideLoading();
                return Promise.reject(e);
            })

    }

    public static getInstance() {
        if (!this.instance) {
            this.instance = new FireStoreService();
        }

        return this.instance;
    }
}