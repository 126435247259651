import { useEffect, useState } from 'react';
import { TranslationService } from '../../../../services/translation.service';
import { BackButtonComponent } from '../../../../components/Shared/BackButtonComponent';
import { Style } from './style';
import { DataFireStoreService } from '../../../../api/services/data-firestore.service';
import { AnketModel } from '../../../../api/model/AnketModel';
import { useAuth } from '../../../../context/Auth/authContext';
import { useLocation } from 'react-router-dom';
import { AnketCardComponent } from '../../../../components/AnketCardComponent';
import { useNavigate } from 'react-router-dom';
import theme from "../../../../constants/theme";
export function AnketListScreenComponent() {

  const t = TranslationService.translate;
  const { state } = useLocation();
  const navigation = useNavigate()
  const { user } = useAuth()
  const [anketler, setAnketler] = useState([] as AnketModel[])

  function getAnketList() {
    // @ts-ignore
    DataFireStoreService.getAnketList(state.UserId, state.Business.id, state.UnitsId, user?.uid as any)
      .then(res => {
        setAnketler(res)
      })
  }

  useEffect(() => {
    getAnketList()
  }, [])

    // @ts-ignore
    const imageUrl = state.Business.imageURL;
  return (
    <>

        <div style={{
            backgroundImage: "url(" + imageUrl + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: 150,
            width: '100%'
        }}>
            <div className='d-flex' style={{
                backgroundColor: theme.COLORS.containerColor,
                opacity: 0.8,
                height: 40,
                alignItems:'center'
            }}>
                <BackButtonComponent />
                <p className='fontMediumItalic text-center' style={{
                    color:'#fff',
                    fontSize:18,
                    flex:1,
                }}>
                    {
                        // @ts-ignore
                        state.header
                    }
                </p>
            </div>
        </div>

      <div className='px-3 mt-4'>
        <div className='d-flex'>
          <p className='fontMediumItalic text-center' style={Style.whichBildir}>{t('SearchScreen', 'surveys')}</p>
        </div>
      </div>
      <div className='mt-2 px-2'>
        {
          anketler.map(function (data, index) {
            return (
              <button
                className='transparentButton'
                style={{ width: '100%' }}
                key={index}
                onClick={() => {
                  navigation('/Anket',
                    {
                      state: {
                        // @ts-ignore
                        UserId: state.UserId,
                        Anket: data,
                        // @ts-ignore
                        Business: state.Business,
                        // @ts-ignore
                        UnitsId: state.UnitsId,
                        // @ts-ignore
                        header: state.header,
                        ServiceId: data.serviceId
                      }
                    })
                  // navigation.navigate('Anket',
                  //   {
                  //     UserId: route.params.UserId,
                  //     Anket: data._data,
                  //     Business: route.params.Business,
                  //     UnitsId: route.params.UnitsId,
                  //     header: route.params.header,
                  //     ServiceId: data._data.serviceId
                  //   })
                }}
              >
                <AnketCardComponent
                  Anket={data}
                />
              </button>
            )
          })
        }
      </div>
    </>
  );
}
