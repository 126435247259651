import { Props } from "./types";
import { useLocation } from "react-router-dom";
import { BackButtonComponent } from "../../../../components/Shared/BackButtonComponent";
import theme from "../../../../constants/theme";
import { TranslationService } from "../../../../services/translation.service";
import { useAuth } from "../../../../context/Auth/authContext";
import { useEffect, useState } from "react";
import { CommentModel } from "../../../../api/model/CommentModel";
import { DataFireStoreService } from "../../../../api/services/data-firestore.service";
import { StringFormatter } from "../../../../utils/string";
import { MomentConverter } from "../../../../utils/moment";
import { Style } from "./style";
import { ModalSwither } from "../../../../utils/modal-switcher";
//import Rate from "rc-rate";
import React from "react";
import Rating from "@mui/material/Rating";

export function UnitsCommentComponent({}: Props) {
  const { state } = useLocation();
  //@ts-ignore
  const unit = state.unit;
  //@ts-ignore
  const business = state.business;
  const imageUrl = business.imageURL ? business.imageURL : business.image;
  const t = TranslationService.translate;
  const { user } = useAuth();

  const [comments, setComments] = useState([] as CommentModel[]);
  const [comment, setComment] = useState("");
  const [point, setPoint] = useState(-1);
  const [canComment, setCanComment] = useState(true);
  const [remainDays, setRemainDays] = useState(0);
  const [waitingPublish, setWaitingPublish] = useState(false);
  const [row, setRow] = useState(2);

  useEffect(() => {
    let value = comment;
    if (value) {
      if (value.length < 30) {
        if (row != 2) {
          setRow(2);
        }
      } else if (value.length >= 30 && value.length < 60) {
        if (row != 3) {
          setRow(3);
        }
      } else if (value.length >= 60) {
        if (row != 4) {
          setRow(4);
        }
      }
    }
  }, [comment]);

  const getComments = () => {
    DataFireStoreService.getUnitComments(
      business.user.uid,
      business.id,
      unit.id
    ).then((res) => {
      setComments(res);
    });
  };

  const checkComment = () => {
    if (user) {
      DataFireStoreService.getComment(
        business.user.uid,
        business.id,
        unit.id,
        user?.uid as string
      ).then((res) => {
        var anyCommentWaitingPublish = false;
        res.forEach((element) => {
          if (element.isPublished == false) {
            anyCommentWaitingPublish = true;
          }
        });
        setWaitingPublish(anyCommentWaitingPublish);
        if (res.length > 0) {
          const list = res.sort(StringFormatter.dynamicSort("date"));
          const lastComment = list[list.length - 1];
          const diff = MomentConverter.getDiffHour(lastComment.date, 24);
          setCanComment(diff.status);
          setRemainDays(diff.days);
        }
      });
    } else {
      setCanComment(true);
      setWaitingPublish(false);
    }
  };

  useEffect(() => {
    getComments();
    checkComment();
  }, []);

  const sendComment = () => {
    if (!user) {
      ModalSwither.ShowLoginStackModal();
      return;
    }
    if (point == -1 || comment === "") {
      ModalSwither.ShowErrorModal(t("SystemMessages", "error17"));
      return;
    }
    const model: CommentModel = {
      user: {
        displayName: user?.displayName!,
        uid: user?.uid!,
        photoURL: user?.photoURL!,
      },
      date: new Date(),
      comment: comment,
      point: point,
      isPublished: false,
    };
    DataFireStoreService.sendComment(
      business.user.uid,
      business.id,
      unit.id,
      model
    ).then(() => {
      setPoint(0);
      setComment("");
      setWaitingPublish(true);
      setCanComment(false);
      setRemainDays(0);
      ModalSwither.ShowSuccessModal(t("SystemMessages", "success3"));
      return;
    });
  };
  const wrapper = React.createRef();
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + imageUrl + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: 150,
          width: "100%",
        }}
      >
        <div
          className="d-flex"
          style={{
            backgroundColor: theme.COLORS.containerColor,
            opacity: 0.8,
            height: 40,
            alignItems: "center",
          }}
        >
          <BackButtonComponent
            goBack={
              //@ts-ignore
              state.goBack ? state.goBack : undefined
            }
          />
          <p
            className="fontMediumItalic text-center"
            style={{
              color: "#fff",
              fontSize: 18,
              flex: 1,
            }}
          >
            {business.title + " " + unit.title}
          </p>
        </div>
      </div>
      <div className="px-3 mt-4">
        {waitingPublish && (
          <div className="d-flex align-items-center">
            <img
              style={{ width: 32, height: 32 }}
              src={theme.ICONS.ic_info}
              alt="bildirr"
            />
            <p style={Style.commentText} className="ms-2">
              {t("SearchScreen", "commentWaitForPublish")}
            </p>
          </div>
        )}
        {comments.map((comment) => {
          let imageSource;
          if (!comment.user.photoURL) {
            imageSource = require("../../../../assets/images/bottombar/active/profile.png");
          } else {
            imageSource = comment.user.photoURL;
          }
          return (
            <div
              className="d-flex"
              style={{ ...Style.container, position: "relative" }}
            >
              <img
                src={imageSource}
                alt="bildirr"
                style={Style.commentcardPP}
              />
              <div style={Style.commentDetail}>
                <p style={Style.userName}>{comment.user.displayName}</p>
                <Rating
                  name="simple-controlled"
                  value={comment.point}
                  size="small"
                  className="mt-1"
                  readOnly
                />
                <p style={Style.commentText}>{comment.comment}</p>
                <p
                  style={{
                    ...Style.commentDate,
                    textAlign: "right",
                    position: "absolute",
                    right: 5,
                    bottom: 5,
                  }}
                >
                  {MomentConverter.formatDateDMY(
                    //@ts-ignore
                    comment.date.toDate(),
                    "D/MM/YYYY"
                  ) +
                    " " +
                    MomentConverter.getClockTime(comment.date)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          ...Style.formArea,
          position: "absolute",
          bottom: 0,
        }}
        className="d-flex"
      >
        {canComment ? (
          <>
            <div style={{ width: "90%" }}>
              <Rating
                name="simple-controlled"
                value={point != -1 ? point : 0}
                onChange={(event, newValue) => {
                  setPoint(newValue as number);
                }}
                className="mb-2"
                precision={0.5}
              />
              <textarea
                rows={row}
                style={Style.textArea}
                onChange={(inputText: any) => {
                  setComment(inputText.target.value);
                }}
                placeholder="Type..."
              ></textarea>
            </div>
            <button className="transparentButton" onClick={sendComment}>
              <img
                style={{ width: 32, height: 32 }}
                src={theme.ICONS.ic_send}
                alt="bildirr"
              />
            </button>
          </>
        ) : (
          <div className="d-flex align-items-center">
            <img
              style={{ width: 32, height: 32 }}
              src={theme.ICONS.ic_lock}
              alt="bildirr"
            />
            <p style={Style.commentText} className="ms-2">
              {24 - remainDays} {t("SearchScreen", "commentDeadLine")}
            </p>
          </div>
        )}
      </div>
    </>
  );
}
