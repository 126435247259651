import theme from '../../../constants/theme';
export const Style = ({
    container: {
        paddingTop: 25
    },
    logo: {
        height: 58,
        width: 48,
        alignSelf: 'center'
    },
    title: {
        color: 'white',
        marginTop: 30,
        fontSize: 32
    },
    form: {
        backgroundColor: theme.COLORS.containerColor,
        paddingInline: 24,
        paddingTop: 32,
        height: '100%'
    },
    label: {
        color: theme.COLORS.containerLabelColor
    },
    forgetPassword: {
        backgroundColor: 'transparent',
        padding: 13,
        borderColor: 'transparent',
        borderRadius: 12,
        color: theme.COLORS.containerTextColor,
        fontSize: 14
    },
    privacy: {
        backgroundColor: 'transparent',
        padding: 13,
        borderColor: 'transparent',
        borderRadius: 12,
        color: theme.COLORS.containerTextColor,
        fontSize: 13
    },
    haventAccount: {
        color: theme.COLORS.containerTextColor,
        fontSize: 14,
    },
    singUp: {
        color: theme.COLORS.blue,
        fontSize: 15
    }
});