export interface Lang {
  display: string;
  code: string;
  flag: string;
  isRTL: boolean;
}

export const defaultLang: Lang = {
  display: 'Türkçe',
  code: 'tr',
  flag: require('../assets/images/langs/tr.png'),
  isRTL: false,
};

export const langs: Lang[] = [
  {
    display: 'Türkçe',
    code: 'tr',
    flag: require('../assets/images/langs/tr.png'),
    isRTL: false,
  },
  {
    display: 'English',
    code: 'en',
    flag: require('../assets/images/langs/en.png'),
    isRTL: false,
  },
  {
    display: 'German',
    code: 'de',
    flag: require('../assets/images/langs/de.png'),
    isRTL: false,
  },
  {
    display: 'Russian',
    code: 'ru',
    flag: require('../assets/images/langs/ru.png'),
    isRTL: false,
  },
  {
    display: 'French',
    code: 'fr',
    flag: require('../assets/images/langs/fr.png'),
    isRTL: false,
  },
  {
    display: 'Greek',
    code: 'gr',
    flag: require('../assets/images/langs/gr.png'),
    isRTL: false,
  },
  {
    display: 'Arabic',
    code: 'sa',
    flag: require('../assets/images/langs/sa.png'),
    isRTL: true,
  },
];

export const getLangFile = (screen: string, lang: string) => {
  switch (screen) {
    // @ts-ignore
    case 'BusinessScreen':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/BusinessSreen/tr.json');
        case 'en':
          return require('../assets/translations/BusinessSreen/en.json');
        case 'de':
          return require('../assets/translations/BusinessSreen/de.json');
        case 'fr':
          return require('../assets/translations/BusinessSreen/fr.json');
        case 'gr':
          return require('../assets/translations/BusinessSreen/gr.json');
        case 'ru':
          return require('../assets/translations/BusinessSreen/ru.json');
        case 'sa':
          return require('../assets/translations/BusinessSreen/sa.json');
      }
    // @ts-ignore
    case 'NotificationsScreen':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/NotificationsScreen/tr.json');
        case 'en':
          return require('../assets/translations/NotificationsScreen/en.json');
        case 'de':
          return require('../assets/translations/NotificationsScreen/de.json');
        case 'fr':
          return require('../assets/translations/NotificationsScreen/fr.json');
        case 'gr':
          return require('../assets/translations/NotificationsScreen/gr.json');
        case 'ru':
          return require('../assets/translations/NotificationsScreen/ru.json');
        case 'sa':
          return require('../assets/translations/NotificationsScreen/sa.json');
      }
    // @ts-ignore
    case 'ProfileScreen':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/ProfileScreen/tr.json');
        case 'en':
          return require('../assets/translations/ProfileScreen/en.json');
        case 'de':
          return require('../assets/translations/ProfileScreen/de.json');
        case 'fr':
          return require('../assets/translations/ProfileScreen/fr.json');
        case 'gr':
          return require('../assets/translations/ProfileScreen/gr.json');
        case 'ru':
          return require('../assets/translations/ProfileScreen/ru.json');
        case 'sa':
          return require('../assets/translations/ProfileScreen/sa.json');
      }
    // @ts-ignore
    case 'QRCodeScreen':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/QRCodeScreen/tr.json');
        case 'en':
          return require('../assets/translations/QRCodeScreen/en.json');
        case 'de':
          return require('../assets/translations/QRCodeScreen/de.json');
        case 'fr':
          return require('../assets/translations/QRCodeScreen/fr.json');
        case 'gr':
          return require('../assets/translations/QRCodeScreen/gr.json');
        case 'ru':
          return require('../assets/translations/QRCodeScreen/ru.json');
        case 'sa':
          return require('../assets/translations/QRCodeScreen/sa.json');
      }
    // @ts-ignore
    case 'SearchScreen':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/SearchScreen/tr.json');
        case 'en':
          return require('../assets/translations/SearchScreen/en.json');
        case 'de':
          return require('../assets/translations/SearchScreen/de.json');
        case 'fr':
          return require('../assets/translations/SearchScreen/fr.json');
        case 'gr':
          return require('../assets/translations/SearchScreen/gr.json');
        case 'ru':
          return require('../assets/translations/SearchScreen/ru.json');
        case 'sa':
          return require('../assets/translations/SearchScreen/sa.json');
      }
    // @ts-ignore
    case 'MainScreen' ||
      'AboutScreen' ||
      'MyAccountScreen' ||
      'PrivacyPolicy' ||
      'ProfileInformationScreen' ||
      'SettingScreen':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/MainScreen/tr.json');
        case 'en':
          return require('../assets/translations/MainScreen/en.json');
        case 'de':
          return require('../assets/translations/MainScreen/de.json');
        case 'fr':
          return require('../assets/translations/MainScreen/fr.json');
        case 'gr':
          return require('../assets/translations/MainScreen/gr.json');
        case 'ru':
          return require('../assets/translations/MainScreen/ru.json');
        case 'sa':
          return require('../assets/translations/MainScreen/sa.json');
      }
    // @ts-ignore
    case 'BottomBar':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/BottomBar/tr.json');
        case 'en':
          return require('../assets/translations/BottomBar/en.json');
        case 'de':
          return require('../assets/translations/BottomBar/de.json');
        case 'fr':
          return require('../assets/translations/BottomBar/fr.json');
        case 'gr':
          return require('../assets/translations/BottomBar/gr.json');
        case 'ru':
          return require('../assets/translations/BottomBar/ru.json');
        case 'sa':
          return require('../assets/translations/BottomBar/sa.json');
      }
    // @ts-ignore
    case 'AutenticationScreen':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/AutenticationScreen/tr.json');
        case 'en':
          return require('../assets/translations/AutenticationScreen/en.json');
        case 'de':
          return require('../assets/translations/AutenticationScreen/de.json');
        case 'fr':
          return require('../assets/translations/AutenticationScreen/fr.json');
        case 'gr':
          return require('../assets/translations/AutenticationScreen/gr.json');
        case 'ru':
          return require('../assets/translations/AutenticationScreen/ru.json');
        case 'sa':
          return require('../assets/translations/AutenticationScreen/sa.json');
      }
    // @ts-ignore
    case 'SystemMessages':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/SystemMessages/tr.json');
        case 'en':
          return require('../assets/translations/SystemMessages/en.json');
        case 'de':
          return require('../assets/translations/SystemMessages/de.json');
        case 'fr':
          return require('../assets/translations/SystemMessages/fr.json');
        case 'gr':
          return require('../assets/translations/SystemMessages/gr.json');
        case 'ru':
          return require('../assets/translations/SystemMessages/ru.json');
        case 'sa':
          return require('../assets/translations/SystemMessages/sa.json');
      }
    // @ts-ignore
    case 'TutorialScreen':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/TutorialScreen/tr.json');
        case 'en':
          return require('../assets/translations/TutorialScreen/en.json');
        case 'de':
          return require('../assets/translations/TutorialScreen/de.json');
        case 'fr':
          return require('../assets/translations/TutorialScreen/fr.json');
        case 'gr':
          return require('../assets/translations/TutorialScreen/gr.json');
        case 'ru':
          return require('../assets/translations/TutorialScreen/ru.json');
        case 'sa':
          return require('../assets/translations/TutorialScreen/sa.json');
      }
    case 'SharedComponents':
      switch (lang) {
        case 'tr':
          return require('../assets/translations/SharedComponents/tr.json');
        case 'en':
          return require('../assets/translations/SharedComponents/en.json');
        case 'de':
          return require('../assets/translations/SharedComponents/de.json');
        case 'fr':
          return require('../assets/translations/SharedComponents/fr.json');
        case 'gr':
          return require('../assets/translations/SharedComponents/gr.json');
        case 'ru':
          return require('../assets/translations/SharedComponents/ru.json');
        case 'sa':
          return require('../assets/translations/SharedComponents/sa.json');
      }
  }
};
