import { PathModel } from "../api/model/PathModel";
import { LoadingSwither } from "../utils/loading-switcher";
import { FireStoreService } from "./firestore.service";
import { ResponseModel } from "./response.model";
export type WhereFilterOp =
    | '<'
    | '<='
    | '=='
    | '>'
    | '>='
    | '!='
    | 'array-contains'
    | 'array-contains-any'
    | 'in'
    | 'not-in';
export type HTTPMethod = 'get' | 'add' | 'update' | 'delete';
export type ConditionModel = { field: string, filter: WhereFilterOp, value: string | number | boolean | Date | any[]}
export class FireStoreRequest<T> {

    private conditions = [] as ConditionModel[];
    private body = {}
    private limit = 0
    private orderBy = ''
    private directionStr = ''
    private startAt: number | string | undefined;
    private endAt: number | string | undefined;
    private startAfter: number | string | undefined;
    private endBefore: number | string | undefined;
    private isLoadingDisabled = false;

    private id = '';
    public constructor(
        private method: HTTPMethod,
        private path: PathModel[],
        private fireStoreService: FireStoreService,
    ) { }

    public setCondition<U>(conditionList: ConditionModel[]): this {
        this.conditions = conditionList
        return this;
    }

    public setBody<U>(doc: {}): this {
        this.body = doc
        return this;
    }

    public setId<U>(id: string): this {
        this.id = id
        return this;
    }

    public setLimit<U>(limit: number): this {
        this.limit = limit
        return this;
    }

    public setStartAt<U>(startAt: number | string): this {
        this.startAt = startAt
        return this;
    }

    public setEndAt<U>(endAt: number | string): this {
        this.endAt = endAt
        return this;
    }

    public setStartAfter<U>(startAfter: number | string): this {
        this.startAfter = startAfter
        return this;
    }

    public setEndBefore<U>(endBefore: number | string): this {
        this.endBefore = endBefore
        return this;
    }

    public setOrderBy<U>(orderBy: string, directionStr?: string): this {
        this.orderBy = orderBy
        this.directionStr = directionStr as any;
        return this;
    }

    public setLoadingDisabled<U>(value: boolean): this {
        this.isLoadingDisabled = value;
        return this;
    }

    public getPath(): PathModel[] {
        return this.path;
    }

    public getMethod(): string {
        return this.method;
    }

    public getCondition(): ConditionModel[] {
        return this.conditions
    }

    public getBody<U>(): {} {
        return this.body
    }

    public getId<U>(): {} {
        return this.id
    }

    public getLimit<U>(): {} {
        return this.limit
    }

    public getOrderBy<U>(): {} {
        const model = {
            fieldPath: this.orderBy,
            directionStr: this.directionStr === '' ? 'asc' : this.directionStr
        }
        return model
    }

    public getStartAt<U>() {
        return this.startAt
    }

    public getEndAt<U>() {
        return this.endAt
    }

    public getStartAfter<U>() {
        return this.startAfter
    }

    public getEndBefore<U>() {
        return this.endBefore
    }

    public getLoadingDisabled<U>() {
        return this.isLoadingDisabled
    }

    public execute(): Promise<ResponseModel<T>> {
        //LoadingSwither.ShowLoading()
        return this.fireStoreService.doApiCall(this).then(response => {
            //LoadingSwither.HideLoading()
            return new ResponseModel(
                response as T
            );
        });
    }
}