import { UserModel } from '../api/model/UserModel';
import { Lang } from '../constants/langs';

export class StorageService {
    public static setUserModel(model: UserModel) {
        return localStorage.setItem('user', JSON.stringify(model));
    }

    public static getUserModel(): Promise<any> {
        return JSON.parse(localStorage.getItem('user') as any);
    }

    public static removeUserModel() {
        return localStorage.removeItem('user')
    }

    public static setLang(model: Lang){
        return localStorage.setItem('lang', JSON.stringify(model));
    }

    public static getLang(): Promise<Lang> {
        return JSON.parse(localStorage.getItem('lang') as any);
    }

    public static setSawTutorial() {
        return localStorage.setItem('tutorial', JSON.stringify(true));
    }

    public static getSawTutorial(): Promise<any> {
        return JSON.parse(localStorage.getItem('tutorial') as any);
    }
}