import React, { useEffect, useState } from 'react';
import { Props } from './types';
import theme from '../../../../constants/theme';
import { useAuth } from '../../../../context/Auth/authContext';
import { DataFireStoreService } from '../../../../api/services/data-firestore.service';
import { BildirimModel, BildirimModelList } from '../../../../api/model/BildirimModel';
import { BildirCardComponent } from '../../../../components/BildirCardComponent';
import { BildirimStates } from '../../../../constants/bildirimStates';
import { typeModel } from '../../../../constants/bildirimTypes';
import { BackButtonComponent } from '../../../../components/Shared/BackButtonComponent';
import { SearchComponent } from '../../../../components/Shared/SearchComponent';
import { Style } from './style';
import { useLocation } from 'react-router-dom';
export function BildirsByBusinessScreenComponent({ }: Props) {
  const { user } = useAuth();
  const { state } = useLocation();

  const [bildirims, setBildirims] = useState([] as BildirimModel[]);
  const [page, setPage] = useState(0);
  // @ts-ignore
  const business = state.Business as BusinessModel;
  // @ts-ignore
  const unit = state.Units as UnitsModel;
  console.log(state)
  function getNormalUserBildirims(
    index: number,
    search?: string,
    types?: typeModel[],
    states?: BildirimStates[],
  ) {
    DataFireStoreService.getNormalUserBildirims(
      user?.uid as any,
      index,
      search as any,
      types,
      states,
    ).then(res => {
      const filtered = res.filter(item => (item.business?.id === business.id && item.units.id === unit.id))
      setBildirims(filtered)
      //setBildirims(res);
    });
  }

  useEffect(() => {
    getNormalUserBildirims(page);
  }, []);

    const imageUrl = business.imageURL ? business.imageURL : business.image
  return (
    <>
        <div style={{
            backgroundImage: "url(" + imageUrl + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: 150,
            width: '100%'
        }}>
            <div className='d-flex' style={{
                backgroundColor: theme.COLORS.containerColor,
                opacity: 0.8,
                height: 40,
                alignItems:'center'
            }}>
                <BackButtonComponent />
                <p className='fontMediumItalic text-center' style={{
                    color:'#fff',
                    fontSize:18,
                    flex:1,
                }}>
                    {business.title + ' ' + unit.title}
                </p>
            </div>
        </div>


      <div className='px-3 mt-3'>
      </div>
      <div style={{ paddingInline: 20 }}>
        <div style={{ marginTop: 26, }}>
          {bildirims.map((data: BildirimModel, index: number) => {
            return (
              <div key={index} style={{ marginTop: 14 }}>
                <BildirCardComponent bildirim={data} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
