import { connect } from 'react-redux';
import { GlobalState } from "../../../store/state";
import { TranslationService } from '../../../services/translation.service';
import theme from "../../../constants/theme";
import { BlueButtonComponent } from "../BlueButtonComponent";
import { ModalSwither } from "../../../utils/modal-switcher";
import { Modal } from 'react-bootstrap';
const Component = (props: any) => {
    const t = TranslationService.translate;
    const img = require('../../../assets/images/ic_close.png')
    const logo = require('../../../assets/images/logo.png')
    return (
        <>
            {
                props.show &&
                <Modal
                    show={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div style={{ backgroundColor: theme.COLORS.screenBgColor }}>
                        <div className='text-center' style={{ marginTop: 26 }}>
                            <img src={logo} style={{ height: 58, width: 48 }} />
                        </div>
                        <div className='text-center' style={{ marginTop: 26 }}>
                            <img src={img} style={{ height: 58, width: 48 }} />
                            <p style={{ marginTop: 10, color: '#fff', fontSize: 16 }}>{props.error}</p>
                        </div>
                        <div className='my-3 text-center mx-5'>
                            <BlueButtonComponent
                                text='TAMAM'
                                onClick={() => {
                                    ModalSwither.HideErrorModal()
                                }}
                            />
                        </div>
                    </div>
                </Modal>

            }
        </>

    )
}


const mapStateToProps = (state: GlobalState) => {
    return {
        show: state.ui.errorModal,
        error: state.ui.error
    };
};

const mapDispatchToProps = () => {
    return {};
};

export const ErrorModal = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);