import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BottomTabComponent } from "../../components/BottomTabComponents/component";
import { MainScreenNavigator } from "./Main";
import { SearchScreenNavigator } from "./Search";
import { ProfileScreenNavigator } from "./Profile";
import { NotificationScreenNavigator } from "./Notification";

export function HomeRouter() {
    
    const notFound = () => {
        return (
            <h2>BÖYLE BİR SAYFA HİÇ OLMADI</h2>
        )
    }

    return (
        <BrowserRouter>
            <div className={"container-fluids body-content"}>
                <Routes>
                    {
                        MainScreenNavigator.map(function (route, index) {
                            return <Route
                                key={index}
                                path={route.path}
                                element={route.screen}
                            />
                        })
                    }
                    {
                        SearchScreenNavigator.map(function (route, index) {
                            return <Route
                                key={index}
                                path={route.path}
                                element={route.screen}
                            />
                        })
                    }
                    {
                        ProfileScreenNavigator.map(function (route, index) {
                            return <Route
                                key={index}
                                path={route.path}
                                element={route.screen}
                            />
                        })
                    }
                    {
                        NotificationScreenNavigator.map(function (route, index) {
                            return <Route
                                key={index}
                                path={route.path}
                                element={route.screen}
                            />
                        })
                    }
                    <Route
                        path='*'
                        element={notFound()}
                    />
                </Routes>
            </div>

            <div className="footer">
                <BottomTabComponent />
            </div>
        </BrowserRouter>
    )
}