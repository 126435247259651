import { Style } from "./style";
import { TranslationService } from "../../../../services/translation.service";
import theme from "../../../../constants/theme";
import { BusinessModel } from "../../../../api/model/BusinessModel";
import { useLocation } from "react-router-dom";
import { UnitsModel } from "../../../../api/model/UnitModel";
import { BackButtonComponent } from "../../../../components/Shared/BackButtonComponent";
import { useNavigate } from "react-router-dom";
import { DataFireStoreService } from "../../../../api/services/data-firestore.service";
import { useAuth } from "../../../../context/Auth/authContext";
import { ModalSwither } from "../../../../utils/modal-switcher";
import { useEffect, useState } from "react";
interface MenuItem {
  text: string;
  icon: any;
  onClick: Function;
}
export function TransactionsScreenComponent() {
  const { user } = useAuth();
  const { state } = useLocation();
  const [follow, setFollow] = useState(false);
  const [list, setList] = useState([] as MenuItem[]);
  const [isLock, setIsLock] = useState<boolean>(false);

  function getUnit() {
    DataFireStoreService.getUnit(
      //@ts-ignore
      state.UserId,
      //@ts-ignore
      state.Business.id,
      //@ts-ignore
      state.Units.id
    ).then(async (res) => {
      //@ts-ignore
      res.subscribedUsers.indexOf(user?.uid) === -1
        ? setFollow(false)
        : setFollow(true);
    });
  }
  const navigation = useNavigate();
  const t = TranslationService.translate;
  // @ts-ignore
  const business = state.Business as BusinessModel;
  // @ts-ignore
  const unit = state.Units as UnitsModel;
  const subcribe = () => {
    if (!user) {
      ModalSwither.ShowLoginStackModal();
      return;
    }
    DataFireStoreService.subscribe(
      // @ts-ignore
      state.UserId,
      business.id,
      unit.id,
      user?.uid
    ).then((res) => {
      res && ModalSwither.ShowSuccessModal(t("SearchScreen", "success"));
      getUnit();
    });
  };

  const unSubcribe = () => {
    if (!user) {
      ModalSwither.ShowLoginStackModal();
      return;
    }
    DataFireStoreService.unSubscribe(
      // @ts-ignore
      state.UserId,
      business.id,
      unit.id,
      user?.uid
    ).then((res) => {
      res && ModalSwither.ShowSuccessModal(t("SearchScreen", "success"));
      getUnit();
    });
  };

  useEffect(() => {
    if (business.pinCodeRequired) {
      setIsLock(true);
    }
    getUnit();
  }, []);

  useEffect(() => {
    const menuList = [] as MenuItem[];
    const model = {
      icon: theme.ICONS.ic_bildir,
      text: t("SearchScreen", "bildir"),
      onClick: () => {
        navigation("/services", {
          state: {
            Business: business,
            Units: unit,
            // @ts-ignore
            UserId: state.UserId,
            header: business.title + " " + unit.title,
          },
        });
      },
    };
    menuList.push(model);
    if (unit.menuPrivate) {
      //@ts-ignore
      if (state.fromQRCode) {
        unit.hasMenu &&
          menuList.push({
            icon: theme.ICONS.ic_menu,
            text: t("SearchScreen", "menu"),
            onClick: () => {
              window.open(
                ("https://menu.bildirr.com/" + unit.id) as string,
                "_blank"
              );
            },
          });
      }
    } else {
      unit.hasMenu &&
        menuList.push({
          icon: theme.ICONS.ic_menu,
          text: t("SearchScreen", "menu"),
          onClick: () => {
            window.open(
              ("https://menu.bildirr.com/" + unit.id) as string,
              "_blank"
            );
          },
        });
    }

    unit.hasAnyAnket &&
      menuList.push({
        icon: theme.ICONS.ic_survey,
        text: t("SearchScreen", "survey"),
        onClick: () => {
          navigation("/anketList", {
            state: {
              Business: business,
              UnitsId: unit.id,
              // @ts-ignore
              UserId: state.UserId as any,
              header: business.title + " " + unit.title,
            },
          });
        },
      });
    if (unit.callWaiterPrivate) {
      //@ts-ignore
      if (state.fromQRCode) {
        menuList.push({
          icon: theme.ICONS.ic_call_waiter,
          text: t("SearchScreen", "callWaiter"),
          onClick: hrefWaiterRequest,
        });
      }
    } else {
      unit.hasCallWaiterProperty &&
        menuList.push({
          icon: theme.ICONS.ic_call_waiter,
          text: t("SearchScreen", "callWaiter"),
          onClick: hrefWaiterRequest,
        });
    }

    DataFireStoreService.getUnitActionMenus(
      // @ts-ignore
      state.UserId,
      // @ts-ignore
      state.Business.id,
      // @ts-ignore
      state.Units.id
    ).then((res) => {
      res.forEach((element) => {
        console.log(element);
        menuList.push({
          icon: element.imageURL,
          text: element.title,
          onClick: () => {
            window.open(element.url as string, "_blank");
          },
        });
      });
      setList(menuList);
    });
  }, []);

  const hrefWaiterRequest = () => {
    navigation("/callWaiterRequest", {
      state: {
        Business: business,
        Units: unit,
      },
    });
  };

  const imageUrl = business.imageURL ? business.imageURL : business.image;

  const _renderMenu = (model: MenuItem, index: number) => {
    return (
      <div className="mt-3 lineShadow" key={"container_" + index}>
        <button
          className="transparentButton d-flex flex-row"
          style={theme.STYLE.lineStyle}
          onClick={() => {
            model.onClick();
          }}
        >
          <img style={Style.img} src={model.icon} alt="bildirr" />
          <p
            className="fontMediumItalic"
            style={Style.unit}
            ref={(el) => {
              if (el) {
                el.style.setProperty("margin-left", "12px", "important");
              }
            }}
          >
            {model.text}
          </p>
        </button>
      </div>
    );
  };

  const _renderItem = (
    Function: Function,
    icon: any,
    text?: string,
    blueBack = false
  ) => {
    return (
      <button
        style={{
          ...Style.topItemContainer,
          backgroundColor: blueBack
            ? theme.COLORS.blue
            : theme.COLORS.componentBgColor,
        }}
        className="transparentButton unitItemContainer"
        onClick={() => {
          Function();
        }}
      >
        <img
          className="tintColorWhite"
          style={{ ...Style.topIcons }}
          src={icon}
          alt="bildirr"
        />
        <p style={Style.menuText} className="text-center mt-1">
          {text}
        </p>
      </button>
    );
  };

  return (
    <>
      {isLock ? (
        <>
          <div
            style={{
              backgroundImage: "url(" + imageUrl + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: 150,
              width: "100%",
            }}
          >
            <div
              className="d-flex"
              style={{
                backgroundColor: theme.COLORS.containerColor,
                opacity: 0.8,
                height: 40,
                alignItems: "center",
              }}
            >
              <BackButtonComponent
                goBack={
                  //@ts-ignore
                  state.goBack ? state.goBack : undefined
                }
              />
              <p
                className="fontMediumItalic text-center"
                style={{
                  color: "#fff",
                  fontSize: 18,
                  flex: 1,
                }}
              >
                {business.title + " " + unit.title}
              </p>
            </div>
          </div>
          <div className="text-center mt-5">
            <img src={theme.ICONS.ic_lock} style={{ height: 64, width: 64 }} />
            <p className=" p-5 fontItalic" style={{ color: "#fff" }}>
              Bu işletmeye sadece mobil uygulamamız üzerinden erişebilirsiniz.
            </p>
            <div className="d-flex p-4 justify-content-between">
              <img
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/bildirr/id1632586264" as string,
                    "_blank"
                  )
                }
                src="https://bildirr.com/assets/img/appstore_button.png"
                style={{ height: 64, width: 148 }}
              />
              <img
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.dmrsoft.bildir" as string,
                    "_blank"
                  )
                }
                src="https://bildirr.com/assets/img/playstore_button.png"
                style={{ height: 64, width: 148 }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              backgroundImage: "url(" + imageUrl + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: 150,
              width: "100%",
              overflowY: "scroll",
            }}
          >
            <div
              className="d-flex"
              style={{
                backgroundColor: theme.COLORS.containerColor,
                opacity: 0.8,
                height: 40,
                alignItems: "center",
              }}
            >
              <BackButtonComponent
                goBack={
                  //@ts-ignore
                  state.goBack ? state.goBack : undefined
                }
              />
              <p
                className="fontMediumItalic text-center"
                style={{
                  color: "#fff",
                  fontSize: 18,
                  flex: 1,
                }}
              >
                {business.title + " " + unit.title}
              </p>
            </div>
          </div>
          <div className="px-3 mt-4">
            <div className="d-flex justify-content-between mt-3">
              {_renderItem(
                () => {
                  navigation("/InformationOfUnit", {
                    state: {
                      description: unit.details?.htmlBody,
                      unit: unit,
                      business: business,
                    },
                  });
                },
                theme.ICONS.ic_info,
                t("SearchScreen", "info")
              )}
              {_renderItem(
                () => {
                  navigation("/Campaigns", {
                    state: {
                      unit: unit,
                      business: business,
                    },
                  });
                },
                theme.ICONS.ic_campaing,
                t("SearchScreen", "campaign")
              )}
              {_renderItem(
                () => {
                  navigation("/comments", {
                    state: {
                      unit: unit,
                      business: business,
                    },
                  });
                },
                theme.ICONS.ic_comment,
                t("SearchScreen", "comments")
              )}
              {follow
                ? _renderItem(
                    unSubcribe,
                    theme.ICONS.ic_follow,
                    t("SearchScreen", "unSubscribe"),
                    true
                  )
                : _renderItem(
                    subcribe,
                    theme.ICONS.ic_follow,
                    t("SearchScreen", "subscribe")
                  )}
              {/* {_renderItem(
            () => {
              if (!user) {
                ModalSwither.ShowLoginStackModal();
                return;
              }
              navigation("/BildirByBusiness", {
                state: { Business: business, Units: unit },
              });
            },
            theme.ICONS.ic_message,
            ""
          )} */}
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className="line" />
              <p className="fontMediumItalic" style={Style.transactions}>
                {t("SearchScreen", "transactions")}
              </p>
              <div className="line" />
            </div>
            <div className="row">
              {list.map((data, index) => {
                return _renderMenu(data, index);
              })}
            </div>
          </div>
          <div style={{ height: 50 }}></div>
        </>
      )}
    </>
  );
}
