import theme from "./theme"

export type BildirimTypes = 'TEŞEKKÜR' | 'ŞİKAYET' | 'ÖNERİ'

export const getTypeBackgroundColor = (type: BildirimTypes) => {
    switch (type) {
        case 'TEŞEKKÜR':
            return theme.COLORS.green
        case 'ŞİKAYET':
            return theme.COLORS.red
        case 'ÖNERİ':
            return theme.COLORS.yellow
    }
}
export interface typeModel {
    _id:number,
    text:string,
    color:string,
    selected:boolean
}
export const bildirTypeList : typeModel[] = ([
    {
        _id: 0,
        text: 'thanks',
        color: getTypeBackgroundColor('TEŞEKKÜR'),
        selected: true
    },
    {
        _id: 1,
        text: 'complaint',
        color: getTypeBackgroundColor('ŞİKAYET'),
        selected: false
    },
    {
        _id: 2,
        text: 'suggestion',
        color: getTypeBackgroundColor('ÖNERİ'),
        selected: false
    }
])