import React, { } from "react";
import { Style } from "./style";
import { Props } from "./types";
import theme from "../../constants/theme";
import { TranslationService } from "../../services/translation.service";
import { Modal } from 'react-bootstrap';

export function ImageViewerModalComponent(props: Props) {
    const t = TranslationService.translate;
    console.log(props.imageUri)
    function closeModal() {
        props.close()
    }

    const _closeButtonImage = () => {
        return (
            <button
                className="transparentButton"
                style={{ position: 'absolute', top: 30, right: 20 }}
                onClick={closeModal}>
                <img src={theme.ICONS.ic_delete_white} style={{ height: 24, width: 24 }} />
            </button>
        )
    }
    const _renderImage = (uri: string) => {
        return (
            <img
                style={{ height: '80%', width: '100%', alignSelf: 'center' }}
                src={uri}
            />
        )
    }
    function renderItem(data: any) {
        return (
            <div style={{ width: '100%', position: 'relative' }}>
                {_renderImage(data.uri)}
                {_closeButtonImage()}
                {
                    props.readyToSend &&
                    <div className="d-flex" style={Style.chatContainer}>
                        <input
                            style={{
                                paddingInline: 10,
                                flex: 1
                            }}
                            value={props.text}
                            placeholder={t('SharedComponents', 'placeholder1')}
                            onChange={(value) => {
                                props.setText && props.setText(value.target.value)
                            }}
                        />
                        <div style={theme.STYLE.verticalLine}></div>
                        <button
                            className="transparentButton"
                            style={{ marginLeft: 10, marginRight: 10 }}
                            onClick={() => {
                                props.sendMessage && props.sendMessage();
                                props.close();
                            }}>
                            <img src={theme.ICONS.ic_send} style={{ height: 22, width: 22 }} />
                        </button >
                    </div>
                }
            </div>
        );
    }

    return (
        <Modal
            show={true}
            centered
            aria-labelledby="contained-modal-title-center"
        >
            <div style={{ paddingBlock: 10 }}>
                <div style={Style.container}>
                    {
                        props.imageUri.map(function (data, index) {
                            return renderItem(data)
                        })
                    }
                </div>
            </div>

        </Modal>
    )
}
