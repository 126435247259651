import {MenuItems} from '../models/MenuItems';

export enum UserRoles {
  USER = 'user',
  ADMIN = 'admin', //business
}

export const getMenuItems = (length: number) => {
  switch (length) {
    case 1:
      return menuUSER;
    case 2:
      return menuADMIN;
    default:
      return menuADMIN;
  }
};

const menuUSER: MenuItems[] = [
  {
    name: 'Ana Sayfa',
    navigation: 'Home',
    activeIcon: require('../assets/images/bottombar/active/home.png'),
    passiveIcon: require('../assets/images/bottombar/passive/home.png'),
  },
  {
    name: 'Arama',
    navigation: 'Search',
    activeIcon: require('../assets/images/bottombar/active/search.png'),
    passiveIcon: require('../assets/images/bottombar/passive/search.png'),
  },
  {
    name: 'QR TARA',
    navigation: 'QRCode',
    activeIcon: require('../assets/images/bottombar/active/qr.png'),
    passiveIcon: require('../assets/images/bottombar/passive/qr.png'),
  },
  {
    name: 'Bildirimler',
    navigation: 'Notifications',
    activeIcon: require('../assets/images/bottombar/active/notification.png'),
    passiveIcon: require('../assets/images/bottombar/passive/notification.png'),
  },
  {
    name: 'Hesabım',
    navigation: 'Profile',
    activeIcon: require('../assets/images/bottombar/active/profile.png'),
    passiveIcon: require('../assets/images/bottombar/passive/profile.png'),
  },
];

const menuADMIN: MenuItems[] = [
  {
    name: 'Ana Sayfa',
    navigation: 'Home',
    activeIcon: require('../assets/images/bottombar/active/home.png'),
    passiveIcon: require('../assets/images/bottombar/passive/home.png'),
  },
  {
    name: 'Arama',
    navigation: 'Search',
    activeIcon: require('../assets/images/bottombar/active/search.png'),
    passiveIcon: require('../assets/images/bottombar/passive/search.png'),
  },
  {
    name: 'QR TARA',
    navigation: 'QRCode',
    activeIcon: require('../assets/images/bottombar/active/qr.png'),
    passiveIcon: require('../assets/images/bottombar/passive/qr.png'),
  },
  {
    name: 'Bildirimler',
    navigation: 'Notifications',
    activeIcon: require('../assets/images/bottombar/active/notification.png'),
    passiveIcon: require('../assets/images/bottombar/passive/notification.png'),
  },
  {
    name: 'Business',
    navigation: 'MyAccount',
    activeIcon: require('../assets/images/bottombar/active/business.png'),
    passiveIcon: require('../assets/images/bottombar/passive/business.png'),
  },
];

export const GENDERs = [
  {label: 'ERKEK', value: true},
  {label: 'KADIN', value: false},
];
