import { errorModalData, showErrorModal, showSuccessModal, successModalData, showLoginStackModal, setLoginStackModalOnClick } from '../store/ui/actions'
import { store } from '../store';
import { UserModel } from '../api/model/UserModel';
export class ModalSwither {

    static HideErrorModal = (): boolean => {
        store.dispatch(showErrorModal(false) as any)
        return false
    };

    static ShowErrorModal = (error: string): boolean => {
        store.dispatch(errorModalData(error) as any)
        store.dispatch(showErrorModal(true) as any)
        return true
    };

    static HideSuccessModal = (): boolean => {
        store.dispatch(showSuccessModal(false) as any)
        return false
    };

    static ShowSuccessModal = (message: string): boolean => {
        store.dispatch(successModalData(message) as any)
        store.dispatch(showSuccessModal(true) as any)
        return true
    };

    static ShowLoginStackModal = (): boolean => {
        store.dispatch(showLoginStackModal(true) as any)
        return false
    };

    static HideLoginStackModal = (): boolean => {
        //@ts-ignore
        store.dispatch(setLoginStackModalOnClick(() => { }) as any)
        store.dispatch(showLoginStackModal(false) as any)
        return false
    };

}