import { DataFireStoreService } from "../../../api/services/data-firestore.service";
import { useEffect, useState } from "react";
import { Props } from "./types";
import { Style } from "./style";
import theme from "../../../constants/theme";
import { SearchComponent } from "../../../components/Shared/SearchComponent";
import { BusinessModel } from "../../../api/model/BusinessModel";
import { useNavigate } from "react-router-dom";
import { fireStore } from "../../../services/firebase";
import { UnitsModel } from "../../../api/model/UnitModel";

export function SearchScreenComponent({}: Props) {
  const navigation = useNavigate();
  const [index, setIndex] = useState(0);
  const [business, setBusiness] = useState([] as BusinessModel[]);
  const [search, setSearch] = useState("");
  const [units, setUnits] = useState([] as UnitsModel[]);

  function getBusinessData(index: number, search?: string) {
    console.log("getBusinessData", index, search);
    DataFireStoreService.getBusinessesData(index, search).then((res) => {
      setBusiness(res);
      console.log("res: ", res);
    });
  }
  function callSearch() {
    getBusinessData(0, search);
  }

  function clearSearch() {
    setSearch("");
    setUnits([]);
    setBusiness([]);
  }

  useEffect(() => {
    if (search.length > 2) {
      getBusinessData(index, search);
    }
  }, [index, search]);

  useEffect(() => {
    if (search.length > 2) {
      fireStore
        .collectionGroup("Units")
        .where("searchKeywords", "array-contains", search.toLowerCase())
        .get()
        .then((res) => {
          const list = [] as any;
          res.docs.forEach((element) => {
            list.push(element.data());
          });

          setUnits(list as any);
        });
    }
  }, [search]);

  const _renderBusinessAndUnitCard = (
    onPress: Function,
    imageURL: string,
    title = "",
    type = "",
    city = "",
    district = ""
  ) => {
    return (
      <button
        className="transparentButton mt-3 d-flex"
        style={theme.STYLE.lineStyle}
        key={index}
        onClick={() => {
          onPress();
        }}
      >
        <img style={Style.img} src={imageURL} alt="bildir" />
        <div className="ms-3 text-start">
          <p className="fontBold " style={Style.header}>
            {title}
          </p>
          <p className="fontMedium" style={Style.type}>
            {type}
          </p>
          <div className="mt-1 d-flex align-items-end">
            <p className="fontBold" style={Style.city}>
              {city}
            </p>
            <p className="fontMedium ms-2" style={Style.district}>
              {district}
            </p>
          </div>
        </div>
      </button>
    );
  };
  return (
    <>
      <div className="text-center" style={{ padding: 20 }}>
        <img style={Style.logoContainer} src={theme.ICONS.logo} alt="bildir" />
        <div style={{ marginTop: 20 }}>
          <SearchComponent
            callSearch={callSearch}
            clearSearch={clearSearch}
            value={search}
            listener={setSearch}
          />
        </div>
        {business.map(function (data, index) {
          const unitler = units.filter(
            (item) => item.businessID == data.id
          ) as UnitsModel[];
          const onPress = () => {
            navigation("/units", {
              state: {
                UserId: data.user.uid,
                Business: data,
              },
            });
          };
          return (
            <>
              {_renderBusinessAndUnitCard(
                onPress,
                data.imageURL as string,
                data.title,
                data.type,
                data.city,
                data.district
              )}
              {unitler.map(function (unit, index) {
                const onPress = () => {
                  DataFireStoreService.getBusinessByBusinessId(
                    unit.businessID
                  ).then((res) => {
                    navigation("/transactions", {
                      state: {
                        UserId: res.user.uid,
                        Business: res,
                        Units: unit,
                        disableLoading: true,
                      },
                    });
                  });
                };
                return _renderBusinessAndUnitCard(
                  onPress,
                  unit.imageURL as string,
                  unit.title,
                  "",
                  unit.details?.city,
                  unit.details?.district
                );
              })}
            </>
          );
        })}
        {units.map(function (unit, index) {
          const isAlreadyRendered = business.filter(
            (item) => item.id === unit.businessID
          );
          const onPress = () => {
            DataFireStoreService.getBusinessByBusinessId(unit.businessID).then(
              (res) => {
                navigation("/transactions", {
                  state: {
                    UserId: res.user.uid,
                    Business: res,
                    Units: unit,
                    disableLoading: true,
                  },
                });
              }
            );
          };
          if (isAlreadyRendered.length == 0)
            return _renderBusinessAndUnitCard(
              onPress,
              unit.imageURL as string,
              unit.title,
              "",
              unit.details?.city,
              unit.details?.district
            );
        })}
      </div>
    </>
  );
}
