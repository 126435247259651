import theme from '../../../constants/theme';
export const Style = ({
    header: {
        backgroundColor: theme.COLORS.componentBgColor,
        paddingBlock: 10,
        paddingInline: 0,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    userDetailName: {
        color: '#fff',
        fontSize: 21,
        marginLeft: 10
    },
    dropdown: {
        padding: 10,
        backgroundColor: theme.COLORS.containerColor,
        width: '40%',
        position: 'absolute',
        right: 0,
        top: 50,
        zIndex: 5
    },
    dropdownText: {
        color: '#fff',
        fontSize: 14,
    },
    container: {
        padding: 10
    }
});
