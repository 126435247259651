import theme from "../../../../constants/theme";
export const Style = {
  headerContainer: {
    backgroundColor: theme.COLORS.containerColor,
    paddingVertical: 10,
  },
  header: {
    color: "#fff",
    fontSize: 18,
  },
  topIcons: {
    height: 26,
    width: 26,
  },
  transactions: {
    fontSize: 12,
    color: theme.COLORS.containerTextColor,
  },
  img: {
    height: 48,
    width: 48,
    borderRadius: 8,
  },
  unit: {
    color: "#fff",
    fontSize: 18,
  },
  topItemContainer: {
    alignItems: "center",
    paddingBlock: 10,
    backgroundColor: theme.COLORS.componentBgColor,
    borderColor: "#fff",
    borderWidth: 1,
    borderRadius: 10,
    width: 80,
  },
  menuText: {
    fontSize: 12,
    color: "#fff",
    marginTop: 5,
  },
};
