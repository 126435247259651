import React, { } from 'react';
import theme from '../../../constants/theme';
import { Props } from './types';
import { useNavigate } from 'react-router-dom';
import { Style } from './style';
export function BackButtonComponent(props: Props) {
    const navigation = useNavigate()
    return (
        <button className='transparentButton' onClick={() => navigation(
            //@ts-ignore
            props.goBack ? props.goBack : -1
        )}>
            <img style={Style.img} src={theme.ICONS.ic_arrow_left} />
        </button>
    )
}