import { Style } from './style';
import theme from '../../../../constants/theme';

export function AboutScreenComponent() {
  return (
    <div className='text-center' style={Style.container}>
      <img src={require('../../../../assets/images/logo.png')} style={{ height: 58, width: 48 }} />
      <p className='fontMediumItalic mt-3' style={Style.infoText}>
        Bildir
      </p>
      <p className='fontMediumItalic mt-3' style={Style.infoText}>
        v1.0
      </p>
      <div style={{ color: theme.COLORS.containerLabelColor }}>
        <p className='fontMediumItalic' style={Style.infoText}>
          DMR - SOFT
        </p>
      </div>
    </div >
  );
}
