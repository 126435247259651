import theme from '../../../constants/theme';
export const Style = ({
    logoContainer: {
        width: 37,
        height: 44,
        marginTop: 5
    },
    img: {
        height: 68,
        width: 68,
        borderRadius: 8
    },
    header: {
        fontSize: 14,
        color: '#fff',
    },
    type: {
        color: theme.COLORS.containerTextColor,
        fontSize: 12,
        marginTop: 7
    },
    city: {
        color: theme.COLORS.blue,
        fontSize: 16
    },
    district: {
        color: theme.COLORS.yellow,
        fontSize: 10,
        marginLeft: 3,
    },
});