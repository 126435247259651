import {connect} from 'react-redux';
import {ForgotPasswordScreenComponent} from './component';
import {showLoading} from '../../../store/ui/actions';
import {GlobalState} from '../../../store/state';

const mapStateToProps = (state: GlobalState) => {
  return {
    loading: state.ui.loading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    showLoading: (loading: boolean) => {
      dispatch(showLoading(loading));
    },
  };
};

export const ForgotPasswordScreen = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordScreenComponent);
