import theme from '../../../constants/theme';
export const Style = ({
    container: {
        backgroundColor: theme.COLORS.componentBgColor,
        borderWidth:0,
        borderRadius: 12,
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#fff',
        width: '100%',
        height: 50
    },
    icon: {
        backgroundColor:'transparent',
        border:0,
    },
});
