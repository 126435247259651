export class FireStoreDocuments{
    static Users = 'Users'
    static Business ='Business'
    static Businesses = 'Businesses'
    static Units = 'Units'
    static Services ='Services'
    static Anketler ='Anketler'
    static Bildirimler = 'Bildirimler'
    static Messages = 'Messages'
    static Cevaplar = 'Cevaplar'
    static UnitBildirimler ='UnitBildirimler'
    static WaiterRequests = 'waiterRequests'
    static ActionMenus = 'ActionMenus'
    static Comments = 'Comments'
    static Notifications = 'Notifications';
    static Campaigns = 'Campaigns';
}