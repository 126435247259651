import {Lang, getLangFile} from '../constants/langs';
import {StorageService} from './storage.service';
import {LangSwither} from '../utils/lang-switcher';

export class TranslationService {
  public static translate(screen: string, key: string) {
    const lang = LangSwither.getLang().code;
    const path = getLangFile(screen, lang);
    return path[key] ? path[key] : key;
  }

  public static async changeLang(lang: Lang): Promise<boolean> {
    await StorageService.setLang(lang);
    LangSwither.changeLanguage(lang);
    return true;
  }

  public static getPhoneLang() {
    const locale = navigator.language;
    return locale;
  }

  public static getLang(): Lang {
    return LangSwither.getLang();
  }
}
