import theme from '../../../../constants/theme';
export const Style = ({
    headerContainer:{
        backgroundColor: theme.COLORS.containerColor,
        paddingVertical: 10,
        opacity: 0.8,
        height: 40,
        alignItems:'center'
    },
    header:{
        color:'#fff',
        fontSize:18,
        flex:1,
    },
    img: {
        height: 68,
        width: 68,
        borderRadius: 8
    },
    unit:{
        color:'#fff',
        fontSize:24
    }
});
