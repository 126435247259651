import React, { useState } from 'react';
import theme from '../../constants/theme';
import { Style } from './style';
import { Props } from './types';
import { TranslationService } from '../../services/translation.service';
import { MicComponent } from '../MicComponent';
import { MicStatus } from '../../models/MicStatus';
import { TextAreaComponent } from '../Shared/TextAreaComponent';
import { UseRecorder } from '../../constants/types';
import useRecorder from '../../hooks/use-recorder';
import useRecordingsList from '../../hooks/use-recordings-list';
export function BildirInputMediaComponent(props: Props) {

    const t = TranslationService.translate;
    const [showTextArea, setShowTextArea] = useState(false);
    const [showMic, setShowMic] = useState(false);
    // inputs
    const [text, setText] = useState('')
    const [record, setRecord] = useState({} as MicStatus)

    const textListener = (value: any) => {
        setText(value)
        props.textListener(value)
    }

    const micFileListener = (model: MicStatus) => {
        setRecord(model)
        props.micFileListener(model.path)
    }

    return (
        <>
            <p className='fontMediumItalic' style={Style.title}>{props.title}</p>
            {
                showMic &&
                <div className='text-end' onClick={()=>setShowMic(false)}>
                    <img src={theme.ICONS.ic_close} style={{ height: 20, width: 20 }} />
                </div>
            }

            {
                showTextArea ?
                    <>
                        <div className='d-flex' style={Style.textAreaContainer}>
                            <TextAreaComponent
                                autoFocus={true}
                                listener={textListener}
                                value={text} />
                            <button className='transparentButton' onClick={() => {
                                setShowTextArea(false)
                            }}>
                                <img src={theme.ICONS.ic_close} style={{ height: 20, width: 20 }} />
                            </button>
                        </div>
                    </>
                    :
                    showMic ?
                        <MicComponent
                            file={props.file as any}
                            handlers={props.handlers}
                            recorderState={props.recorderState}
                            audio={props.audio}
                            deleteAudio={props.deleteAudio}
                            recordings={props.recordings}
                        />
                        :
                        <>
                            <div className='d-flex' style={Style.content}>
                                <button className='transparentButton' style={Style.card} onClick={() => {
                                    setShowTextArea(true)
                                }}>
                                    <p className='fontRegular' style={{ color: '#fff', fontSize: 12 }}>
                                        {text ? text : t('SearchScreen', 'writeMessage')}
                                    </p>
                                </button>
                                <button className='transparentButton' style={Style.card} onClick={() => {
                                    setShowMic(true)
                                }}>
                                    <img src={theme.ICONS.ic_mic} style={{ height: 62, width: 34 }} />
                                </button>
                            </div>

                        </>
            }
        </>
    )
}